import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import config from '../../config';

import MUIDataTable from 'mui-datatables';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ReactChartkick, { AreaChart, ColumnChart, LineChart } from 'react-chartkick';
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting'
import HC_offlineExporting from 'highcharts/modules/offline-exporting'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LinkIcon from '@material-ui/icons/Link';
import Datapoint from './Datapoint';
import DataEntry from './DataEntry';
import Word from './Word';

import { splitNumbers } from '../utils';

axios.defaults.timeout = 180000;
HC_exporting(Highcharts);
HC_offlineExporting(Highcharts);
ReactChartkick.addAdapter(Highcharts)

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  dense: {
    marginTop: 16,
  },
  fab: {
    margin: theme.spacing.unit,
  },
  filler: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 400
  },
  dataContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
  expose: {
    backgroundColor: '#4183c4',
  },
  exposeRow: {
    fontWeight: 700,
    color: 'white',
  },
  exposeRowNowrap: {
    whiteSpace: 'nowrap',
    fontWeight: 700,
    color: 'white',
  },
  card: {
    width: 'auto',
    maxWidth: 800,
    margin: 5,
    display: 'block',
    color: 'rgba(0, 0, 0, 0.74)',
    fontWeight: 700,
    fontSize: 13,
  },
  listNb: {
    marginRight: 20
  }
});

const categoriesOptions = [
  { value: 'all', label: 'Toutes' },
  { value: 'homepage', label: 'A la Une' },
  { value: 'france', label: 'France' },
  { value: 'international', label: 'International' },
  { value: 'économie', label: 'Economie' },
  { value: 'science_high-tech', label: 'Science' },
  { value: 'culture', label: 'Culture' },
  { value: 'sports', label: 'Sport' },
  { value: 'santé', label: 'Sante' }
];

class ArticlesGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      render: false,
      clusters: [],
      clusterCategory: 'all',
    }
  };

  getData = () => {
    axios.post(`${config.backEndServer}/api/cluster/theme/ranking`, { ...this.props.query, keywords: 'coronavirus' }
      , { withCredentials: true, timeout: 180000 })
      .then((res) => {
        const score = res.data.reduce((a, b) => a + b.score, 0);
        this.setState({ render: true, clusters: res.data, score });
      })
      .catch(err => {
        this.setState({ render: 'timeout'});
      });
  };

  componentDidMount = () => {
    this.getData();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.clusterCategory !== this.state.clusterCategory)
      this.getData();
  }

  liste = (data) => {
    const { classes } = this.props;
    let newListe = data.flat();
    let liste = [];
    for (let i = 0; i < newListe.length; i++) {
      if (i > 0) {
        liste.push(<Divider variant="middle" component="li" key={"divider" + newListe[i].titre} />);
      }
      liste.push(<ListItem key={newListe[i].titre} alignItems="flex-start">
          <ListItemText
            primary={
              <React.Fragment>
                <span className={classes.listNb}>{ i + 1 }</span>
                <a href={`/dashboard/article/${newListe[i].id}`} target="_blank">
                  { newListe[i].titre }
                </a>
              </React.Fragment>
            }
          />
        </ListItem>)
  }
  return liste;
}

  render = () => {
    const { classes, isAdmin, query, newQuery, queryDay } = this.props;
    const { render, clusters, score } = this.state;

    if (!clusters)
      return null;
    if (render) {
      return (
        <Fragment>
          <h3>Classement des éditeurs sur le Score Complet du Thème</h3>
          <div id="ranking">
            {
              clusters.filter(a => a.score > 0).sort((a, b) => b.score - a.score).map((editeur, key) =>
                <ExpansionPanel className={classes.card} key={editeur._id}>
                  <ExpansionPanelSummary className={query.editeur === editeur._id ? classes.expose : null}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panelTheme-content"
                    id="panelTheme-header"
                  >
                    <Typography style={{ minWidth: 50 }} className={query.editeur === editeur._id ? classes.exposeRow : null} gutterBottom component="p">
                      {key + 1}
                    </Typography>
                    <Typography style={{ minWidth: 250 }} className={query.editeur === editeur._id ? classes.exposeRow : null} component="p">
                      {editeur._id}
                    </Typography>
                    <Typography style={{ minWidth: 50 }} className={editeur._id === query.editeur ? classes.exposeRowNowrap : classes.nowrap} component="p">
                      {splitNumbers(editeur.score)}
                    </Typography>
                    <Typography style={{ minWidth: 50 }} className={query.editeur === editeur._id ? classes.exposeRow : null} gutterBottom component="p">
                      {Math.round(editeur.score / score * 100)}%
                        </Typography>
                    <Typography style={{ minWidth: 50 }} className={query.editeur === editeur._id ? classes.exposeRow : null} gutterBottom component="p">
                      {editeur.articles} articles
                        </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <List className={classes.root}>
                      {this.liste(editeur.articles_list)}
                    </List>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              )
            }
          </div>
        </Fragment>
      );
    }  else if (this.state.render === 'timeout') {
      return (
        <div className={classes.filler} >
          <h3>Délai de chargement expiré</h3>
        </div>
      )
    } else {
      return (
        <div className={classes.filler} >
          <CircularProgress className={classes.progress} />
        </div>
      )
    }
  };
}


ArticlesGraph.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ArticlesGraph);
