import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';

import { splitNumbers, decode } from '../utils';

function SelectedClustersClassement(props) {
    const { classes, query, clusters, fullTimeFrame, score, handleChangeFull } = props;

    const mergeCols = (arraysToMerge, arraysTitles) => {
        const nbCols = arraysToMerge.length;
        const nbRows = arraysToMerge[0].length;
        let merged = [];
        for (let i = 0; i < nbRows; i++) {
            let item = {};
            for (let k = 0; k < nbCols; k++) {
                item[arraysTitles[k]] = arraysToMerge[k][i];
            }
            merged.push(item);
        }
        return merged;
    }

    const liste = (editeur) => {
        const { classes } = props;
        let liste = [];
        mergeCols([fullTimeFrame === true ? editeur[1].scores : editeur[1].scoresFiltres, editeur[1].titre, editeur[1].articleId], ['score', 'titre', 'article'])
            .sort((a, b) => b.score - a.score)
            .map((item) =>
                item.score > 0 ?
                    liste.push(
                        <Fragment key={item.score}>
                            <div className={classes.tableLink} onClick={() => window.open(`${window.location.origin}/dashboard/article/${item.article}`)} >
                                {Math.round(item.score / score * 100)}% - <a style={{ display: "contents" }}>{decode(item.titre)}</a>
                            </div>
                            <Divider variant="middle" component="li" key={"divider" + item.titre} />
                        </Fragment>
                    )
                    : null
            )
        return liste;
    }

    return (
        clusters.articlesCluster && Object.entries(clusters.articlesCluster).sort((a) => fullTimeFrame === true ? a[1].score > 0 : a[1].scoreFiltre).length ?
            <Fragment>
                <div>
                    {!props.sujet && <Paper className={classes.warningTimeframe}>
                        <div> Afficher toute la Durée de Vie </div>
                        <Switch
                            checked={fullTimeFrame}
                            onChange={handleChangeFull}
                            color="primary"
                        />
                    </Paper>}
                </div>
                <h3>Classement des éditeurs sur la période selectionnée</h3>
                <div id="ranking">
                    {
                        Object.entries(clusters.articlesCluster).filter(a => fullTimeFrame === true ? a[1].score > 0 : a[1].scoreFiltre > 0).sort((a, b) => fullTimeFrame === true ? b[1].score - a[1].score : b[1].scoreFiltre - a[1].scoreFiltre).map((editeur, key) => {
                            if ((fullTimeFrame != true && editeur[1].scoresFiltres.filter(a => a > 0).length > 0) || (fullTimeFrame === true && editeur[1].scores.filter(a => a > 0).length > 0)) {
                                return (<ExpansionPanel className={classes.card} key={editeur[1]._id}>
                                    <ExpansionPanelSummary className={query.editeur === editeur[1]._id ? classes.expose : null}
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panelTheme-content"
                                        id="panelTheme-header"
                                    >
                                        <Typography style={{ minWidth: 50 }} className={query.editeur === editeur[1]._id ? classes.exposeRow : null} gutterBottom component="p">
                                            {key + 1}
                                        </Typography>
                                        <Typography style={{ minWidth: 250 }} className={query.editeur === editeur[1]._id ? classes.exposeRow : null} component="p">
                                            {editeur[0]}
                                        </Typography>
                                        <Typography style={{ minWidth: 50 }} className={editeur[1]._id === query.editeur ? classes.exposeRowNowrap : classes.nowrap} component="p">
                                            {splitNumbers(fullTimeFrame === true ? editeur[1].score : editeur[1].scoreFiltre)}
                                        </Typography>
                                        <Typography style={{ minWidth: 50 }} className={query.editeur === editeur[1]._id ? classes.exposeRow : null} gutterBottom component="p">
                                            {Math.round((fullTimeFrame === true ? editeur[1].score : editeur[1].scoreFiltre) / score * 100)}%
                                        </Typography>
                                        <Typography style={{ minWidth: 50 }} className={query.editeur === editeur[1]._id ? classes.exposeRow : null} gutterBottom component="p">
                                            {fullTimeFrame === true ? editeur[1].scores.filter(a => a > 0).length : editeur[1].scoresFiltres.filter(a => a > 0).length} articles
                                        </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <List className={classes.root}>
                                            {liste(editeur)}
                                        </List>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>)
                            }
                        }
                        )
                    }
                </div>
            </Fragment>
            : null
    );
}

export default SelectedClustersClassement;
