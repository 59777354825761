import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DesktopIcon from '@material-ui/icons/DesktopWindows';
import MobileIcon from '@material-ui/icons/Smartphone';
import TagList from './TagList';
import Coverage from './Coverage';

import { splitNumbers, replaceCharacters } from '../utils';

const styles = {
  card: {
    width: 'auto',
    maxWidth: 275,
    margin: 5,
    display: 'inline-block'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
    textAlign: 'center',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-all',
    wordBreak: 'break-word',
    hyphens: 'auto'
  },
  pos: {
    marginBottom: 12,
  },
  viewCover: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 16
  },
  info: {
    fontWeight: 700,
    textAlign: 'center',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-all',
    wordBreak: 'break-word',
    hyphens: 'auto',
    cursor: 'pointer',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  tag: {
    display: 'inline-block',
    backgroundColor: 'rgb(230, 230, 230)',
    minWidth: 0,
    boxSizing: 'border-box',
    borderRadius: 2,
    margin: 2,
    fontSize: 12,
    fontWeight: 700,
    paddingLeft: 5,
    paddingRight: 5,
    backgroundColor: '#4183c4',
    color: 'white',
    cursor: 'pointer',
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)'
  },
  bigTag: {
    display: 'inline-block',
    backgroundColor: 'rgb(230, 230, 230)',
    minWidth: 0,
    boxSizing: 'border-box',
    borderRadius: 2,
    margin: 2,
    fontSize: 14,
    fontWeight: 700,
    padding: 4,
    backgroundColor: '#4183c4',
    color: 'white',
    cursor: 'pointer',
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)'
  },
};



function Datapoint(props) {
  const { classes, newQuery, query, coverage } = props;
  let footer = '';
  if (props.footer) {
    if (props.footer.includes('desktop'))
      footer =  <DesktopIcon />
    else if (props.footer.includes('mobile'))
      footer = <MobileIcon />
    }

  const goToCouverture = (newParams) => {
      newQuery({...query, ...newParams },
        () => { window.open(`/dashboard/couverture`) });
  }

  return (
    <Card className={`${props.color ?  props.color + ' ' : ''}${classes.card}`}>
      <a href={props.link} target="_blank">
        <div className={classes.viewCover}>
          <Coverage coverage={coverage} />
          <CardContent>
              <Typography className={classes.info} variant="h5" component="h2" onClick={() => goToCouverture({clusterEntities: props.entity, clusterLemmes: ''})}>
                {props.entity}
              </Typography>
            <CardActions className={classes.actions}>
              <div className={classes.legend} color="textSecondary">
                {
                  !!(props.entity) ?
                  props.lemmes.map(l => <div className={classes.tag} onClick={() => goToCouverture({clusterEntities: '', clusterLemmes: l})}>{replaceCharacters(l)}</div>)
                  : props.lemmes.map(l => <div className={classes.bigTag} onClick={() => goToCouverture({clusterEntities: '', clusterLemmes: l})}>{replaceCharacters(l)}</div>)
                }
              </div>
            </CardActions>
          </CardContent>
        </div>
      </a>
    </Card>
  );
}

Datapoint.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Datapoint);
