import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Info from '@material-ui/icons/Info';
import Edit from '@material-ui/icons/Edit'
import Visibility from '@material-ui/icons/Visibility'
import moment from 'moment'

import { splitNumbers, favicon } from '../utils.js';


const styles = theme => ({
  card: {
    cursor: 'pointer',
    width: 300,
    marginTop: 10,
    marginBottom: 10,
    position: 'relative',
    overflow: 'visible'
  },
  date: {
    position: 'absolute',
    color: '#57AAD9',
    left: -50,
    top: 'calc(50% - 16px)'
  },
  recentArticle: {
    'border-left': '4px solid #70C28B',
    cursor: 'pointer',
    width: 300,
    margin: '0 auto',
    marginTop: 10,
    marginBottom: 10,
    position: 'relative',
    overflow: 'visible'
  },
  liveArticle: {
    'border-left': '4px solid #DD4B44',
    cursor: 'pointer',
    width: 300,
    margin: '0 auto',
    marginTop: 10,
    marginBottom: 10,
    position: 'relative',
    overflow: 'visible'
  },
  icon: {
    width: 16,
    height: 16
  },
  infos: {
    "font-size": 12,
    "display": "flex",
    justifyContent: "space-evenly",
    alignItems: "center"
  },
  live: {
    color: "#DD4B44"
  }
});


const getColor = (article) => {
  // Not that pertinent cause sorted already
  //if (article.firstLocalView > moment().subtract(30, 'minutes').format('YYYY-MM-DD HH:mm:ss'))
  //  return classes.recentArticle
  if (article.lastLocalView > moment().subtract(30, 'minutes').format('YYYY-MM-DD HH:mm:ss'))
    return true

   return false
}

function ActuList(props) {
  const { classes, articles } = props;
  let toFill = [0] //Ugly but no simpler solution
  if (articles.length % 3 === 1)
    toFill = [0,0]
  if (articles.length % 3 === 2)
    toFill = [0]

  return (
    <Fragment>
      {articles.sort((a,b) => a.firstLocalView - b.firstLocalView).map(article =>
          <Card key={article._id} className={classes.card}>
            <div className={classes.date}>
              <strong>{moment(article.firstLocalView).format('HH:mm')}</strong>
            </div>
            <Grid className={classes.grid} container justify="center" alignItems="center" spacing={8}>
              <Grid item  xs={2}>
                <img className={classes.icon} src={favicon[article.editeur]} />
              </Grid>
              <Grid item xs={8} >
                 <strong>{article.titre}</strong>
               </Grid>
             <Grid className={classes.infos} item  xs={4}>
                  <Edit /> {moment(article.dateArticle).format('DD/MM HH:mm')}
              </Grid>
              <Grid className={classes.infos} item  xs={4}>
                  <Visibility className={getColor(article) ? classes.live : {}} /> {moment(article.lastLocalView).format('DD/MM HH:mm')}
              </Grid>
              <Grid item xs={1}>
                <a href={`/dashboard/article/${article._id}`} target="_blank">
                  <Info />
                </a>
              </Grid>
            </Grid>
          </Card>)}
          { toFill.map((c, index) => <div key={index} className={classes.card} />) }
    </Fragment>
  );
}

ActuList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ActuList);
