import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import config from '../config';
import _ from 'lodash';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Fab from '@material-ui/core/Fab';
import DownloadIcon from '@material-ui/icons/SaveAlt';

import ClusterLine from './sub_components/ClusterLine';
import CoverageStatus from './CoverageStatus';

const withInfiniteScroll = (Component) =>
  class WithInfiniteScroll extends React.Component {
    componentDidMount() {
      window.addEventListener('scroll', this.onScroll, false);
    }

    componentWillUnmount() {
      window.removeEventListener('scroll', this.onScroll, false);
    }

    onScroll = () => {
      if (
        (window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 500) &&
        this.props.list.length
        && !this.props.isLoading
      ) {
        this.props.onPaginatedSearch();
      }
    }

    render() {
      return <Component {...this.props} />;
    }
  }

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  fieldContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  listContainer: {
    paddingBottom: 150
  },
  filler: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 400
  },
  ddl: {
    position: 'absolute',
    right: 30
  },
  subtitle: {
    color: 'grey',
    textAlign: 'center'
  },
  fab: {
    margin: theme.spacing.unit,
    width: 42,
    height: 42,
    backgroundColor: '#57AAD9',
    marginTop: 20
  }
});

class ClusterList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      render: false,
      clusters: [],
      period: '24',
      isLoading: false,
      isLoadingDetailed: false,
      pagination: 0,
      total: null,
      apiLemmes: null
    }
  };

  getData = () => {
    if (!this.state.isLoading && this.state.total !== this.state.clusters.length) {
      this.setState({ isLoading: true });
      axios.post(`${config.backEndServer}/api/cluster`, {
          ...this.props.query,
          pagination: this.state.pagination,

        }, { withCredentials: true })
        .then((res) => {
          if (res.data.pageInfo) {
            this.setState({
              render: true,
              clusters: [...res.data.clusters],
              isLoading: false,
              pagination: this.state.pagination + res.data.clusters.length,
              total: res.data.pageInfo[0] ? res.data.pageInfo[0].count : 0,
              apiLemmes: res.data.pageInfo[0] && res.data.pageInfo[0].lemmes ? res.data.pageInfo[0].lemmes : null,
            });
            this.props.handleClusters && this.props.handleClusters([...res.data.clusters]); 
          } else {
            const toAppend = res.data.clusters.filter(x => !this.state.clusters.some(n => n._id === x._id))
            if (!toAppend.length) {
              this.setState({
                isLoading: false,
                total: this.state.clusters.length
              })
            } else {
              this.setState({
                clusters: [...this.state.clusters, ...toAppend],
                isLoading: false,
                pagination: this.state.pagination + res.data.clusters.length,
                category: this.state.category,
                position: this.state.position,
                entities: this.state.entities,
                lemmes: this.state.lemmes,
                orphan: this.state.orphan
              });
              this.props.handleClusters && this.props.handleClusters([...this.state.clusters, ...toAppend]); 
            }
          }
        });
    }
  };

  getDetails = () => {
    const clustersToUpdate = this.state.clusters.filter(c => typeof c.coverage === 'undefined').slice(0, 10);
    if (!this.state.isLoadingDetailed && clustersToUpdate.length) {
      this.setState({ isLoadingDetailed: true });
      axios.post(`${config.backEndServer}/api/cluster/details`, { ids: clustersToUpdate.map(c => c._id), editeur: this.props.query.editeur, groupe: this.props.query.groupe }, { withCredentials: true })
        .then((res) => {
          const details = res.data;
          const newClusters = this.state.clusters.map(c => {
            const index = details.findIndex(t => c._id === t._id)
            if (index > -1) {
              if (!!(this.props.coverage)) {
                c.coverage = details[index].coverage;
                c.coverageList = details[index].coverageList;
                c.urlList = details[index].urls;
                c.lastDateArticleList = details[index].lastDateArticleList;
                c.lastExposedList = details[index].lastExposedList;
              }
            }
            return c;
          });
          this.setState({ clusters: newClusters, isLoadingDetailed: false });
        });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, false);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!!(this.props.coverage) && !this.state.isLoading && (prevState.isLoading || prevState.isLoadingDetailed)) {
      this.getDetails();
    }
  }

  onScroll = () => {
    if (
      (
        this.state.clusters.length && !this.state.isLoading &&
        window.innerHeight + window.scrollY) >= (ReactDOM.findDOMNode(this).offsetHeight)
    ) {
      this.getData();
    }
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.onScroll, false);
    this.getData();
  }

  render = () => {
    const { classes, query } = this.props;
    let { clusters } = this.state;

    let isCustomPeriod = true;

    const periodOptions = [
      { value: '24', label: "Aujourd'hui" },
      { value: '2', label: "2 Dernières Heures" },
      { value: '6', label: "6 Dernières Heures" },
      { value: '12', label: "12 Dernières Heures" }
    ];

    if (this.props.query.fromDate === moment().startOf('day').format('YYYY-MM-DDTHH:mm') &&
      this.props.query.toDate === moment().endOf('day').format('YYYY-MM-DDTHH:mm')) {
      isCustomPeriod = false;
    }
    let csvUrl = `${config.backEndServer}/api/cluster/csv?1=1`;
    for (const key in this.props.query) {
      csvUrl += `&${encodeURIComponent(key)}=${encodeURIComponent(this.props.query[key])}`;
    }

    if (this.state.render) {
    return (
        <div className="article-container">
          { this.props.coverage  && !this.props.noSTatus ? <CoverageStatus query={this.props.query} /> : null }
          <a className={classes.ddl} href={csvUrl} download>
            <Fab color="primary" aria-label="Download" className={classes.fab}>
              <DownloadIcon />
            </Fab>
          </a>
          <h1>Clusters</h1>
          <h1>{ this.state.total } résultats correspondants</h1>
          { this.state.apiLemmes ?
            <h4 className={classes.subtitle}>Recherche optimisée sur les mots: {this.state.apiLemmes.join(', ')}</h4>
            : null
          }
          <h1></h1>
          <div id="listContainer" className={classes.listContainer} >
            { clusters.map((c, index) => <ClusterLine key={c._id} cluster={c} editeur={this.props.query.editeur} position={index + 1} detailed={this.state.detailed} />) }
            { this.state.isLoading ?
              <div className={classes.filler} >
                <CircularProgress className={classes.progress} />
              </div>
                : null }
          </div>
        </div>
      );
    } else {
      return (
        <div className={classes.filler} >
          <CircularProgress className={classes.progress} />
        </div>
      );
    }
  };
}

ClusterList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ClusterList);
