import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import config from '../../config';


import Datapoint from './Datapoint';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  dense: {
    marginTop: 16,
  },
  fab: {
    margin: theme.spacing.unit,
  },
  filler: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 400
  },
  dataContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'nowrap'
  }
});

class DailyData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      render: false,
      daily: null
    }
  };

  getData = () => {
    axios.post(`${config.backEndServer}/api/status/stats`, this.props.query
    , {withCredentials: true})
      .then((res) => {
        this.setState({ render: true, data: res.data });
      });
  };

  componentDidMount = () => {
    this.getData();
  }

  render = () => {
    const { classes } = this.props;
    if (this.state.render) {
      return (
        <div className={classes.dataContainer}>
          <Datapoint title="Articles modifiés aujourd'hui" info={this.state.data.articles} />
          <Datapoint title="Articles apparus aujourd'hui" info={this.state.data.newArticles} />
          <Datapoint title="Editeurs recensés" info={this.state.data.editeurs} />
        </div>
        );
    } else {
      return (
        <div className={classes.filler} >
          <CircularProgress className={classes.progress} />
        </div>
      )
    }
  };
}


DailyData.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DailyData);
