import React, { Component } from 'react';
import ArticlesList from './ArticlesList';
import ArticlesGraph from './ArticlesGraph';
import SitemapEditeur from './SitemapEditeur';
import moment from 'moment';

const Articles = (props) => {
  // if timeRange more than a month => Render graph only
  const isLongRange = props.query.editeur && props.query.editeur !== 'all' && moment(props.query.toDate).diff(props.query.fromDate, 'days') > 30
  let title = 'Articles';
  if (props.query.editeur && props.query.editeur !== 'all') {
    title = props.query.editeur;
    // if (props.query.compareDate === 'first_view')
    //   title = 'Nouveaux articles vus pour ' + props.query.editeur;
    // if (props.query.compareDate === 'lastModified')
    //   title = 'Articles dernièrement modifiés pour ' + props.query.editeur;
    // if (props.query.compareDate === 'all')
    //   title = 'Articles vus pour  ' + props.query.editeur;
  }
  return (
    <div>
      <h1>{title}</h1>
      <div className="article-container">
        <ArticlesGraph {...props} />
        <SitemapEditeur {...props} />
      </div>
      <ArticlesList {...props} />
    </div>
  );
}

export default Articles;
