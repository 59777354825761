import React, { Component } from 'react';
import { BrowserRouter , Route , Switch , Link, Redirect } from 'react-router-dom'
import './App.css';
import Login from './Login'
import ErrorPage from './ErrorPage';
import Dashboard from './Dashboard/App';

class App extends Component {
  render = () => {
    return (
      <Switch>
        <Route exact path='/login' component={Login}/>
        <Route exact path='/error' component={ErrorPage}/>
        <Route path='/dashboard' component={Dashboard}/>
        <Redirect path="/" to="/dashboard" />
      </Switch>
    );
  };
}

export default App;
