import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import config from '../config';

import MUIDataTable from 'mui-datatables';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactChartkick, { AreaChart, ColumnChart, LineChart } from 'react-chartkick';
import Highcharts from 'highcharts'
import Datapoint from './sub_components/Datapoint';
import HC_exporting from 'highcharts/modules/exporting'
import HC_offlineExporting from 'highcharts/modules/offline-exporting'

import { customColor, saveColumnSetup, getGraphArray } from './utils';

HC_exporting(Highcharts);
HC_offlineExporting(Highcharts);
ReactChartkick.addAdapter(Highcharts)

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  dense: {
    marginTop: 16,
  },
  fab: {
    margin: theme.spacing.unit,
  },
  filler: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 400
  },
  dataContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'nowrap'
  }
});

const formatDate = (a) => {
  return moment(a, 'YYYY-MM-DD').format('D/M')
}

const formatHour = (day, hour) => {
  // return moment(day + ' ' + hour, 'YYYY-MM-DD HH');
  return day + ' ' + hour;
}

const decode = (string) => {
  let finalString = '';
  try { finalString = decodeURIComponent(escape(string)) }
  catch(error) { finalString = string }
  return finalString;
}

class ArticlesGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      render: false,
      editeur: {}
    }
  };

  componentDidUpdate = (prevProps) => {
    if ((prevProps.query.fromDate !== this.props.query.fromDate) || (prevProps.query.toDate !== this.props.query.toDate))
      this.getData();
  }

  getData = () => {
    const query = this.props.changeEditeur ? this.props.query : {...this.props.query, ...{editeur: 'all'} };
    axios.post(`${config.backEndServer}/api/editeur/score`, this.props.query
    , {withCredentials: true})
      .then((res) => {
        this.setState({ render: true, editeur: res.data.editeur, timeframe: res.data.timeframe });
      });
  };

  componentDidMount = () => {
    this.getData();
  }

  render = () => {
    const { classes, isAdmin, displaySelect } = this.props;
    const { render, editeur, timeframe } = this.state;
    const { fromDate, toDate } = this.props.query;

    if (render) {
      if (!(editeur && editeur.scores && editeur.jours))
        return null;

      const { score, dataChart } = getGraphArray(editeur.scores, editeur.jours, fromDate, toDate, timeframe)
      const optionsColumn = {
        exporting: {
           fallbackToExportServer: false
         }
      };

      return (
        <div>
          <div className="article-container">
            <div className="chart-container" id="evolution-chart">
              <h3>Evolution de la visibilité totale </h3>
              <AreaChart colors={Object.values(customColor)} stacked={true} data={dataChart} library={optionsColumn} />
            </div>
          </div>
        </div>
        );
    } else {
      return (
        <div className={classes.filler} >
          <CircularProgress className={classes.progress} />
        </div>
      )
    }
  };
}


ArticlesGraph.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ArticlesGraph);
