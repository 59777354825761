import React, { Component } from 'react';
import moment from 'moment';
import axios from 'axios';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import config from '../../config';

const styles = {
    card: {
        cursor: 'pointer',
        width: 'auto',
        maxWidth: 800,
        margin: '0 auto',
        marginTop: 10,
    },
    cardBis: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        cursor: 'default',
        width: 'auto',
        maxWidth: 800,
        margin: '0 auto',
        marginBottom: 10,
        marginTop: 1,
        padding: 10,
    },
    titreCardContent: {
        fontSize: 16,
        fontWeight: 700,
        marginBottom: 10
    },
    tag: {
        display: 'table',
        backgroundColor: '#bd1e1e66',
        minWidth: 0,
        boxSizing: 'border-box',
        borderRadius: 5,
        margin: '5px 2px',
        fontSize: 16,
        padding: 5,
        color: 'rgb(82, 81, 110)',
        cursor: 'pointer'
    },
    specialTag: {
        display: 'table',
        backgroundColor: '#04890866',
        minWidth: 0,
        boxSizing: 'border-box',
        borderRadius: 5,
        margin: '5px 2px',
        fontSize: 16,
        padding: 5,
        color: 'rgb(82, 81, 110)',
        border: 'solid 2px #048908',
        cursor: 'pointer',
        boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)'
    },
    selectedTag: {
        // display: 'inline-block',
        backgroundColor: '#57aad966',
        // minWidth: 0,
        boxSizing: 'border-box',
        borderRadius: 5,
        marginLeft: '2px',
        marginTop: "25px",
        fontSize: 16,
        padding: 5,
        color: 'rgb(82, 81, 110)',
        border: 'solid 2px #57aad9',
        boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)'
    },
    selectedTagLine: {
        display: 'flex',
        width: '-webkit-fill-available',
        justifyContent: 'space-between',
    },
    btn: {
        backgroundColor: '#57AAD9',
        marginTop: 20,
        alignSelf: 'center'
    },
    ddl: {
        textDecoration: "none"
    },
    infos: {
        "font-size": 12,
        "display": "flex",
        justifyContent: "space-evenly",
        alignItems: "center"
    },
    cardExpression: {
        width: 300,
        marginTop: 15,
        marginBottom: 10,
        padding: 10,
        position: 'relative',
        overflow: 'visible'
    },
    date: {
        position: 'absolute',
        color: '#57AAD9',
        left: -50,
        top: 'calc(50% - 16px)',
        width: 10
    },
    warningTimeframe: {
        position: 'fixed',
        height: 90,
        width: 100,
        top: 200,
        right: 20,
        padding: 10,
        opacity: 0.8,
        zIndex: 10000,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'rgb(244, 155, 66, 0.8)',
    },
};

class NewsboxKeywords extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expressions: [],
            selected: [],
            confirmed: []
        }
    };

    componentDidMount = () => {
        this.getExpressions();
    }

    getExpressions = () => {
        axios.get(`${config.backEndServer}/api/cluster/getexpressions`,
        { withCredentials: true })
            .then((res) => {
                this.setState({ selected: res.data.toValidate, expressions: res.data.toValidate, confirmed: res.data.confirmed });
            });
    }

    handleCard = (e, u) => {
        const { innerText } = e.target;

        let selected = this.state.selected;
        let found = selected.find(element => element.expression == innerText);
        if (found != undefined) {
            selected = selected.filter(i => i.expression != innerText);
        } else {
            selected.push({
                expression: innerText,
                clusterId: u.clusterId,
                date_add: moment().toDate(),
                crawling: true
            })
        }
        this.setState({ selected: selected });
        e.target.classList.toggle(this.props.classes.specialTag)
    }

    saveExpressions = () => {
        axios.post(`${config.backEndServer}/api/cluster/expressions/validate`, {
            selected: this.state.selected,
        }, { withCredentials: true })
            .then((res) => {
                toast.success('Les expressions sont confirmées', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.setState({ selected: res.data.toValidate, expressions: res.data.toValidate, confirmed: res.data.confirmed });
            });
    }

    manageCrawling = (data, type) => {
        axios.post(`${config.backEndServer}/api/cluster/expressions/crawling`, {
            expression: data,
            crawling: type
        }, { withCredentials: true })
            .then((res) => {
                toast.success("L'expression a été modifiée", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.setState({ selected: res.data.toValidate, expressions: res.data.toValidate, confirmed: res.data.confirmed });
            });
    }

    render = () => {
        const { classes } = this.props;
        let { expressions, selected, confirmed } = this.state;

        let toFill = [0]
        if (confirmed.length % 3 === 1)
            toFill = [0, 0]
        if (confirmed.length % 3 === 2)
            toFill = [0]
        return (
            <div>
            <div>
                <Paper className={classes.warningTimeframe}>
                    <Button variant="contained" color="secondary" size="small" onClick={() => this.manageCrawling("all", false)}> Arrêter tous les crawls </Button>
                </Paper>
            </div>
                {
                    expressions.length > 0 ?
                        <Card className={classes.card}>
                            {
                                <CardContent className={classes.cardBis}>
                                    <div>
                                        <div className={classes.titreCardContent}>Les expressions à valider :</div>
                                        {expressions.map((u, index) => {
                                            let found = selected.find(element => element.expression == u.expression);
                                            return (<div key={u.expression} className={found != undefined ? classes.specialTag : classes.tag} onClick={(e) => this.handleCard(e, u)}> {u.expression} </div>)
                                        }
                                        )}
                                    </div>
                                    <Button variant="contained" color="primary" className={classes.btn} onClick={this.saveExpressions}> Confirmer </Button>
                                </CardContent>
                            }
                        </Card>
                        :
                        null
                }
                {
                    confirmed.length > 0 ?
                        <div className='cards-container'>
                            {confirmed.sort((a, b) => moment(b.date_add) - moment(a.date_add)).map(u => {
                                let csvNewsbox = `${config.backEndServer}/api/cluster/newsbox/csv/?1=1`;
                                csvNewsbox += `&${encodeURIComponent("query")}=${encodeURIComponent(u.expression)}`;
                                return (<Card key={u._id} className={classes.cardExpression}>
                                    <div className={classes.date}>
                                        <strong>{moment(u.date_add).format('DD/MM HH:mm')}</strong>
                                    </div>
                                    <Grid className={classes.grid} container justify="center" alignItems="center" spacing={8}>
                                        <Grid item xs={8} >
                                            <strong>{u.expression}</strong>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <a className={classes.ddl} href={csvNewsbox} download>
                                                <Button variant="contained" color="primary" className={classes.btn} aria-label="Download" size="small"> Télécharger CSV </Button>
                                            </a>
                                            {
                                                u.crawling && u.crawling == true ?
                                                <Button variant="contained" color="secondary" size="small" style={{marginTop: 20, marginLeft: 20, alignSelf: 'center'}} onClick={() => this.manageCrawling(u, false)}> Arrêter le crawl </Button>
                                                :
                                                <Button variant="contained" color="primary" size="small" style={{marginTop: 20, marginLeft: 4, alignSelf: 'center'}} onClick={() => this.manageCrawling(u, true)}> Reprendre le crawl </Button>
                                            }
                                        </Grid>
                                    </Grid>
                                </Card>)
                            })}
                            {toFill.map((c, index) => <div key={index} className={classes.cardExpression} />)}
                        </div>
                        :
                        null
                }
            </div>
        );
    };
}

NewsboxKeywords.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NewsboxKeywords);
