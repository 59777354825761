import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Info from '@material-ui/icons/Info';
import Edit from '@material-ui/icons/Edit'
import Visibility from '@material-ui/icons/Visibility'
import moment from 'moment'

import ActuLineEditeur from './ActuLineEditeur'
import ActuLineInfo from './ActuLineInfo'
import { splitNumbers, getTitle, favicon, getOffset, getLen } from '../utils'


const styles = theme => ({
  icon: {
    width: 16,
    height: 16,
    marginRight: 2,
  },
  lineContainer: {
    position: 'relative',
    height: 44,
    marginTop: 10,
    marginBottom: 10
  },
  lineContainer: {
    position: 'relative',
    height: 44,
    marginBottom: 10,
    cursor: 'pointer',
  },
  eventContainer: {
    top: 5,
    height: 'inherit',
    position: 'absolute',
    paddingLeft: 5,
    paddingRight: 5,
    backgroundColor: '#3f51b5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  selectedEventContainer: {
    top: 5,
    height: 'inherit',
    position: 'absolute',
    paddingLeft: 5,
    paddingRight: 5,
    backgroundColor: '#3f51b5',
  },
  meter: {
    width: 1180,
    position: 'relative',
    height: 18,
    overflow: 'hidden'
  },
  hour: {
    position: 'absolute',
  },
  lineTitle: {
    padding: 9,
    borderWidth: '1px 1px 1px 1px',
    borderColor: 'white',
    borderStyle: 'solid',
    backgroundColor: '#3f51b5',
    color: 'white',
    position: 'absolute',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontWeight: 700,
    width: 300,
    fontSize: '1.1em',
    height: 54,
    zIndex: '1'
  },
  selectedContainer: {
    width: 980,
  },
  timeSpent: {
    position: 'absolute',
    color: '#3f51b5',
    left: -340,
    top: 'calc(50% - 5px)'
  }
});

const clusterCoverage = (date, articles, dataToGet) => {
  return articles.filter(a => a[dataToGet].filter(a => date > a.start && date < a.end ).length).length
}

function ActuLineCluster(props) {
  const { classes, articles, cluster, onClick, selected, rangeStart, rangeEnd, editeur, dataToGet } = props;

  const dayMinutes = {}
  let dateAlaune = []
  let htmlCluster = []
  if (articles.length === 1) {
    dateAlaune = articles[0][dataToGet]
  }
  else {
    for (let i = 0; moment(rangeStart).add(i, 'minutes') < rangeEnd; i++) {
      const currTime = moment(rangeStart).add(i, 'minutes').format('YYYY-MM-DD HH:mm:ss')
      if (clusterCoverage(currTime, articles, dataToGet)) {
        const firstView = {
  				start: currTime,
  				end: currTime
  			};
  			if (dateAlaune.length === 0) {
          dateAlaune.push(firstView)
  			} else {
  				let lastView = dateAlaune[ dateAlaune.length - 1 ];
  				if (lastView.end < moment(currTime).subtract(10, 'minutes').format('YYYY-MM-DD HH:mm:ss')) {
  					dateAlaune.push(firstView);
  				} else {
  					dateAlaune[ dateAlaune.length - 1 ].end = currTime;
  				}
  			}
      }
    }
  }

  for (let i = 0; i < dateAlaune.length; i++) {
    let firstDate = moment(dateAlaune[i].start, 'YYYY-MM-DD HH:mm:ss')
    if (firstDate < rangeStart)
      firstDate = rangeStart
    let lastDate = moment(dateAlaune[i].end, 'YYYY-MM-DD HH:mm:ss')
    if (lastDate > rangeEnd)
      lastDate = rangeEnd
    const offsetX = getOffset(firstDate, rangeStart, rangeEnd)
    const length = getOffset(lastDate, rangeStart, rangeEnd) - offsetX;

    htmlCluster.push(
      <Card key={i} className={selected ? classes.selectedEventContainer : classes.eventContainer} style={{left: offsetX, width: length}}>
          { !selected && articles.map((article) => <img className={classes.icon} src={favicon[article.editeur]} />) }
      </Card>
    )
  }

  const transformHours = (date) => {
    let showHours = "";
    let hours = Math.floor(date / 60);
    let minutes = (date % 60);
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    showHours = `${hours}:${minutes}`
    return showHours;
}

  return (
    <Fragment>
      <div className={classes.lineContainer} key={cluster._id} onClick={() => onClick(cluster)} >
        <div className={classes.lineTitle} style={{marginLeft: -300}}>
          {getTitle(cluster)}
        </div>
        <div className={classes.timeSpent} >
        { transformHours(Math.floor(cluster.clusterExposition / 60000))}
        </div>
        {htmlCluster}
      </div>
      { selected &&
        <Fragment>
            <div className={classes.selectedContainer} >
              <ActuLineEditeur key={cluster._id} articles={articles.filter(a => cluster._id === a.cluster_id)} cluster={true} rangeStart={rangeStart} rangeEnd={rangeEnd} dataToGet={dataToGet} />
              <ActuLineInfo key={cluster._id} articles={articles.filter(a => cluster._id === a.cluster_id)} cluster={cluster} rangeStart={rangeStart} rangeEnd={rangeEnd} editeur={editeur} dataToGet={dataToGet} titre={props.titre} />
            </div>
        </Fragment>
    }
    </Fragment>
  );
}

ActuLineCluster.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ActuLineCluster);
