import React from 'react';
import moment from 'moment';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Fab from '@material-ui/core/Fab';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';

function UserInfo(props) {
    return (
        <TableRow>
            <TableCell>
                <TextField style={{ minWidth: 120 }}
                    className="standard-text"
                    value={props.user.email ? props.user.email : ""}
                    onChange={props.handleChange('email', props.index)}
                    type="text"
                />
            </TableCell>
            <TableCell>
                <TextField
                    className="standard-text"
                    value={props.user.editeur ? props.user.editeur : ""}
                    onChange={props.handleChange('editeur', props.index)}
                    type="text"
                    margin="normal"
                />
            </TableCell>
            <TableCell style={{ maxWidth: 20 }}>
                <TextField
                    className="standard-number"
                    min="1"
                    value={props.user.sessionLimit ? props.user.sessionLimit : ""}
                    onChange={props.handleChange('sessionLimit', props.index)}
                    type="number"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="normal"
                />
            </TableCell>
            <TableCell style={{ maxWidth: 60 }}>
                {props.user.lastConnection ? moment(props.user.lastConnection).fromNow() : moment().startOf(Date.now()).fromNow()}
            </TableCell>
            <TableCell style={{ width: 60 }}>
                <TextField style={{ width: 60 }}
                    className="isAdmin"
                    select
                    value={props.user.isAdmin ? props.user.isAdmin : false}
                    onChange={props.handleChange('isAdmin', props.index)}
                    margin="normal"
                >
                    <MenuItem value={true}>
                        Oui
            </MenuItem>
                    <MenuItem value={false}>
                        Non
            </MenuItem>
                </TextField>
              </TableCell>
              <TableCell style={{ width: 80 }}>
                    <TextField style={{ width: 80 }}
                        className="role"
                        select
                        value={props.user.role ? props.user.role : "normal"}
                        onChange={props.handleChange('role', props.index)}
                        margin="normal"
                    >
                      <MenuItem value={"normal"}>
                            Normal
                      </MenuItem>
                      <MenuItem value={"actus"}>
                            Accès Actus
                      </MenuItem>
                  </TextField>
              </TableCell>
            <TableCell>
                <TextField
                    className="pass-text"
                    value={props.user.password ? props.user.password : ""}
                    onChange={props.handleChange('password', props.index)}
                    type="text"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="normal"
                />
            </TableCell>
            <TableCell style={{ width: 120 }}>
                <Fab
                    style={{visibility:"hidden", marginRight: "10px"}}
                    className={props.classes.fab}
                    size="small"
                    className="save-user"
                    variant="round"
                    color="primary"
                    onClick={() => props.handleSubmit(props.user)}>
                    <SaveIcon />
                </Fab>
                {props.user._id &&
                    <Fab
                        className={props.classes.fabDelete}
                        size="small"
                        className="delete-user"
                        variant="round"
                        color="secondary"
                        onClick={() => props.handleOpenOrClose(props.user)}>
                        <DeleteIcon />
                    </Fab>
                }
            </TableCell>
        </TableRow>
    );
}

export default UserInfo;
