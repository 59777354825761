import React, { Component } from 'react';
import axios from 'axios';
import Swal from "sweetalert2";

import config from '../../config';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import PauseIcon from '@material-ui/icons/Pause';
import PlayIcon from '@material-ui/icons/PlayArrow';

const styles = theme => ({
  fabPause: {
    margin: theme.spacing.unit,
    width: 42,
    height: 42,
    marginTop: 20,
    backgroundColor: '#dc3545'
  },
  fabOpen: {
    margin: theme.spacing.unit,
    width: 42,
    height: 42,
    backgroundColor: '#28a745',
    marginTop: 20
  },
  container: {
    position: 'absolute',
    right: 30,
    top: 10
  }
});

class Admin extends Component {
  constructor(props) {
    super();
    this.state = {
      status: props.status,
      isAdmin: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).isAdmin : null
    };
  }

  componentDidMount = () => {
  }

  handleSubmit = (status) => {
    axios.post(`${config.backEndServer}/api/cluster/${this.props.clusterId}`,
      { status: status, query: this.props.query },
      { withCredentials: true })
    .then((res) => {
      Swal.fire(
        'Ok',
        `Cluster Status: ${status}`,
        'success'
      );
      this.setState({ status: status });
    })
    .catch((err) => {
      Swal.fire(
        'Erreur',
        err.message,
        'error'
      )
    });
  }

  render = () => {
    const { classes } = this.props;

    if (!this.state.isAdmin)
      return null;
    return (
      <div className={classes.container}>
        { this.state.status === 'closed' ?
          <Fab className={classes.fabOpen} aria-label="Open" onClick={() => { this.handleSubmit('opened') }}>
            <PlayIcon />
          </Fab>
          : null
        }
        { !this.state.status || this.state.status === 'opened' ?
          <Fab className={classes.fabPause} aria-label="Pause" onClick={() => { this.handleSubmit('closed') }}>
            <PauseIcon />
          </Fab>
          : null
        }
      </div>
    );
  };
}

export default withStyles(styles)(Admin);
