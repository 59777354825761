import React, { Component } from 'react';
import axios from 'axios';

import config from '../../config';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import PlayIcon from '@material-ui/icons/PlayArrow';

const styles = theme => ({
  fabOpen: {
    margin: theme.spacing.unit,
    width: 42,
    backgroundColor: '#57AAD9',
    height: 42,
    marginTop: 20,
  },
  container: {
    position: 'absolute',
    right: 30,
    zIndex: 1000
  },
  sitemapContainer: {
    position: 'absolute',
    right: 30,
    zIndex: 1000,
    marginTop: 370
  }
});

class GoTo extends Component {
  constructor(props) {
    super();
  }

  componentDidMount = () => {
  }

  render = () => {
    const { classes, url, sitemap } = this.props;
    let containerClass = classes.container;

    if (sitemap)
      containerClass = classes.sitemapContainer;

    return (
      <div className={containerClass}>
        <Fab className={classes.fabOpen} aria-label="GoTo" onClick={() => { window.open(`${window.location.origin}/dashboard${url}`) }}>
          <PlayIcon />
        </Fab>
      </div>
    );
  };
}

export default withStyles(styles)(GoTo);
