import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import axios from 'axios';
import _ from 'lodash';

import config from '../../config';

import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Fab from '@material-ui/core/Fab';
import ButtonMU from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import RestoreIcon from '@material-ui/icons/Restore';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  dense: {
    marginTop: 16,
  },
  fab: {
    margin: theme.spacing.unit,
    width: 42,
    height: 42,
    backgroundColor: '#57AAD9',
    marginTop: 20
  },
  appbar: {
    left: 160,
    width: 'calc(100% - 160px)',
    backgroundColor: '#FFF',
    color: '#000000'
  }
});

const sources = [
  {
    value: 'tout',
    label: 'Tout'
  },
  {
    value: 'google',
    label: 'Tout Google'
  }, {
    value: 'desktop',
    label: 'Google News Desktop'
  }, {
    value: 'allMobile',
    label: 'Tout Mobile'
  },
  {
    value: 'mobile',
    label: 'Google News Mobile'
  }, {
  //   value: 'flipboard',
  //   label: 'Flipboard'
  // }, {
    value: 'applenews',
    label: 'AppleNews'
  }
];
const compareDate = [
  {
    value: 'lastModified',
    label: 'Derniere modification',
  },
  {
    value: 'first_view',
    label: 'Premiere Apparition',
  },
  {
    value: 'all',
    label: 'Tout'
  }
];

const compareDateSujets = [
  {
    value: 'first_view',
    label: 'Date de création du sujet',
  },
  {
    value: 'lastModified',
    label: 'Dates du filtre',
  },
  {
    value: 'all',
    label: 'Tout',
  }
];

const groupements = [
  {
    value: 'groupe',
    label: 'Regroupements',
  },
  {
    value: 'editeur',
    label: 'Normé',
  },
  {
    value: 'editeur_google',
    label: 'Google',
  }
];

const partages = [
  {
    value: 'total',
    label: 'Total',
  },
  {
    value: 'facebook',
    label: 'Facebook',
  },
  {
    value: 'twitter',
    label: 'Twitter',
  }
];

const groupementToEditeur = {
  'groupe': 'groupe',
  'editeur': 'nom_norme',
  'editeur_google': 'nom_affiche'
}

const categoriesOptions = [
  { value: 'all', label: 'Toutes' },
  { value: 'homepage', label: 'A la Une' },
  { value: 'france', label: 'France' },
  { value: 'international', label: 'International'},
  { value: 'économie', label: 'Economie' },
  { value: 'science_high-tech', label: 'Science'},
  { value: 'culture', label: 'Culture' },
  { value: 'sports', label: 'Sport' },
  { value: 'santé', label: 'Sante' }
];

const positionsOptions = [
  { value: 'all', label: 'Toutes' },
  { value: 'top', label: 'A la une' },
  { value: 'cat', label: 'Sections' },
  { value: 'topic', label: 'Topic' },
  { value: 'sitemap', label: 'Sitemap'},
  // { value: 'flipboard_top', label: 'F. A la une' },
  // { value: 'flipboard_topic', label: 'F. Sujets' }
];

const articlePositionOptions = [
  { value: 'all', label: 'Toutes' },
  { value: 'top', label: 'A la une' },
  { value: 'cat', label: 'Sections' },
  { value: 'topic', label: 'Topic' },
];

const printClusterParams = (query, handleChange, handleTextChange, handleCheckBoxChange, classes) => {
  return (
    <Fragment>
      <TextField
        id="category"
        select
        label="Categorie"
        className={classes.textField}
        value={query.clusterCategory}
        onChange={handleChange('clusterCategory')}
        SelectProps={{
          MenuProps: {
            className: classes.menu,
          },
        }}
        margin="normal"
        variant="filled"
      >
        {categoriesOptions.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        id="position"
        select
        label="Position"
        className={classes.textField}
        value={query.clusterPosition}
        onChange={handleChange('clusterPosition')}
        SelectProps={{
          MenuProps: {
            className: classes.menu,
          },
        }}
        margin="normal"
        variant="filled"
      >
        { positionsOptions.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField style={{minWidth: 200}}
        id="clusterEntities"
        label="Noms Propres"
        className={classes.textField}
        value={query.clusterEntities}
        onKeyPress={handleTextChange}
        onChange={handleChange('clusterEntities')}
        margin="normal"
      />
      <TextField style={{minWidth: 200}}
        id="clusterLemmes"
        label="Noms Communs"
        className={classes.textField}
        value={query.clusterLemmes}
        onKeyPress={handleTextChange}
        onChange={handleChange('clusterLemmes')}
        margin="normal"
      />
      <FormControlLabel
      control={
        <Checkbox
         checked={query.clusterOrphan}
         onChange={handleCheckBoxChange('clusterOrphan')}
         value="orphan"
         color="primary"
         />
      }
      label="Articles Orphelins"
      />
    </Fragment>
  )
}

const printArticlesParams = (query, handleChange, handleTextChange, handleCheckBoxChange, classes) => {
  return (
    <Fragment>
      <TextField
        id="category"
        select
        label="Categorie"
        className={classes.textField}
        value={query.articleCategory}
        onChange={handleChange('articleCategory')}
        SelectProps={{
          MenuProps: {
            className: classes.menu,
          },
        }}
        margin="normal"
        variant="filled"
      >
        {categoriesOptions.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        id="position"
        select
        label="Position"
        className={classes.textField}
        value={query.articlePosition}
        onChange={handleChange('articlePosition')}
        SelectProps={{
          MenuProps: {
            className: classes.menu,
          },
        }}
        margin="normal"
        variant="filled"
      >
        { articlePositionOptions.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField style={{minWidth: 200}}
        id="articleEntities"
        label="Mot clé"
        className={classes.textField}
        value={query.articleEntities}
        onKeyPress={handleTextChange}
        onChange={handleChange('articleEntities')}
        margin="normal"
      />
      <TextField style={{minWidth: 200}}
        id="url"
        label="URL"
        className={classes.textField}
        value={query.url}
        onKeyPress={handleTextChange}
        onChange={handleChange('url')}
        margin="normal"
      />
      <FormControlLabel
      control={
        <Checkbox
         checked={query.articleShare}
         onChange={handleCheckBoxChange('articleShare')}
         value="articleShare"
         color="primary"
         />
      }
      label="Avec Partages"
      />
    </Fragment>
  )
}

class Selector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: {...this.props.query},
      editeurs: [],
      render: this.props.changeEditeur ? false : true,
      selectedEditeur: {
        value: this.props.query.editeur,
        label: this.props.query.editeur === 'all' ? 'Tous' : this.props.query.editeur
      }
    }
  }

  componentDidMount = () => {
    // if (this.props.match && this.props.match.path === "/dashboard/suivigeneral") {
    //  const actualizedQuery = {
    //    fromDate: moment().startOf('day').format('YYYY-MM-DDTHH:mm'),
    //    toDate: moment().endOf('day').format('YYYY-MM-DDTHH:mm'),
    //    source: 'tout',
    //    compareDate: 'first_view',
    //    groupe: 'groupe',
    //    editeur: 'all'
    //  };
    //  if (!_.isEqual(this.props.query, actualizedQuery))
    //     this.setState({query: actualizedQuery })
    // }
    this.getData();
  }

  getData = () => {
    axios.get(`${config.backEndServer}/api/editeur`,
    { withCredentials: true })
      .then((res) => {
        this.setState({ editeurs: res.data, render: true });
      })
  }

  resetQuery = () => {
    const defaultQuery = {
        fromDate: moment().startOf('day').format('YYYY-MM-DDTHH:mm'),
        toDate: moment().endOf('day').format('YYYY-MM-DDTHH:mm'),
        source: 'tout',
        compareDate: 'all',
        groupe: 'editeur',
        editeur: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).editeur : '',
        clusterCategory: 'all',
        clusterPosition: 'all',
        clusterEntities: '',
        clusterLemmes: '',
        clusterOrphan: false,
        articleCategory: 'all',
        articlePosition: 'all',
        articleEntities: '',
        articleShare: false,
        share: 'facebook'
    };

    if (localStorage.getItem("selectedClusters") != null) localStorage.removeItem('selectedClusters');

    this.setState({
      query: defaultQuery,
      selectedEditeur: {
          value: defaultQuery.editeur,
          label: defaultQuery.editeur
        }
      }, () => this.props.newQuery(this.state.query));
  };

  handleChange = name => event => {
    this.setState({
      query: {...this.state.query, [name]: event.target.value}
    });
  };

  handleGroupChange = event => {
    this.setState({
      selectedEditeur: {
        value: 'all',
        label: 'Tous'
      },
      query: {...this.state.query, groupe: event.target.value, editeur: 'all' }
    });
  }

  handleChangeSelect = (value) => {
    this.setState({
      selectedEditeur: {
        value: value.value,
        label: value.label
      },
      query: {...this.state.query, editeur: value.value}
    });
  }

  handleTextChange = e => {
    this.setState({ query: { ...this.state.query, [e.target.id]: e.target.value } })
  }

  handleCheckBoxChange = name => event => {
    this.setState({ query: {...this.state.query,  [name]: event.target.checked } });
  };

  render = () => {
    const { classes, newQuery } = this.props;
    const { handleTextChange, handleChange, handleCheckBoxChange } = this;

    if (this.state.render) {
      if (this.props.changeSource) {
        return (
          <form className={classes.container} noValidate>
            <TextField
              id="source"
              select
              label="Zone"
              className={classes.textField}
              value={this.state.query.source}
              onChange={handleChange('source')}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal"
              variant="filled"
            >
              {sources.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <Fab id="search" color="primary" aria-label="Add" className={classes.fab} onClick={() => newQuery(this.state.query)}>
              <SearchIcon />
            </Fab>
          </form>
          );
      }
      else {
        let names = [...new Set(this.state.editeurs.map(x => x[groupementToEditeur[this.state.query.groupe]]))];
        const editeurs = [{ value: 'all', label: 'Tous' }, ...names.map(x => { return { value: x, label: x } })];
        return (
          <AppBar className={classes.appbar} position="fixed">
            <Toolbar>
            <form className={classes.container} noValidate>
            { this.props.clusterParams ? null :
                <TextField
                  id="source"
                  select
                  label="Zone"
                  className={classes.textField}
                  value={this.state.query.source}
                  onChange={handleChange('source')}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin="normal"
                  variant="filled"
                >
                  {sources.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              }
              <TextField
                id="from-datetime-local"
                label="Depuis"
                type="datetime-local"
                defaultValue={this.state.query.fromDate}
                value={this.state.query.fromDate}
                className={classes.textField}
                onChange={handleChange('fromDate')}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                variant="filled"
              />
              <TextField
                id="to-datetime-local"
                label="Au"
                type="datetime-local"
                defaultValue={this.state.query.toDate}
                onChange={handleChange('toDate')}
                value={this.state.query.toDate}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                variant="filled"
              />
              { this.props.sitemap || this.props.sujets == true ? null :
                <TextField
                  id="compareDate"
                  select
                  label="Date de reference"
                  className={classes.textField}
                  value={this.state.query.compareDate}
                  onChange={handleChange('compareDate')}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin="normal"
                  variant="filled"
                >
                  {compareDate.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              }
              { this.props.sujets == true &&
                <TextField
                  id="compareDate"
                  select
                  label="Date de reference"
                  className={classes.textField}
                  value={this.state.query.compareDate}
                  onChange={handleChange('compareDate')}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin="normal"
                  variant="filled"
                >
                  {compareDateSujets.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              }
              { this.props.changeEditeur && this.props.sujets != true ?
                <TextField
                  id="groupement"
                  select
                  label="Groupement"
                  className={classes.textField}
                  value={this.state.query.groupe}
                  onChange={this.handleGroupChange}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin="normal"
                  variant="filled"
                >
                  {groupements.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                : null
              }
              { this.props.changeEditeur ?
                <Select
                  className="searchable-select-container" classNamePrefix="searchable-select"
                  value={this.state.selectedEditeur}
                  onChange={this.handleChangeSelect}
                  options={editeurs}
                />
                : null
              }
              {
                this.props.sujets == true &&
                <Fragment>
                  <TextField style={{minWidth: 200}}
                    id="clusterEntities"
                    label="Noms Propres"
                    className={classes.textField}
                    value={this.state.query.clusterEntities}
                    onKeyPress={handleTextChange}
                    onChange={handleChange('clusterEntities')}
                    margin="normal"
                  />
                  <TextField style={{minWidth: 200}}
                    id="clusterLemmes"
                    label="Noms Communs"
                    className={classes.textField}
                    value={this.state.query.clusterLemmes}
                    onKeyPress={handleTextChange}
                    onChange={handleChange('clusterLemmes')}
                    margin="normal"
                  />
                </Fragment>
              }
              { this.props.clusterParams ?
                printClusterParams(this.state.query, handleChange, handleTextChange, handleCheckBoxChange, classes)
                : null }
              { this.props.articleParams ?
                printArticlesParams(this.state.query, handleChange, handleTextChange, handleCheckBoxChange, classes)
                : null }
                { this.props.partageParams ?
                  <TextField
                    id="share"
                    select
                    label="Partages Sociaux"
                    className={classes.textField}
                    value={this.state.query.share}
                    onChange={handleChange('share')}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    margin="normal"
                    variant="filled"
                  >
                    {partages.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                : null
              }
              <Fab id="search" color="primary" aria-label="Add" className={classes.fab} onClick={() => newQuery(this.state.query)}>
                <SearchIcon />
              </Fab>
              <Fab color="primary" aria-label="Add" className={classes.fab} onClick={() => this.resetQuery()}>
                <RestoreIcon />
              </Fab>
            </form>
          </Toolbar>
        </AppBar>
        );
      }
    }
    else
      return null;
  }
}

Selector.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Selector);
