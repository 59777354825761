import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import config from '../../config';

import MUIDataTable from 'mui-datatables';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ReactChartkick, { AreaChart, ColumnChart, LineChart } from 'react-chartkick';
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting'
import HC_offlineExporting from 'highcharts/modules/offline-exporting'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Datapoint from './Datapoint';
import DataEntry from './DataEntry';
import Word from './Word';

import { splitNumbers } from '../utils';

HC_exporting(Highcharts);
HC_offlineExporting(Highcharts);
ReactChartkick.addAdapter(Highcharts)
axios.defaults.timeout = 180000;

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  dense: {
    marginTop: 16,
  },
  fab: {
    margin: theme.spacing.unit,
  },
  filler: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 400
  },
  dataContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

const categoriesOptions = [
  { value: 'all', label: 'Toutes' },
  { value: 'homepage', label: 'A la Une' },
  { value: 'france', label: 'France' },
  { value: 'international', label: 'International'},
  { value: 'économie', label: 'Economie' },
  { value: 'science_high-tech', label: 'Science'},
  { value: 'culture', label: 'Culture' },
  { value: 'sports', label: 'Sport' },
  { value: 'santé', label: 'Sante' }
];

class ArticlesGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      render: false,
      clusters: [],
      clusterCategory: 'all',
    }
  };

  getData = () => {
    axios.post(`${config.backEndServer}/api/cluster/theme/editeur`, {...this.props.query }
    , {withCredentials: true, timeout: 180000})
      .then((res) => {
        const score = res.data.reduce((a, b) => a + b.score, 0);
        this.setState({ render: true, clusters: res.data, score });
      })
      .catch(err => {
        this.setState({ render: 'timeout'});
      });
  };

  componentDidMount = () => {
    this.getData();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.clusterCategory !== this.state.clusterCategory)
      this.getData();
  }

  render = () => {
    const { classes, isAdmin, query, newQuery, queryDay } = this.props;
    const { render, clusters, score } = this.state;

    const exposedColumns = [
        { name: '#', options: {display: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (tableMeta.rowIndex + 1);
          },
          sort: false
        } },
        { name: 'Editeur', options: { display: false } },
        { name: 'Score', options: { display: true, sortDirection: 'desc' } },
        { name: 'Score Cluster Parent', options: { display: true  } },
        { name: 'Titre', options: { display: true } },
        { name: 'Mise à jour Sitemap', options: { display: true } },
        { name: 'Heure de publication', options: { display: true } },
        { name: 'Dernière Modification', options: { display: true } },
        { name: 'Première exposition', options: { display: false } },
        { name: 'id', options: { display: false } },
    ]

    const unexposedColumns = [
        { name: '#', options: {display: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (tableMeta.rowIndex + 1);
          },
          sort: false
        } },
        { name: 'Editeur', options: { display: false } },
        { name: 'Score', options: { display: true } },
        { name: 'Score Cluster Parent', options: { display: true,  sortDirection: 'desc'  } },
        { name: 'Titre', options: { display: true } },
        { name: 'Mise à jour Sitemap', options: { display: true } },
        { name: 'Heure de publication', options: { display: true } },
        { name: 'Dernière Modification', options: { display: true } },
        { name: 'Première exposition', options: { display: false } },
        { name: 'id', options: { display: false } },
    ]

    const exposedArticles = clusters.filter(c => c.articlesCluster.coverage !== 1).map(cluster => {
      const article = cluster.articlesCluster;
      return [1, article.editeur, article.score, cluster.score, article.titre,
      article.lastDateArticle, article.dateArticle, article.lastModified, article.first_exposition, article._id];
    });

    const unexposedArticles = clusters.filter(c => c.articlesCluster.coverage === 1).map(cluster => {
      const article = cluster.articlesCluster;
      return [1, article.editeur, 0, cluster.score, article.titre,
      article.lastDateArticle, article.dateArticle, article.lastModified, article.first_exposition, article._id];
    });

    const options = {
      filter: false,
      selectableRows: false,
      responsive: "stacked",
      rowsPerPage: 20,
      rowsPerPageOptions: [10,50, 100],
      onRowClick: (rowData) => window.open(`${window.location.origin}/dashboard/article/${rowData[rowData.length - 1]}`),
    };


    if (!query.editeur || query.editeur === 'all')
      return (<div><h1>Veuillez selectionner un editeur</h1></div>)
    if (!clusters)
      return null;
    if (render) {
      return (
        <Fragment>
          <h3>{clusters.length} articles liés a la thématique</h3>
          <h3>Top articles</h3>
          <div id="exposed">
            <MUIDataTable className="datatable"
              title={""}
              data={exposedArticles}
              columns={exposedColumns}
              options={options}
            />
          </div>
          <h3>Articles non exposés</h3>
          <div id="unexposed">
            <MUIDataTable className="datatable"
              title={""}
              data={unexposedArticles}
              columns={unexposedColumns}
              options={options}
            />
          </div>
        </Fragment>
        );
    } else if (this.state.render === 'timeout') {
      return (
        <div className={classes.filler} >
          <h3>Délai de chargement expiré</h3>
        </div>
      )
    } else {
      return (
        <div className={classes.filler} >
          <CircularProgress className={classes.progress} />
        </div>
      )
    }
  };
}


ArticlesGraph.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ArticlesGraph);
