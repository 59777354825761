import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import config from '../config';

import MUIDataTable from 'mui-datatables';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Datapoint from './sub_components/Datapoint';
import DataEntry from './sub_components/DataEntry';

import { customColor, saveColumnSetup, getGraphQuarterArray } from './utils';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  dense: {
    marginTop: 16,
  },
  fab: {
    margin: theme.spacing.unit,
  },
  filler: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 400
  },
  dataContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'nowrap'
  }
});

const formatDate = (a) => {
  return moment(a, 'YYYY-MM-DD').format('D/M')
}

const formatHour = (day, hour) => {
  // return moment(day + ' ' + hour, 'YYYY-MM-DD HH');
  return day + ' ' + hour;
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const statusOrder = {
  couverture_totale: 0,
  couverture_homepage: 1,
  couverture_france: 2,
  couverture_international: 3,
  couverture_économie: 4,
  'couverture_science_high-tech': 5,
  couverture_culture: 6,
  couverture_sports: 7,
  couverture_santé: 8,
  // couverture_flipboard_actualites: 9
}

const decode = (string) => {
  let finalString = '';
  try { finalString = decodeURIComponent(escape(string)) }
  catch(error) { finalString = string }
  return finalString;
}

class ArticlesGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      render: false,
      coverage: [],
    }
  };

  getData = () => {
    const query = this.props.changeEditeur ? this.props.query : {...this.props.query, ...{editeur: 'all'} };
    axios.post(`${config.backEndServer}/api/editeur/coverage`, this.props.query
    , {withCredentials: true})
      .then((res) => {
        this.setState({ render: true, statuses: res.data });
      });
  };

  componentDidMount = () => {
    this.getData();
  }

  componentDidUpdate = (prevProps) => {
    if ((prevProps.query.fromDate !== this.props.query.fromDate) || (prevProps.query.toDate !== this.props.query.toDate))
      this.getData();
  }

  render = () => {
    const { classes, isAdmin, displaySelect } = this.props;
    const { statuses } = this.state;

    if (this.state.render) {

      const hasCoverage = statuses.some(x => !Array.isArray(x.coverage[0]));

      if (!hasCoverage)
        return null;

      const toDisplay = statuses.map(stats => {
        const statusOrder = {
          couverture_totale: 0,
          couverture_homepage: 0,
          couverture_france: 0,
          couverture_international: 0,
          couverture_économie: 0,
          'couverture_science_high-tech': 0,
          couverture_culture: 0,
          couverture_sports: 0,
          couverture_santé: 0,
          // couverture_flipboard_actualites: 0
        }
        stats.coverage.forEach(coverage => {
          for (let category in coverage) {
            // legacy
            if (category !== 'couverture_flipboard_actualites')
              statusOrder[category] += coverage[category]
          }
        })
        for (let category in statusOrder) {
          statusOrder[category] = Math.round(statusOrder[category] / stats.coverage.length) + '%';
        }
        return [statusOrder, stats._id];
      });
      return (
        <div id="coverage" >
          <div className="article-container">
            <h3>Taux de couverture</h3>
            {
              toDisplay.map(stats =>
                <Fragment key={stats[1]}>
                  <h4>{stats[1]}</h4>
                  <div>{Object.keys(stats[0]).map(key => <DataEntry key={key} title={capitalizeFirstLetter(key.split('_')[1])} info={stats[0][key]} />)}</div>
                  </Fragment>)
            }
          </div>
        </div>
        );
    } else {
      return (
        <div className={classes.filler} >
          <CircularProgress className={classes.progress} />
        </div>
      )
    }
  };
}


ArticlesGraph.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ArticlesGraph);
