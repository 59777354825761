import React, { Component, Fragment } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import config from '../../config';

const styles = theme => ({
});


class SocketToast extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    this.props.socket.subDailyReportStatus((err, status, reportingUrl) => {
      if (status === 'done')
        toast(<Fragment>Nouveau reporting généré: <a target="_blank" href={config.backEndServer + '/reporting/' + reportingUrl} download>{reportingUrl}</a></Fragment>, {
          type: toast.TYPE.SUCCESS
        });
    });
  }

  render = () => {
    return (
    <ToastContainer
        position="bottom-right"
        autoClose={30000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
    />
  )}
}

SocketToast.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SocketToast);
