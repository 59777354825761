import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import Swal from "sweetalert2";

import config from '../config';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Card from '@material-ui/core/Card';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import UserInfo from './sub_components/UserInfo';

const styles = theme => ({
  fabAdd: {
    margin: theme.spacing.unit,
    width: 42,
    height: 42,
    marginTop: 20
  },
  fab: {
    // margin: theme.spacing.unit,
    width: 42,
    height: 42,
    backgroundColor: '#57AAD9',
    // marginTop: 20
  },
  fabDelete: {
    backgroundColor: '#ef5350',
    width: 42,
    height: 42,
    // marginTop: 20
  },
  actionContainer: {
    marginTop: 40,
    marginBottom: 40,
    textAlign: 'right'
  }
});

class Admin extends Component {
  constructor(props) {
    super();
    this.state = {
      users: [],
      isOpen: false,
    };
  }

  componentDidMount = () => {
    axios.get(`${config.backEndServer}/api/user`,
      { withCredentials: true })
      .then((res) => {
        this.setState({ render: true, users: res.data.map(user => { return { ...user, password: '' } }) });
      });
  }

  handleChange = (name, index) => event => {
    // const value = target.type === 'checkbox' ? target.checked : target.value;
    const [modifiedUser] = this.state.users.slice(index, index + 1);
    modifiedUser[name] = event.target.value;

    document.getElementsByClassName("tableUser")[0].childNodes[index].lastChild.childNodes[0].style.visibility = "visible";

    this.setState((prevState, props) => {
      prevState.users[index] = modifiedUser;
      return { users: [...prevState.users] };
    });
  }

  handleSubmit = (user) => {
    let isEmptyOrSpaces = (str) => {
      if (str == undefined) {
        return;
      }
      return str == null || str.match(/^ *$/) != null;
    }

    if (isEmptyOrSpaces(user.email) || user.email == undefined || user.email == "" || isEmptyOrSpaces(user.editeur) || user.editeur == undefined || user.editeur == "" || user.sessionLimit == undefined || user.sessionLimit == "") {
      Swal.fire(
        'Attention',
        "Veuillez remplir tous les champs",
        'warning'
      );
    } else {
      axios.post(`${config.backEndServer}/api/user`, { users: user },
        { withCredentials: true })
        .then((res) => {
          Swal.fire(
            'Ok',
            'Utilisateur sauvegardé!',
            'success'
          );
          this.setState({ users: res.data.map(user => { return { ...user, password: '' } }) })
        })
        .catch((err) => {
          Swal.fire(
            'Erreur',
            err.message,
            'error'
          )
        });
    }
  }

  handleOpenOrClose = (user) => {
    this.setState({ isOpen: !this.state.isOpen, deleteUser: user });
  };

  handleDelete = () => {
    axios.post(`${config.backEndServer}/api/user/delete`, { id: this.state.deleteUser._id },
      { withCredentials: true })
      .then((res) => {
        Swal.fire(
          'Ok',
          'Utilisateur supprimé!',
          'success'
        );
        this.setState({ users: res.data.map(user => { return { ...user, password: '' } }), isOpen: !this.state.isOpen, deleteUser: {} })
      })
      .catch((err) => {
        Swal.fire(
          'Erreur',
          err.message,
          'error'
        )
      });
  }

  handleAddOne = () => {
    const newUser = {};
    this.setState({ users: [...this.state.users, newUser] });
  }

  render = () => {
    const { classes } = this.props;
    return (
      <div>
        <h1>Admin</h1>
        <div className="article-container">
          <h3>Utilisateurs</h3>
          <Card>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>Editeur</TableCell>
                  <TableCell>Sessions Max</TableCell>
                  <TableCell>Dernière Connexion</TableCell>
                  <TableCell>Admin</TableCell>
                  <TableCell>Accès utilisateur</TableCell>
                  <TableCell>Nouveau Mot de Passe</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="tableUser">
                {this.state.users.map((u, index) => <UserInfo key={u._id} user={u} index={index} handleChange={this.handleChange} classes={classes} handleSubmit={this.handleSubmit} handleOpenOrClose={this.handleOpenOrClose} />)}
              </TableBody>
            </Table>
          </Card>
          <div className={classes.actionContainer}>
            <Fab className={classes.fabAdd} color="primary" aria-label="Add" onClick={this.handleAddOne}>
              <AddIcon />
            </Fab>
          </div>
        </div>

        <Dialog
          open={this.state.isOpen}
          onClose={this.handleOpenOrClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{`Est-ce que vous voulez supprimer l'utilisateur ${this.state.deleteUser ? this.state.deleteUser.email : " "} ?`}</DialogTitle>
          <DialogActions>
            <Button onClick={this.handleOpenOrClose} color="primary">
              Annuler
          </Button>
            <Button onClick={this.handleDelete} color="primary" autoFocus>
              Oui
          </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };
}

export default withStyles(styles)(Admin);
