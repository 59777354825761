let config = {
    __DEGUGGING__:false,
    //backEndServer:'http://localhost:3012',
    backEndServer:'https://apidash.eperfconsulting.com',
    alertD: function(...args) {
		if (this.__DEGUGGING__) {
			args.map((arg) => {
				console.log(arg);
			});
		}
	},
}



export default config
