import React, { Component } from 'react';
import axios from 'axios';

import config from '../config';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';


class Report extends Component {
    constructor(props) {
        super();
        this.state = {
            anomalies: [],
        };
    }

    componentDidMount = () => {
        axios.get(`${config.backEndServer}/api/article/report`,
            { withCredentials: true })
            .then((res) => {
                this.setState({ anomalies: res.data });
            });
    }
    render = () => {
        return (
            <div>
                <h1>Anomalies</h1>
                <div className="article-container">
                    <Card>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width: 180 }}>Date</TableCell>
                                    <TableCell>Position</TableCell>
                                    <TableCell>Commentaire</TableCell>
                                    <TableCell style={{ width: 180 }}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="tableUser">
                                {this.state.anomalies.map((a, index) => {
                                    if (a.addDate == undefined) return;
                                    return (<TableRow key={a.addDate}>
                                        <TableCell>
                                            {a.addDate}
                                        </TableCell>
                                        <TableCell>
                                            {a.position}
                                        </TableCell>
                                        <TableCell>
                                            {a.comment}
                                        </TableCell>
                                        <TableCell>
                                            <Button variant="contained" color="primary" onClick={() => window.open(`${window.location.origin}/dashboard/article/${a.titleId}`)}>
                                                Voir l'article
                                            </Button>
                                        </TableCell>
                                    </TableRow>)
                                }
                                )}
                            </TableBody>
                        </Table>
                    </Card>
                </div>
            </div>
        );
    };
}

export default Report;
