import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import config from '../config';
import { withStyles } from '@material-ui/core/styles';

// import TimeGraph from './sub_components/TimeGraph';
import ReactChartkick, { LineChart, AreaChart, ColumnChart } from 'react-chartkick';
import Highcharts from 'highcharts'
import HC_exporting from 'highcharts/modules/exporting'
import HC_offlineExporting from 'highcharts/modules/offline-exporting'
import Datapoint from './sub_components/Datapoint';

import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Up from '@material-ui/icons/TrendingUp';
import Down from '@material-ui/icons/TrendingDown';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';

import TagList from './sub_components/TagList';
import DataEntry from './sub_components/DataEntry';
import ScalingTagList from './sub_components/ScalingTagList';
import FlagCluster from './sub_components/FlagCluster';
import Coverage from './sub_components/Coverage';

import { getDisplayColors, getQuarterColor, customColor, getTitle, categoriesOptions, calcQuarterScore,
  getGraphQuarter, getSocialHour, socialColor, splitNumbers, replaceCharacters
} from './utils'

import { groupBy } from 'lodash';

HC_exporting(Highcharts);
HC_offlineExporting(Highcharts);
ReactChartkick.addAdapter(Highcharts);
momentDurationFormatSetup(moment);

const decode = (string) => {
  let finalString = '';
  try { finalString = decodeURIComponent(escape(string)) }
  catch(error) { finalString = string }
  return finalString;
}

const displayName = {
  "score_applenews": 'Applenews',
  "score_google_desktop_top": 'A la une',
  "score_google_desktop_cat": 'Sections',
  "score_google_desktop_topic": 'Topic',
  "score_google_desktop_homepage": 'Homepage',
  "score_google_desktop_france": 'France',
  "score_google_desktop_international": 'International',
  "score_google_desktop_économie": 'Economie',
  "score_google_desktop_science_high-tech": 'Science High-Tech',
  "score_google_desktop_culture": 'Culture',
  "score_google_desktop_sports": 'Sports',
  "score_google_desktop_santé": 'Santé',
  "score_google_mobile_webview_top": 'A la une',
  "score_google_mobile_webview_cat": 'Sections',
  "score_google_mobile_webview_topic": 'Topic',
  "score_google_mobile_webview_homepage": 'Homepage',
  "score_google_mobile_webview_france": 'France',
  "score_google_mobile_webview_international": 'International',
  "score_google_mobile_webview_économie": 'Economie',
  "score_google_mobile_webview_santé": 'Santé',
  "score_google_mobile_webview_sports": 'Sports',
  "score_google_mobile_webview_science_high-tech":'Science High-Tech',
  "score_google_mobile_webview_culture": 'Culture',
  "score_google_mobile_webview_A la une": 'A la une',
  "score_google_mobile_webview_Economie": 'Economie',
  "score_google_desktop_A la une": 'A la une',
  "score_google_desktop_Economie": 'Economie'
}

const sourceColor = {
  score_google_desktop: 'desktop-color',
  score_google_mobile_webview: 'mobile-color',
  score_flipboard_mobile: 'flipboard-color',
  score_applenews: 'applenews-color'
};

const formatDate = (a, lenToCut) => {
  if (lenToCut === 9)
    return moment(a, 'YYYY-MM-DD HH').format('D/M')
  return moment(a, 'YYYY-MM-DD HH').format('H[h]')
}

const DataCategory = (props) => {
  const { scores, category, classes } = props;

  return (
    <Grid item xs={4} className={sourceColor[category]}>
      <div className="data-entries-title">
        <Typography className={classes.categoryTitle} variant="h5" component="h2" gutterBottom>
          {`${sourceScore[category]} - `}
          <span className={classes.score} >{splitNumbers(scores[category])}</span>
        </Typography>
      </div>
      <div className="data-entries">
        {
          Object.keys(scores || {}).map(key => {
            if (displayName[key] && scores[key] && key.indexOf(category) > -1 && key !== category)
              return <DataEntry title={displayName[key]} info={scores[key]} />
            return null;
          })
        }
      </div>
    </Grid>
  );
}

const displayLifetime = (string) => {
  if (!string)
    return null;
  const regex = /[0-9]{1,2}-[0-9]{1,2}-([0-9]{1,2}) ([0-9]{1,2}):([0-9]{1,2}):([0-9]{1,2})/;
  const time = regex.exec(string);
  const diff = moment.duration(`${time[1]}.${time[2]}:${time[3]}:${time[4]}`);
  if (diff > 172800000)
    return moment.duration(diff).format("M [mois] d [jours] h [hrs] m [mins]")
  else
    return moment.duration(diff).format("M [mois] d [jour] h [hrs] m [mins]")
}

const sourceScore = {
  score_google_desktop: 'Google Desktop',
  score_google_mobile_webview: 'Google Mobile',
  score_flipboard_mobile: 'Flipboard Mobile',
  score_applenews: 'Applenews'
};

const styles = {
  sectionTitle: {
    marginTop: '80px !important'
  },
  divider: {
    marginBottom: 15,
    marginTop: 15
  },
  info: {
    marginTop: 5,
    fontSize: 14,
    fontWeight: 700
  },
  label: {
    fontWeight: 700,
    fontSize: 11,
    lineHeight: '11px',
    color: 'rgba(0, 0, 0, 0.34)',
    textAlign: 'center'
  },
  tableLink: {
    cursor: 'pointer',
    paddingTop: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  nowrap: {
    whiteSpace: 'nowrap'
  },
  tag: {
    display: 'inline-block',
    backgroundColor: 'rgb(230, 230, 230)',
    minWidth: 0,
    boxSizing: 'border-box',
    borderRadius: 2,
    margin: 2,
    fontSize: 13,
    paddingLeft: 5,
    paddingRight: 5,
    color: 'rgb(82, 81, 110)',
    cursor: 'pointer'
  },
  specialTag: {
    display: 'inline-block',
    backgroundColor: 'rgb(230, 230, 230)',
    minWidth: 0,
    boxSizing: 'border-box',
    borderRadius: 2,
    margin: 2,
    fontSize: 13,
    paddingLeft: 5,
    paddingRight: 5,
    color: 'rgb(82, 81, 110)',
    border: 'solid 2px #044389',
    cursor: 'pointer',
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)'
  },
  warningTimeframe: {
    position: 'fixed',
    height: 90,
    width: 100,
    top: 200,
    right: 20,
    padding: 10,
    opacity: 0.8,
    zIndex: 10000,
    fontSize: 11,
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: 'rgb(244, 155, 66, 0.8)',
    textAlign: 'center'
  },
  expose: {
    backgroundColor: '#4183c4',
  },
  exposeRow: {
    fontWeight: 700,
    color: 'white',
  },
  exposeRowNowrap: {
    whiteSpace: 'nowrap',
    fontWeight: 700,
    color: 'white',
  }
};

const getQuarter = (start) => {
  return moment().subtract((start + 1)* 15 + moment().minute() % 15, 'minutes').format('HH_mm-') + (moment().subtract((start + 1)* 15 + moment().minute() % 15, 'minutes').minute() + 15);
}

const displayTrend = (cluster, classes) => {
  let recentTrend = null, olderTrend = null;
  let shortStart = null, shortEnd = null, longStart = null, longEnd = null;

  let today = moment().format('YYYY-MM-DD');

  if (!cluster.scores_quarts[today])
    return null;

  if (cluster.scores_quarts[today][getQuarter(0)])
    shortStart = cluster.scores_quarts[today][getQuarter(0)].score;
  if (cluster.scores_quarts[today][getQuarter(3)])
    shortEnd = cluster.scores_quarts[today][getQuarter(3)].score;
  if (cluster.scores_quarts[today][getQuarter(0)] && cluster.scores_quarts[today][getQuarter(1)])
    longStart = cluster.scores_quarts[today][getQuarter(0)].score + cluster.scores_quarts[today][getQuarter(1)].score;
  if (cluster.scores_quarts[today][getQuarter(14)] && cluster.scores_quarts[today][getQuarter(15)])
    longEnd = cluster.scores_quarts[today][getQuarter(14)].score + cluster.scores_quarts[today][getQuarter(15)].score;

  if (!!(shortStart) && !!(shortEnd)) {
    if (shortStart > shortEnd)
      recentTrend = (<Up />);
    else
      recentTrend = (<Down />);
  }
  if (!!(longStart) && !!(longEnd)) {
    if (longStart > longEnd)
      olderTrend = (<Up />);
    else
      olderTrend = (<Down />);
  }

  if (recentTrend === null && olderTrend === null)
    return null;

  return (
    <Card style={{
        width: 'auto',
        maxWidth: 120,
        height: '107px',
        margin: 5,
        display: 'inline-block',
        padding: 10
      }}>
    { recentTrend ?
      <div>
        <div className={classes.info}>
          { recentTrend }
        </div>
        <div className={classes.label}>
          sur 1h
        </div>
      </div>
        : null
      }
      { olderTrend ?
        <div>
          <div className={classes.info}>
            { olderTrend }
          </div>
          <div className={classes.label}>
            sur 4h
          </div>
        </div>
        : null
      }
  </Card>
  );
}

class Cluster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cluster: null,
      fullTimeFrame: false
    }
  };

  componentDidMount = () => {
    axios.post(`${config.backEndServer}/api/cluster/${this.props.match.params.id}`,
      { query: this.props.query },
      { withCredentials: true })
      .then((res) => {
        const init = {
          score: 0
        }
        if (res.data) {
          res.data.articlesCluster = res.data.articlesCluster.map(a => { return {...init, ...a}; });
          this.setState({ render: true, cluster: res.data });
        }
      });
  }

  goToCouverture = (newParams) => {
      this.props.newQuery({...this.props.query, ...newParams },
        () => { window.open(`${window.location.origin}/dashboard/couverture`) });
  }

  mergeCols = (arraysToMerge, arraysTitles) => {
    const nbCols = arraysToMerge.length;
    const nbRows = arraysToMerge[0].length;
    let merged = [];
    for(let i=0; i<nbRows; i++) {
      let item = {};
      for(let k=0; k<nbCols; k++) {
        item[arraysTitles[k]]=arraysToMerge[k][i];
      }
      merged.push(item);
    }
    return merged;
  }

  render = () => {
    if (this.state.render) {
      const { classes, query, isAdmin } = this.props;
      const { fullTimeFrame, cluster } = this.state;
      let { fromDate, toDate } = this.props.query;

      if (fullTimeFrame === true) {
          fromDate = cluster.first_view.slice(0, -5) + '00:00';
          toDate = cluster.lastModified;
      }
      const isScoreQuarts = typeof this.state.cluster.scores_quarts !== 'undefined';
      const { score, dataChart, scores } = getGraphQuarter(this.state.cluster.scores_quarts, fromDate, toDate);
      const socialChart = getSocialHour(this.state.cluster.articlesCluster.map(editeur => editeur.social_shares_jours), fromDate, toDate);;
      const optionsColumn = {
        exporting: {
             fallbackToExportServer: false
         },
      };

      const [editorArticles] = this.state.cluster.articlesCluster
        .filter(a => this.props.query.editeur === a._id)

      let coverage = 0;
      if (editorArticles)  {
        if (!editorArticles.score)
          coverage = 1;
        else {
          for (let i = 0; i < editorArticles.titre.length; i++) {
            if (editorArticles.lastModified[i] >= moment().startOf('day').format('YYYY-MM-DD HH:mm:00'))
              coverage = 3;
            coverage = Math.max(coverage, 2);
          }
        }
      }

      return (
        <div className="article-container">
          <FlagCluster status={this.state.cluster.status} clusterId={this.state.cluster._id} />
          <h1 className={classes.sectionTitle}>{getTitle(this.state.cluster)}</h1>
          <div>
            <Paper className={classes.warningTimeframe}>
              <div>
                Afficher toute la Durée de Vie
              </div>
              <Switch
                 checked={fullTimeFrame}
                 onChange={() => { this.setState({ fullTimeFrame: !fullTimeFrame}) } }
                 color="primary"
               />
           </Paper>
         </div>
          <h3>Informations</h3>
          <div className="datapoint-container">
            <Datapoint title="Score Complet Cluster" info={this.state.cluster.score} />
            <Datapoint title="Score selon les filtres" info={score} />
            { this.props.query.editeur !== 'all' ?
              <Card style={{
                  width: 'auto',
                  maxWidth: 120,
                  height: '107px',
                  margin: 5,
                  display: 'inline-block'
                }}>
                <CardContent>
                  <Coverage coverage={coverage} />
                </CardContent>
              </Card>
              : null }
              { displayTrend(this.state.cluster, classes) }
            <Datapoint title="Première Détection" info={moment(this.state.cluster.first_view).format('LLL')} />
            <Datapoint title="Dernière Modification" info={moment(this.state.cluster.lastModified).format('LLL')} />
            <Datapoint title="Nombre d'Articles" info={this.state.cluster.urls.length} />
          </div>
          <Divider className={classes.divider} variant="middle" />
          <div className="chart-container">
            <h3>Vie du Cluster</h3>
            <AreaChart data={dataChart} colors={Object.values(customColor)} stacked={true} library={optionsColumn} />
            { socialChart ?
              <Fragment>
                <h3>Evolution des partages sociaux</h3>
                <AreaChart data={socialChart} colors={Object.values(socialColor)} stacked={true} library={optionsColumn} />
                </Fragment>
              : null
            }
          </div>
          <Divider className={classes.divider} variant="middle" />
          <Grid container justify="space-between" spacing={8}>
            <Grid item xs={3}>
              <h3>Entités</h3>
              <Table>
                <TableBody>
                  {this.state.cluster.entities.sort((a, b) => b.count - a.count).slice(0, 6).map(u =>
                    <TableRow key={u['Text']}>
                      <TableCell>
                        { !!(cluster.needed_entities) && cluster.needed_entities.indexOf(u['Text']) > -1 ?
                          <div className={classes.specialTag} onClick={() => this.goToCouverture({clusterEntities: u['Text'], clusterLemmes: ''}) } >
                            {replaceCharacters(u['Text'])}
                          </div>
                          :
                          <div className={classes.tag} onClick={() => this.goToCouverture({clusterEntities: u['Text'], clusterLemmes: ''}) } >
                            {replaceCharacters(u['Text'])}
                          </div>
                        }
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={3}>
            <h3>Lemmes</h3>
            <Table>
              <TableBody>
                {this.state.cluster.lemmes.sort((a, b) => b.count - a.count).slice(0, 6).map(u =>
                  <TableRow key={u.lemme}>
                    <TableCell>
                      { !!(cluster.needed_lemmes) && cluster.needed_lemmes.indexOf(u.text) > -1 ?
                        <div className={classes.specialTag} onClick={() => this.goToCouverture({clusterEntities: '', clusterLemmes: u.lemme}) } >
                          {replaceCharacters(u.lemme)}
                        </div>
                        :
                        <div className={classes.tag} onClick={() => this.goToCouverture({clusterEntities: '', clusterLemmes: u.lemme}) } >
                          {replaceCharacters(u.lemme)}
                        </div>
                      }
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            </Grid>
            <Grid item xs={2}>
              <h3>Catégories</h3>
              <div className="datapoint-container">
                { this.state.cluster.categories.filter(c => typeof categoriesOptions[c] !== 'undefined').map(c =>
                  this.state.cluster.cat_principale === c ?
                    <div key={c} className={classes.specialTag} >
                      {categoriesOptions[c]}
                    </div>
                    : <div key={c} className={classes.tag} >
                      {categoriesOptions[c]}
                    </div>
                  )
                }
              </div>
            </Grid>
            <Grid item xs={2}>
              <h3>Positions</h3>
              <div className="datapoint-container">
                <TagList array={this.state.cluster.positions.map(pos => pos.split('_').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' '))} />
              </div>
            </Grid>
            <Grid item xs={2}>
              <h3>Crawls</h3>
              <div className="datapoint-container">
                <TagList array={this.state.cluster.crawls.filter(a => a !== 'sitemap').map(pos => pos.split('_').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' '))} />
              </div>
            </Grid>
          </Grid>
          { isAdmin && this.state.cluster.terms && this.state.cluster.terms.length ?
            <Fragment>
              <Divider className={classes.divider} variant="middle" />
              <Grid container justify="space-between" spacing={8}>
                <Grid item xs={9}>
                  <h3>Entités Limma</h3>
                  <Table>
                    <TableBody>
                      {this.state.cluster.terms.slice(0,17).map(u =>
                        <TableRow key={u['form']}>
                          <TableCell>
                            <div className={classes.tag} >
                              {replaceCharacters(u['form'])}
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </Fragment>
            : null
          }
          <Divider className={classes.divider} variant="middle" />
          {
            this.state.cluster.articlesCluster && this.state.cluster.articlesCluster.filter(a => a.score > 0).length ?
            <Fragment>
              <h3>Classement des éditeurs sur la période selectionnée</h3>
              <TableBody>
                {
                  this.state.cluster.articlesCluster.filter(a => fullTimeFrame === true ? a.score > 0 : a.scoreFiltre > 0).sort((a, b) => fullTimeFrame === true ? b.score - a.score : b.scoreFiltre - a.scoreFiltre).map((editeur, key) =>
                    <TableRow key={editeur._id} className={editeur._id === query.editeur ? classes.expose : null} >
                      <TableCell className={editeur._id === query.editeur ? classes.exposeRow : null}>
                        {key + 1}
                      </TableCell>
                      <TableCell className={editeur._id === query.editeur ? classes.exposeRow : null}>
                        {editeur._id}
                      </TableCell>
                      <TableCell  className={editeur._id === query.editeur ? classes.exposeRowNowrap : classes.nowrap}>
                        {splitNumbers(fullTimeFrame === true ? editeur.score : editeur.scoreFiltre)}
                      </TableCell>
                      <TableCell className={editeur._id === query.editeur ? classes.exposeRow : null}>
                        {Math.round((fullTimeFrame === true ? editeur.score : editeur.scoreFiltre) / score * 100)}%
                      </TableCell>
                      <TableCell className={editeur._id === query.editeur ? classes.exposeRow : null}>
                        {/* { editeur.titre.map((u, key) =>
                          editeur.scores[key] > 0 ?
                          <TableRow className={classes.tableLink} onClick={() => window.open(`${window.location.origin}/dashboard/article/${editeur.articleId[key]}`)} >
                          {Math.round(editeur.scores[key] / this.state.cluster.score * 100)}% - {decode(u)}</TableRow>
                          : null
                        )} */}
                        {
                          this.mergeCols([fullTimeFrame === true ? editeur.scores : editeur.scoresFiltres, editeur.titre, editeur.articleId],['score','titre','article'])
                          .sort((a, b) => b.score - a.score)
                          .map((item) =>
                          item.score > 0 ?
                          <TableRow className={classes.tableLink} onClick={() => window.open(`${window.location.origin}/dashboard/article/${item.article}`)} >
                          {Math.round(item.score / score * 100)}% - {decode(item.titre)}</TableRow>
                          : null
                        )}
                      </TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            </Fragment>
            : null
          }
          {
              isScoreQuarts ?
              <Grid item xs={12}>
                <Divider className={classes.divider}variant="middle" />
                <h3>Scores</h3>
                <Grid container justify="center" spacing={12}>
                  {
                    Object.keys(sourceScore || {}).map(key => {
                      if (scores[key])
                        return <DataCategory key={key} classes={classes} category={key} scores={scores} />
                      return null;
                    })
                  }
                </Grid>
                <h1></h1>
                <h1></h1>
              </Grid>
              : null
            }
          {
            this.state.cluster.articlesCluster && this.state.cluster.articlesCluster.filter(a => !a.score || a.scores.indexOf(0) > -1).length ?
            <Fragment>
              <h3>Articles non exposés sur le même sujet</h3>
              <div className="datapoint-container">
                {this.state.cluster.articlesCluster.filter(a => a.scores.indexOf(0) > -1).map(editeur => {
                     return editeur.scores.map((score, key) => {
                       if (score === 0)
                         return <Datapoint key={editeur._id} link={`/dashboard/article/${editeur.articleId[key]}`}
                           title={editeur._id} subtitle={decode(editeur.titre[key])} />
                       return null;
                     })
                })}
              </div>
            </Fragment>
            : null
          }
          </div>
      );
    }
    else {
      return (
        <div >
          <CircularProgress />
        </div>
      )
    }
  }
}

Cluster.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Cluster);
