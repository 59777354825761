import React, { Component } from 'react';
import EditeursList from './EditeursList';

const Editeurs = (props) => {
  return (
    <div>
      <h1>Classement Editeurs</h1>
      <EditeursList {...props}/>
    </div>
  );
}

export default Editeurs;
