import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import config from '../config';
import _ from 'lodash';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Fab from '@material-ui/core/Fab';
import DownloadIcon from '@material-ui/icons/SaveAlt';

import ClusterLine from './sub_components/ClusterLine';
import CoverageStatus from './CoverageStatus';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  fieldContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  listContainer: {
    paddingBottom: 150
  },
  filler: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 400
  },
  ddl: {
    position: 'absolute',
    right: 30
  },
  subtitle: {
    color: 'grey',
    textAlign: 'center'
  },
  fab: {
    margin: theme.spacing.unit,
    width: 42,
    height: 42,
    backgroundColor: '#57AAD9',
    marginTop: 20
  }
});

class ClusterList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      render: false,
      clusters: []
    }
  };BertList

  getData = () => {
    const query = this.props.changeEditeur ? this.props.query : {...this.props.query, ...{editeur: 'all'} };
    axios.post(`${config.backEndServer}/api/cluster/bert`, this.props.query
    , {withCredentials: true, timeout: 60000 })
      .then((res) => {
        this.setState({ render: 'true', clusters: res.data });
      })
      .catch(err => {
        this.setState({ render: 'timeout'});
      });
  };

  componentDidMount = () => {
    this.getData();
  }

  render = () => {
    const { classes, query } = this.props;
    let { clusters } = this.state;

    if (this.state.render) {
      return (
          <div className="article-container">
            <div className={classes.listContainer} >
              { clusters.map((c, index) => <ClusterLine key={c._id} cluster={c} editeur={this.props.query.editeur} position={index + 1} detailed={false} />) }
            </div>
          </div>
        );
    } else {
      return (
        <div className={classes.filler} >
          <CircularProgress className={classes.progress} />
        </div>
      );
    }
  };
}

ClusterList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ClusterList);
