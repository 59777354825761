import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import NoIcon from '@material-ui/icons/NotInterested';
import WarningIcon from '@material-ui/icons/Warning';
import TimerOff from '@material-ui/icons/TimerOff';
import CheckIcon from '@material-ui/icons/Check';

const coverageIcons = [ <NoIcon />, <WarningIcon />, <TimerOff />, <CheckIcon />];

const styles = {
  label: {
    fontWeight: 700,
    fontSize: 11,
    lineHeight: '11px',
    color: 'rgba(0, 0, 0, 0.34)',
    textAlign: 'center'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  }
};

const Coverage = (props) => {
  const { classes, coverage } = props;
  if (typeof coverage === 'undefined')
    return null;

  const coverageLabel = ['aucune couverture', 'couverture non exposé', 'Couverture + de 24h', 'Couverture Du Jour'];
  const icon = coverageIcons[coverage];
  const colorsLabels = ['cluster-0-color-fill', 'cluster-1-color-fill', 'cluster-2-color-fill', 'cluster-3-color-fill'];
  return (
    <div className={classes.container}>
      <div className={`${colorsLabels[coverage]} color-fill`}>
        {coverageIcons[coverage]}
      </div>
      <div className={classes.label}>
        {coverageLabel[coverage]}
      </div>
    </div>
  );
}

Coverage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Coverage);
