import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import config from '../config';

import MUIDataTable from 'mui-datatables';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactChartkick, { AreaChart, ColumnChart, LineChart } from 'react-chartkick';
import Highcharts from 'highcharts'
import Datapoint from './sub_components/Datapoint';
import HC_exporting from 'highcharts/modules/exporting'
import HC_offlineExporting from 'highcharts/modules/offline-exporting'

import { customColor, saveColumnSetup, displayScore } from './utils';

HC_exporting(Highcharts);
HC_offlineExporting(Highcharts);
ReactChartkick.addAdapter(Highcharts)

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  dense: {
    marginTop: 16,
  },
  fab: {
    margin: theme.spacing.unit,
  },
  filler: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 400
  },
  dataContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'nowrap'
  }
});

const formatDate = (a, lenToCut) => {
  if (lenToCut === 9)
    return moment(a, 'YYYY-MM-DD HH').format('D/M')
  return moment(a, 'YYYY-MM-DD HH').format('H[h]')
}

const decode = (string) => {
  let finalString = '';
  try { finalString = decodeURIComponent(escape(string)) }
  catch(error) { finalString = string }
  return finalString;
}

class UnexposedArticlesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      render: 'false',
      articles: [],
    }
  };

  getData = () => {
    const {query, coverage, orphan }  = this.props;
    let url = `${config.backEndServer}/api/article/coverage`;
    if (coverage === 2)
      url = `${config.backEndServer}/api/cluster/coverage`;
    axios.post(url, { ...query, sitemap: true, coverage, orphan }
    , {withCredentials: true, timeout: 60000 })
      .then((res) => {
        this.setState({ render: 'true', articles: res.data });
      })
      .catch(err => {
        this.setState({ render: 'timeout'});
      });
  };

  componentDidMount = () => {
    this.getData();
  }

  render = () => {
    const { classes, isAdmin, displaySelect } = this.props;
    const exposedClusterArticles = this.state.articles.filter(a => a[2]);

    const columns = [
        { name: '#', options: {display: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (tableMeta.rowIndex + 1);
          },
          sort: false
        } },
        { name: 'Editeur', options: { display: false } },
        { name: 'Score Cluster Parent', options: { display: true , sortDirection: 'desc' } },
        { name: 'Titre', options: { display: true } },
        { name: 'Mise à jour Sitemap', options: { display: true } },
        { name: 'Heure de publication', options: { display: true } },
        { name: 'Dernière Modification', options: { display: true } },
        { name: 'Première exposition', options: { display: false } },
        { name: 'id', options: { display: false } },
    ]

    const options = {
      filter: false,
      selectableRows: false,
      responsive: "stacked",
      rowsPerPage: 50,
      rowsPerPageOptions: [10,50, 100],
      onRowClick: (rowData) => window.open(`${window.location.origin}/dashboard/article/${rowData[rowData.length - 1]}`),
      onColumnViewChange: (changedColumn, action) => saveColumnSetup('articleSettings', changedColumn, action)
    };

    if (this.state.render === 'true') {
      const { editeur } = this.state;
      return (
        <div>
          <MUIDataTable className="datatable"
            title={""}
            data={exposedClusterArticles}
            columns={columns}
            options={options}
          />
        </div>
        );
    } else if (this.state.render === 'timeout') {
      return (
        <div className={classes.filler} >
          <h3>Trop de résultats, délais de chargement expiré. Veuillez sélectionner des paramètres de filtre plus spécifiques.</h3>
        </div>
      )
    }
      else {
      return (
        <div className={classes.filler} >
          <CircularProgress className={classes.progress} />
        </div>
      )
    }
  };
}


UnexposedArticlesList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UnexposedArticlesList);
