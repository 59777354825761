import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';
import config from '../config';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import EditeursList from './EditeursList';
import ArticlesList from './ArticlesList';
import ArticlesGraph from './ArticlesGraph';
import RankingEditeur from './RankingEditeur';
import SitemapEditeur from './SitemapEditeur';
import CoverageStatus from './CoverageStatus';
import ExposedEditeur from './ExposedEditeur';
import CoverageArticlesList from './CoverageArticlesList';
import SitemapsList from './SitemapsList';
import Keywords from './Keywords';

import GoTo from './sub_components/GoTo';

class SuiviGeneral extends Component {
    constructor(props) {
      super(props);
      this.state = {
        value: 0
      }
    };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  render = () => {
    const { value } = this.state;
    let actualValue = value; // UGLY REMOVE ASAP
    let openTab;

    if (this.props.query.editeur === 'all')
      this.props.query.editeur = JSON.parse(localStorage.getItem('user')).editeur;
    this.props.query.groupe = 'editeur';
    if (this.props.query.editeur === 'all')
      return (<h1>Pas d'éditeur par défault pour votre profil, veuillez en choisir un.</h1>);

    const title = `${this.props.query.editeur}`;
    const queryNow = { ...this.props.query, ...{ fromDate: moment().subtract(4, 'hours').format('YYYY-MM-DDTHH:mm'),
      toDate: moment().endOf('day').format('YYYY-MM-DDTHH:mm') } }
    const queryDay = { ...this.props.query, ...{ fromDate: moment().startOf('day').format('YYYY-MM-DDTHH:mm'),
      toDate: moment().endOf('day').format('YYYY-MM-DDTHH:mm') } };
    const queryWeek = { ...this.props.query, ...{ fromDate: moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DDTHH:mm'),
      toDate: moment().endOf('day').format('YYYY-MM-DDTHH:mm') } };
    const queryMonth = { ...this.props.query, ...{ fromDate: moment().subtract(30, 'days').startOf('day').format('YYYY-MM-DDTHH:mm'),
      toDate: moment().endOf('day').format('YYYY-MM-DDTHH:mm') } };

    if (!this.props.isAdmin)
      actualValue++;
    if (actualValue === 0) {
      openTab = (
        <div>
          <GoTo url="/articles" />
          <h1></h1>
          <ArticlesGraph query={this.props.query} />
          <GoTo url="/editeurs" />
          <RankingEditeur query={this.props.query} />
          <GoTo url="/couverture" />
          <CoverageStatus query={this.props.query} />
          <GoTo url="/articles" />
          <GoTo url="/sitemap" sitemap={true} />
          <SitemapEditeur query={this.props.query} />
          <GoTo url="/articles" />
          <h1></h1><h3>Meilleurs Articles</h3>
          <ArticlesList query={ {...this.props.query, ...{limit: 20} } } />
          <h1></h1>
      </div>);
    } else if (actualValue === 1) {
      openTab = (
        <div>
          <GoTo url="/articles" />
          <h1></h1>
          <ArticlesGraph query={queryMonth} />
          <GoTo url="/editeurs" />
          <RankingEditeur query={queryMonth} />
          <GoTo url="/couverture" />
          <CoverageStatus query={queryMonth} />
          <GoTo url="/articles" />
          <GoTo url="/sitemap" sitemap={true} />
          <SitemapEditeur query={queryMonth} />
          <GoTo url="/articles" />
          <h1></h1>
          <h3>Meilleurs Articles</h3>
          <ArticlesList query={ {...queryMonth, ...{limit: 20} } } />
        </div>
      );
    } else if (actualValue === 2) {
      openTab = (
        <div>
          <GoTo url="/articles" />
          <h1></h1>
          <ArticlesGraph query={queryWeek} />
          <GoTo url="/editeurs" />
          <RankingEditeur query={queryWeek} />
          <GoTo url="/couverture" />
          <CoverageStatus query={queryWeek} />
          <GoTo url="/articles" />
          <GoTo url="/sitemap" sitemap={true} />
          <SitemapEditeur query={queryWeek} />
          <GoTo url="/articles" />
          <h1></h1>
          <h3>Meilleurs Articles</h3>
          <ArticlesList query={ {...queryWeek, ...{limit: 20} } } />
          <h1></h1>
        </div>
      );
    } else if (actualValue === 3) {
      openTab = (
        <div>
          <GoTo url="/articles" />
          <h1></h1>
          <ArticlesGraph query={queryDay} />
          <ExposedEditeur query={queryDay} />
          <GoTo url="/couverture" />
          <CoverageStatus query={queryDay} />
          <GoTo url="/articles" />
          <GoTo url="/sitemap" sitemap={true} />
          <SitemapEditeur query={queryDay} />
          <GoTo url="/articles" />
          <h1></h1>
          <h3>Meilleurs Articles</h3>
          <ArticlesList query={ {...queryDay, ...{limit: 20} } } />
          <h1></h1>
      </div>)
    } else if (actualValue === 4) {
      openTab = (
        <div>
          <h1></h1>
          <h3>Mots Clefs Actifs</h3>
          <Keywords query={queryNow} newQuery={this.props.newQuery} queryDay={queryDay} />
          <h3>Articles non exposés dans un Cluster Actif</h3>
          <CoverageArticlesList query={queryDay} coverage={1} />
          <h3>Articles trop vieux dans un Cluster Actif</h3>
          <CoverageArticlesList query={queryNow} coverage={2} />
          <h3>Articles Orphelins</h3>
          <CoverageArticlesList query={queryDay} coverage={3} orphan={true} />
          <h3>Derniers articles Sitemaps non exposés</h3>
          <SitemapsList query={{...queryDay, fromDate: moment().subtract(4, 'hours').format('YYYY-MM-DDTHH:mm'), unexposed: true }} />
          <h1></h1>
      </div>);
    }
    return (
      <div>
        <h1>{title}</h1>
        <Paper style={{width: this.props.isAdmin ? 820 : 640, margin: '0 auto'}}>
          <Tabs
            value={value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
          >
            { this.props.isAdmin ? <Tab id="selected" label="Periode Sélectionée" /> : null }
            <Tab id="month" label="Sur le Mois" />
            <Tab id="week" label="Sur la Semaine" />
            <Tab id="day" label="Aujourd'hui" />
            <Tab id="live" label="Temps Réel" />
          </Tabs>
        </Paper>
        {openTab}
      </div>
    )
  }
}

export default SuiviGeneral;
