import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import moment from 'moment';
import config from '../config';
import _ from 'lodash';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import NewsboxLine from './sub_components/NewsboxLine';
import NewsboxKeywords from './sub_components/NewsboxKeywords';
import NewsboxListSujet from './sub_components/NewsboxListSujet';

const styles = theme => ({
    listContainer: {
        paddingBottom: 150
    },
    filler: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 400
    },
    subtitle: {
        color: 'grey',
        textAlign: 'center'
    },
});

class NewsboxList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            render: false,
            clusters: [],
            period: '24',
            isLoading: false,
            isLoadingDetailed: false,
            pagination: 0,
            total: null,
            apiLemmes: null, 
            nbExpressions: 0,
            value: 0
        }
    };

    getData = () => {
        if (!this.state.isLoading && this.state.total !== this.state.clusters.length) {
            this.setState({ isLoading: true });
            axios.post(`${config.backEndServer}/api/cluster`, {
                ...this.props.query,
                pagination: this.state.pagination,
            }, { withCredentials: true })
            .then((res) => {
                const nbExp = res.data.clusters.reduce((accumulator, currentValue) => {
                    return accumulator  + (currentValue && currentValue.newsboxExp && currentValue.newsboxExp.length > 0 ? currentValue.newsboxExp[0].length : 0)
                }, 0)
                this.setState({ nbExpressions: nbExp })
                if (res.data.pageInfo) {
                    this.setState({
                        render: true,
                        clusters: [...res.data.clusters],
                        isLoading: false,
                        pagination: this.state.pagination + res.data.clusters.length,
                        total: res.data.pageInfo[0] ? res.data.pageInfo[0].count > 50 ? 50 : res.data.pageInfo[0].count : 0,
                        apiLemmes: res.data.pageInfo[0] && res.data.pageInfo[0].lemmes ? res.data.pageInfo[0].lemmes : null,
                    });
                } else {
                    const toAppend = res.data.clusters.filter(x => !this.state.clusters.some(n => n._id === x._id))
                    if (!toAppend.length) {
                        this.setState({
                            isLoading: false,
                            total: this.state.clusters.length
                        })
                    } else if (this.state.pagination == 40) {
                        this.setState({
                            clusters: [...this.state.clusters, ...toAppend.slice(0, 10)],
                            isLoading: false,
                            pagination: this.state.pagination + res.data.clusters.length,
                            category: this.state.category,
                            position: this.state.position,
                            entities: this.state.entities,
                            lemmes: this.state.lemmes,
                            orphan: this.state.orphan
                        });
                    } else {
                        this.setState({
                            clusters: [...this.state.clusters, ...toAppend],
                            isLoading: false,
                            pagination: this.state.pagination + res.data.clusters.length,
                            category: this.state.category,
                            position: this.state.position,
                            entities: this.state.entities,
                            lemmes: this.state.lemmes,
                            orphan: this.state.orphan
                        });
                    }
                }
            });
        }
    };

    updateNbExp = (nb) => {
        this.setState({ nbExpressions: this.state.nbExpressions + nb })
    }

    getDetails = () => {
        const clustersToUpdate = this.state.clusters.filter(c => typeof c.coverage === 'undefined').slice(0, 10);
        if (!this.state.isLoadingDetailed && clustersToUpdate.length) {
            this.setState({ isLoadingDetailed: true });
            axios.post(`${config.backEndServer}/api/cluster/details`, { ids: clustersToUpdate.map(c => c._id), editeur: this.props.query.editeur, groupe: this.props.query.groupe }, { withCredentials: true })
                .then((res) => {
                    const details = res.data;
                    const newClusters = this.state.clusters.map(c => {
                        const index = details.findIndex(t => c._id === t._id)
                        if (index > -1) {
                            if (!!(this.props.coverage)) {
                                c.coverage = details[index].coverage;
                                c.coverageList = details[index].coverageList;
                                c.urlList = details[index].urls;
                                c.lastDateArticleList = details[index].lastDateArticleList;
                                c.lastExposedList = details[index].lastExposedList;
                            }
                        }
                        return c;
                    });
                    this.setState({ clusters: newClusters, isLoadingDetailed: false });
                });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll, false);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!!(this.props.coverage) && !this.state.isLoading && (prevState.isLoading || prevState.isLoadingDetailed)) {
            this.getDetails();
        }
    }

    onScroll = () => {
        if (
            (
                this.state.clusters.length && !this.state.isLoading &&
                window.innerHeight + window.scrollY) >= (ReactDOM.findDOMNode(this).offsetHeight)
        ) {
            this.getData();
        }
    }

    componentDidMount = () => {
        window.addEventListener('scroll', this.onScroll, false);
        this.getData();
    }

    handleChange = (event, newValue) => {
        this.setState({ value: newValue });
    };

    render = () => {
        const { classes, query } = this.props;
        let { clusters, value } = this.state;
        let openTab;

        let isCustomPeriod = true;

        const periodOptions = [
            { value: '24', label: "Aujourd'hui" },
            { value: '2', label: "2 Dernières Heures" },
            { value: '6', label: "6 Dernières Heures" },
            { value: '12', label: "12 Dernières Heures" }
        ];

        if (this.props.query.fromDate === moment().startOf('day').format('YYYY-MM-DDTHH:mm') &&
            this.props.query.toDate === moment().endOf('day').format('YYYY-MM-DDTHH:mm')) {
            isCustomPeriod = false;
        }

    if (value === 0) {
        openTab = (
            <div className="article-container">
            <h1>{this.state.total} résultats correspondants</h1>
            <h5 className={classes.subtitle}>{this.state.nbExpressions} expressions validées aujourd'hui</h5>
            {this.state.apiLemmes ?
                <h4 className={classes.subtitle}>Recherche optimisée sur les mots: {this.state.apiLemmes.join(', ')}</h4>
                : null
            }
            <div id="listContainer" className={classes.listContainer} >
                {clusters.map((c, index) => <NewsboxLine key={c._id} cluster={c} editeur={this.props.query.editeur} position={index + 1} detailed={this.state.detailed} updateNbExp={this.updateNbExp} />)}
                {this.state.isLoading ?
                    <div className={classes.filler} >
                        <CircularProgress className={classes.progress} />
                    </div>
                    : null}
            </div>
        </div>);
        } else if (value === 1) {
            openTab = (
                <div>
                    <NewsboxListSujet query={this.props.query} coverage={true} />
                </div>
            );
        } else if (value === 2) {
            openTab = (
                <div>
                    <NewsboxKeywords query={this.props.query} />
                </div>
            );
        }

        if (this.state.render) {
            return (<div>
                <h1>Newsbox</h1>
                <Paper style={{width: 792, margin: '0 auto', marginBottom: 25}}>
                <Tabs
                    value={value}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab id="validated" label="Valider les expressions pour un cluster" style={{width: 300}} />
                    <Tab id="validatedSujet" label="Valider les expressions pour un sujet" style={{width: 300}} />
                    <Tab id="accepted" label="Voir les expressions validées des 5 derniers jours" style={{width: 300}} />
                </Tabs>
                </Paper>
                {openTab}
            </div>)
        } else {
            return (
                <div className={classes.filler} >
                    <CircularProgress className={classes.progress} />
                </div>
            );
        }
    };
}

NewsboxList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NewsboxList);
