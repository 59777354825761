import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';

import MUIDataTable from 'mui-datatables';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Info from '@material-ui/icons/Info';
import ButtonMU from '@material-ui/core/Button';
import LinkIcon from '@material-ui/icons/Link';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { favicon } from '../utils.js'
import ActuLineArticle from './ActuLineArticle.js'
import ActuList from './ActuList.js'

const styles = {
  clusterContainer: {
    width: 1180,
  },
  meter: {
    width: 1180,
    position: 'relative',
    height: 18,
    overflow: 'hidden'
  },
  hour: {
    position: 'absolute',
  },
  icon: {
    width: 32,
    height: 32
  },
  tab: {
    minWidth: 100,
    width: 100,
  }
}

const editeurs = [
  'Le Monde',
  'Ouest-France',
  '20 Minutes',
  'Franceinfo',
  'Bfmtv.Com',
  'Le Figaro',
  'Le Parisien',
  'Ladepeche.Fr',
  'Sud Ouest',
  'Midi Libre',
  'La Voix Du Nord',
  "L'Independant"
]

class ActuLineEditeur extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 0,
      selectedArticle: null
    }
  };

  handleChange = (event, newValue) => {
    this.setState({ tab: newValue, selectedArticle: null });
  };

  onClick = (article) => {
    if (this.state.selectedArticle && article._id === this.state.selectedArticle._id)
      this.setState({selectedArticle: null})
    else
      this.setState({selectedArticle: article})
  }

  render = () => {
    const { articles, classes, isAdmin, rangeStart, rangeEnd, cluster, dataToGet } = this.props;
    const { tab, selectedArticle } = this.state;

    return (
      <div className={classes.clusterContainer}>
        {articles.map(a =>
            <ActuLineArticle key={a._id} article={a} onClick={this.onClick} cluster={cluster} rangeStart={rangeStart} rangeEnd={rangeEnd} dataToGet={dataToGet} />
          )}
      </div>
  )}
}

ActuLineEditeur.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ActuLineEditeur);
