import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import config from '../config';
import _ from 'lodash';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import ClusterLine from './sub_components/ClusterLine';
import CoverageStatus from './CoverageStatus';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    fieldContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
    listContainer: {
        paddingBottom: 150
    },
    filler: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 400
    },
    ddl: {
        position: 'absolute',
        right: 30
    },
    subtitle: {
        color: 'grey',
        textAlign: 'center'
    },
    fab: {
        margin: theme.spacing.unit,
        width: 42,
        height: 42,
        backgroundColor: '#57AAD9',
        marginTop: 20
    }
});

class Cea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            render: false,
            clusters: [],
            period: '24',
            isLoading: false,
            isLoadingDetailed: false,
            pagination: 0,
            total: null,
            apiLemmes: null,
            value: 0
        }
    };

    getData = (tab) => {
        if (!this.state.isLoading && this.state.total !== this.state.clusters.length) {
            this.setState({ isLoading: true });
            axios.post(`${config.backEndServer}/api/cluster/cea`, {
                ...this.props.query,
                pagination: this.state.pagination,
                tab
            }, { withCredentials: true })
                .then((res) => {
                    this.setState({
                        render: true,
                        clusters: [...res.data.clusters],
                        isLoading: false,
                    });
                });
        }
    };

    getDetails = () => {
        const clustersToUpdate = this.state.clusters.filter(c => typeof c.coverage === 'undefined').slice(0, 10);
        if (!this.state.isLoadingDetailed && clustersToUpdate.length) {
            this.setState({ isLoadingDetailed: true });
            axios.post(`${config.backEndServer}/api/cluster/details`, { ids: clustersToUpdate.map(c => c._id), editeur: this.props.query.editeur, groupe: this.props.query.groupe }, { withCredentials: true })
                .then((res) => {
                    const details = res.data;
                    const newClusters = this.state.clusters.map(c => {
                        const index = details.findIndex(t => c._id === t._id)
                        if (index > -1) {
                            if (!!(this.props.coverage)) {
                                c.coverage = details[index].coverage;
                                c.coverageList = details[index].coverageList;
                                c.urlList = details[index].urls;
                                c.lastDateArticleList = details[index].lastDateArticleList;
                                c.lastExposedList = details[index].lastExposedList;
                            }
                        }
                        return c;
                    });
                    this.setState({ clusters: newClusters, isLoadingDetailed: false });
                });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!!(this.props.coverage) && !this.state.isLoading && (prevState.isLoading || prevState.isLoadingDetailed)) {
            this.getDetails();
        }
    }

    handleChange = (event, newValue) => {
        this.setState({ value: newValue });
        this.getData(newValue);
    };

    componentDidMount = () => {
        this.getData(this.state.value);
    }

    showTabContent = () => {
        const { classes } = this.props;
        let { clusters } = this.state;
        if (clusters.length > 0) {
            return (
                <div id="listContainer" className={classes.listContainer} >
                    {clusters.map((c, index) => <ClusterLine key={c._id} cluster={c} editeur={this.props.query.editeur} position={index + 1} detailed={this.state.detailed} cea={true} />)}
                    {this.state.isLoading ?
                        <div className={classes.filler} >
                            <CircularProgress className={classes.progress} />
                        </div>
                        : null}
                </div>);
        } else {
            return (
                <div className="article-container">
                    <h1>Pas de données</h1>
                </div>
            );
        }
    }

    render = () => {
        const { classes, query } = this.props;
        let { clusters, value } = this.state;
        let actualValue = value;
        let openTab;

        let isCustomPeriod = true;

        const periodOptions = [
            { value: '24', label: "Aujourd'hui" },
            { value: '2', label: "2 Dernières Heures" },
            { value: '6', label: "6 Dernières Heures" },
            { value: '12', label: "12 Dernières Heures" }
        ];

        if (this.props.query.fromDate === moment().startOf('day').format('YYYY-MM-DDTHH:mm') &&
            this.props.query.toDate === moment().endOf('day').format('YYYY-MM-DDTHH:mm')) {
            isCustomPeriod = false;
        }

        if (actualValue === 0) {
            openTab = this.showTabContent()
        } else if (actualValue === 1) {
            openTab = this.showTabContent()
        } else if (actualValue === 2) {
            openTab = this.showTabContent()
        }

        if (this.state.render) {
            return (
                <div className="article-container">
                    {this.props.coverage && !this.props.noSTatus ? <CoverageStatus query={this.props.query} /> : null}
                    <h1>Clusters les plus chauds</h1>
                    <h4 style={{ textAlign: "center" }}>Dernier passage : {moment().startOf('hour').format('DD-MM-YYYY HH:mm')}</h4>
                    <Paper style={{ width: 500, margin: '0 auto' }}>
                        <Tabs
                            value={value}
                            onChange={this.handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                        >
                            <Tab id="selected" label="Periode Sélectionée" />
                            <Tab id="fourHours" label="Sur 4 heures" />
                            <Tab id="twentyFourHours" label="Sur 24 heures" />
                        </Tabs>
                    </Paper>
                    <h1></h1>
                    {openTab}
                </div>
            );
        } else {
            return (
                <div className={classes.filler} >
                    <CircularProgress className={classes.progress} />
                </div>
            );
        }
    };
}

Cea.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Cea);
