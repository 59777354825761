import React, { Component } from 'react';
import axios from 'axios';
import config from '../../config';
import moment from 'moment';
import DesktopIcon from '@material-ui/icons/DesktopWindows';
import MobileIcon from '@material-ui/icons/Smartphone';

export default class ApiStatus extends Component {
  constructor(props) {
    super(props)
    this.state = {
      status: null
    }
  }

  componentDidMount = () => {
    this.getData();
    this.timerID = setInterval(
      () => this.tick(),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    this.setState({});
  }

  getData = () => {
    axios.get(`${config.backEndServer}/api/status`, { withCredentials: true })
      .then((res) => {
        this.setState({ status: res.data });
        setTimeout(this.getData, 30000);
      })
      .catch((err) => {
        if (err && err.response && err.response.status && err.response.status === 401 && err.response.data === 'Unauthorized') // Checks if session alive
          window.location.assign('/login');
        else if (err && err.message === 'Network Error') {
          window.location.assign('/error');
        }
      })
  }

  render = () => {
    if (this.state.status) {
      const desktopColor = !this.state.status['google_desktop'] || moment().diff(this.state.status['google_desktop']) > 60000 * 3 ? 'red' : 'green';
      const mobileColor = !this.state.status['google_mobile_webview'] || moment().diff(this.state.status['google_mobile_webview']) > 60000 * 3 ? 'red' : 'green';
      const flipboardColor = !this.state.status['flipboard_mobile'] || moment().diff(this.state.status['flipboard_mobile']) > 60000 * 3 ? 'red' : 'green';
      const appleColor = !this.state.status['applenews'] || moment().diff(this.state.status['applenews']) > 60000 * 3 ? 'red' : 'green';
      return (
        <div className="apistatus-container">
          <h3>Status API</h3>
          <DesktopIcon style={{color: desktopColor}}/>
          <div>{moment(this.state.status['google_desktop']).fromNow()}</div>
          <MobileIcon style={{color: mobileColor}}/>
          <div>{moment(this.state.status['google_mobile_webview']).fromNow()}</div>
          {/*
            <div style={{color: flipboardColor}}>Flipboard</div>
            <div>{moment(this.state.status['flipboard_mobile']).fromNow()}</div>
          */}
          <div style={{color: appleColor}}>AppleNews</div>
          <div>{moment(this.state.status['applenews']).fromNow()}</div>
        </div>
      );
    }
    else
      return null;
  }
}
