import React, { Fragment } from 'react';
import Info from '@material-ui/icons/Info';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import _ from 'lodash';

import ReactChartkick, { AreaChart } from 'react-chartkick';
import Highcharts from 'highcharts'
import HC_exporting from 'highcharts/modules/exporting'
import HC_offlineExporting from 'highcharts/modules/offline-exporting'
import Datapoint from './Datapoint';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';

import TagList from './TagList';
import Coverage from './Coverage';
import DataEntry from './DataEntry';

import { customColor, categoriesOptions, socialColor, decode, splitNumbers, getTitle } from '../utils'

HC_exporting(Highcharts);
HC_offlineExporting(Highcharts);
ReactChartkick.addAdapter(Highcharts);
momentDurationFormatSetup(moment);

const displayName = {
    "score_applenews": 'Applenews',
    "score_google_desktop_top": 'A la une',
    "score_google_desktop_cat": 'Sections',
    "score_google_desktop_topic": 'Topic',
    "score_google_desktop_homepage": 'Homepage',
    "score_google_desktop_france": 'France',
    "score_google_desktop_international": 'International',
    "score_google_desktop_économie": 'Economie',
    "score_google_desktop_science_high-tech": 'Science High-Tech',
    "score_google_desktop_culture": 'Culture',
    "score_google_desktop_sports": 'Sports',
    "score_google_desktop_santé": 'Santé',
    "score_google_mobile_webview_top": 'A la une',
    "score_google_mobile_webview_cat": 'Sections',
    "score_google_mobile_webview_topic": 'Topic',
    "score_google_mobile_webview_homepage": 'Homepage',
    "score_google_mobile_webview_france": 'France',
    "score_google_mobile_webview_international": 'International',
    "score_google_mobile_webview_économie": 'Economie',
    "score_google_mobile_webview_santé": 'Santé',
    "score_google_mobile_webview_sports": 'Sports',
    "score_google_mobile_webview_science_high-tech": 'Science High-Tech',
    "score_google_mobile_webview_culture": 'Culture',
    "score_google_mobile_webview_A la une": 'A la une',
    "score_google_mobile_webview_Economie": 'Economie',
    "score_google_desktop_A la une": 'A la une',
    "score_google_desktop_Economie": 'Economie'
}

const sourceScore = {
    score_google_desktop: 'Google Desktop',
    score_google_mobile_webview: 'Google Mobile',
    score_flipboard_mobile: 'Flipboard Mobile',
    score_applenews: 'Applenews'
};

const sourceColor = {
    score_google_desktop: 'desktop-color',
    score_google_mobile_webview: 'mobile-color',
    score_flipboard_mobile: 'flipboard-color',
    score_applenews: 'applenews-color'
};

const DataCategory = (props) => {
    const { scores, category, classes } = props;

    return (
        <Grid item xs={4} className={sourceColor[category]}>
            <div className="data-entries-title">
                <Typography className={classes.categoryTitle} variant="h5" component="h2" gutterBottom>
                    {`${sourceScore[category]} - `}
                    <span className={classes.score} >{splitNumbers(scores[category])}</span>
                </Typography>
            </div>
            <div className="data-entries">
                {
                    Object.keys(scores || {}).map(key => {
                        if (displayName[key] && scores[key] && key.indexOf(category) > -1 && key !== category)
                            return <DataEntry key={scores[key]} title={displayName[key]} info={scores[key]} />
                        return null;
                    })
                }
            </div>
        </Grid>
    );
}

function SelectedClustersResume(props) {
    const { classes, clustersList, query, fullTimeFrame, displayTrend, handleChangeFull, clusters, score, coverage, dataChart, optionsColumn, socialChart, entiteArr, lemmesArr, isScoreQuarts, sourceScore, scores, theme, selectedTopic } = props;

    const ClusterList = (c, index, classes) => {
        return (
            <Card key={index} className={classes.card}>
                <Grid className={classes.grid} container justify="center" alignItems="center" spacing={8}>
                    <Grid item xs={1}>
                        <div className={classes.info}>
                            {splitNumbers(c.score)}
                        </div>
                        <div className={classes.label} >
                            score
                        </div>
                        <div className={classes.info}>
                            {c.urls.length}
                        </div>
                        <div className={classes.label}>
                            articles
                        </div>
                    </Grid>
                    <Grid item xs={1}><Coverage coverage={c.coverage} /></Grid>
                    {displayTrend(c, classes, "liste")}
                    <Grid className={classes.titre} item xs={6}>
                        {getTitle(c)}
                    </Grid>
                    <Grid item xs={2}>
                        <a href={`/dashboard/cluster/${c._id}`} target="_blank">
                            <Info />
                        </a>
                    </Grid>
                </Grid>
            </Card>
        )
    }

    return (
        <div className="article-container">
            <div>
                {!props.sujet && <Paper className={classes.warningTimeframe}>
                    <div> Afficher toute la Durée de Vie </div>
                    <Switch
                        checked={fullTimeFrame}
                        onChange={handleChangeFull}
                        color="primary"
                    />
                </Paper>}
            </div>
            <div id="entiteLemmes">
                <h3>Entités</h3>
                <TagList array={entiteArr.slice(0, 10).map(a => a["Text"])} />
                <h3>Lemmes</h3>
                <TagList array={lemmesArr.slice(0, 10).map(a => a["lemme"])} />
            </div>
            <Grid container justify="space-between" spacing={8} style={{ marginTop: "10px" }}>
                <Grid item xs={4}>
                    <h3>Catégories</h3>
                    <div className="datapoint-container">
                        {clusters.categories.filter(c => typeof categoriesOptions[c] !== 'undefined').map(c =>
                            clusters.cat_principale === c ?
                                <div key={c} className={classes.specialTag} >
                                    {categoriesOptions[c]}
                                </div>
                                : <div key={c} className={classes.tag} >
                                    {categoriesOptions[c]}
                                </div>
                        )
                        }
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <h3>Positions</h3>
                    <div className="datapoint-container">
                        <TagList array={clusters.positions.map(pos => pos.split('_').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' '))} />
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <h3>Crawls</h3>
                    <div className="datapoint-container">
                        <TagList array={clusters.crawls.filter(a => a !== 'sitemap').map(pos => pos.split('_').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' '))} />
                    </div>
                </Grid>
            </Grid>
            <h3>Informations</h3>
            <div className="datapoint-container">
                {!props.sujet && <Datapoint title="Score Complet des Clusters" info={clusters.score} />}
                <Datapoint title="Score selon les filtres" info={score} />
                {query.editeur !== 'all' ?
                    <Card style={{
                        width: 'auto',
                        maxWidth: 120,
                        height: '107px',
                        margin: 5,
                        display: 'inline-block'
                    }}>
                        <CardContent>
                            <Coverage coverage={coverage} />
                        </CardContent>
                    </Card>
                    : null}
                {displayTrend(clusters, classes, "display")}
                <Datapoint title="Première Détection" info={moment(clusters.first_view[0]).format('LLL')} />
                <Datapoint title="Dernière Modification" info={moment(clusters.lastModified[clusters.lastModified.length - 1]).format('LLL')} />
                <Datapoint title="Nombre d'Articles" info={clusters.urls.length} />
            </div>
            <Divider className={classes.divider} variant="middle" />
            <div className="chart-container">
                <h3>Vie des Clusters</h3>
                <AreaChart data={dataChart} colors={Object.values(customColor)} stacked={true} library={optionsColumn} />
                {socialChart ?
                    <Fragment>
                        <h3>Evolution des partages sociaux</h3>
                        <AreaChart data={socialChart} colors={Object.values(socialColor)} stacked={true} library={optionsColumn} />
                    </Fragment>
                    : null
                }
            </div>
            {
                query.editeur && query.editeur !== 'all' && ((query.clusterEntities && query.clusterEntities != "") || (query.clusterLemmes && query.clusterLemmes != "")) || ((selectedTopic && selectedTopic.clusterEntities && selectedTopic.clusterEntities != null) || (selectedTopic && selectedTopic.clusterLemmes && selectedTopic.clusterLemmes != null)) ?
                    <div className="chart-container">
                        <h3>Evolution du score editeur sur le thème</h3>
                        <div id="scoreEditeur">
                            <AreaChart colors={Object.values(customColor)} stacked={true} data={theme.editeurDataChart} library={optionsColumn} />
                        </div>
                    </div>
                    : null
            }
            {
                isScoreQuarts ?
                    <Grid item xs={12}>
                        <Divider className={classes.divider} variant="middle" />
                        <h3>Scores</h3>
                        <Grid container justify="center" spacing={16}>
                            {
                                Object.keys(sourceScore || {}).map(key => {
                                    if (scores[key])
                                        return <DataCategory key={key} classes={classes} category={key} scores={scores} />
                                    return null;
                                })
                            }
                        </Grid>
                    </Grid>
                    : null
            }
            <div className={classes.selectedClusters}>
                <h2>{clustersList.length} Clusters Sélectionnés</h2>
                <div className={classes.listContainer} >
                    {clustersList.sort((a, b) => b.score - a.score).map((c, index) => {
                        return ClusterList(c, index, classes)
                    })}
                </div>
            </div>
            {
                clusters.articlesCluster && clusters.articlesCluster.filter(a => !a.score || a.scores.indexOf(0) > -1).length ?
                    <Fragment>
                        <h3>Articles non exposés sur le même sujet</h3>
                        <div className="datapoint-container">
                            {clusters.articlesCluster.filter(a => a.scores.indexOf(0) > -1).map(editeur => {
                                return editeur.scores.map((score, key) => {
                                    if (score === 0)
                                        return <Datapoint key={editeur._id} link={`/dashboard/article/${editeur.articleId[key]}`}
                                            title={editeur._id} subtitle={decode(editeur.titre[key])} />
                                    return null;
                                })
                            })}
                        </div>
                    </Fragment>
                    : null
            }
        </div>
    );

}

export default (SelectedClustersResume);
