import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import config from '../../config';
import _ from 'lodash';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import SelectedClustersResume from './SelectedClustersResume';
import SelectedClustersClassement from './SelectedClustersClassement';

import { getGraphQuarter, getSocialHour, splitNumbers, decode } from '../utils'

momentDurationFormatSetup(moment);

const styles = {
    chip: {
        margin: 2,

    },
    divider: {
        marginBottom: 15,
        marginTop: 15
    },
    info: {
        marginTop: 5,
        fontSize: 14,
        fontWeight: 700
    },
    label: {
        fontWeight: 700,
        fontSize: 11,
        lineHeight: '11px',
        color: 'rgba(0, 0, 0, 0.34)',
        textAlign: 'center'
    },
    tableLink: {
        cursor: 'pointer',
        paddingTop: 5,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    nowrap: {
        whiteSpace: 'nowrap'
    },
    tag: {
        display: 'inline-block',
        backgroundColor: 'rgb(230, 230, 230)',
        minWidth: 0,
        boxSizing: 'border-box',
        borderRadius: 2,
        margin: 2,
        fontSize: 13,
        paddingLeft: 5,
        paddingRight: 5,
        color: 'rgb(82, 81, 110)',
        cursor: 'pointer'
    },
    specialTag: {
        display: 'inline-block',
        backgroundColor: 'rgb(230, 230, 230)',
        minWidth: 0,
        boxSizing: 'border-box',
        borderRadius: 2,
        margin: 2,
        fontSize: 13,
        paddingLeft: 5,
        paddingRight: 5,
        color: 'rgb(82, 81, 110)',
        border: 'solid 2px #044389',
        cursor: 'pointer',
        boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)'
    },
    expose: {
        backgroundColor: '#4183c4',
    },
    exposeRow: {
        fontWeight: 700,
        color: 'white',
    },
    exposeRowNowrap: {
        whiteSpace: 'nowrap',
        fontWeight: 700,
        color: 'white',
    },
    warningTimeframe: {
        position: 'fixed',
        height: 90,
        width: 100,
        top: 200,
        right: 20,
        padding: 10,
        opacity: 0.8,
        zIndex: 10000,
        fontSize: 11,
        fontWeight: 700,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: 'rgb(244, 155, 66, 0.8)',
        textAlign: 'center'
    },
    button: {
        display: 'flex',
    },
    filler: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 400
    },
    card: {
        width: 'auto',
        maxWidth: 800,
        margin: 5,
        display: 'block',
        color: 'rgba(0, 0, 0, 0.74)',
        fontWeight: 700,
        fontSize: 13,
    },
    listContainer: {
        paddingBottom: 50,
        textAlign: "-webkit-center"
    },
    grid: {
        textAlign: 'center',
        minHeight: 100
    },
    titre: {
        fontSize: 16,
        fontWeight: 700
    },
    selectedClusters: {
        textAlign: "center",
        marginTop: 50
    }
};

const sourceScore = {
    score_google_desktop: 'Google Desktop',
    score_google_mobile_webview: 'Google Mobile',
    score_flipboard_mobile: 'Flipboard Mobile',
    score_applenews: 'Applenews'
};

function SelectedClustersSummary(props) {
    const { classes, clustersList, query, displayTrend, selectedTopic } = props;
    let { fromDate, toDate } = props.query;
    const [clusters, setClusters] = useState({});
    const [fullTimeFrame, setFullTimeFrame] = useState(false);
    const [value, setValue] = useState(0);
    const [theme, setTheme] = useState({});
    let openTab;

    const mergeClusters = (data) => {
        let newData = {};
        function customizer(objValue, srcValue) {
            if (_.isArray(objValue)) {
                return objValue.concat(srcValue);
            }
            if (_.isString(objValue)) {
                let array = [objValue];
                return array.concat(srcValue);
            }
            if (_.isBoolean(objValue)) {
                let array = [objValue];
                return array.concat(srcValue);
            }
            if (_.isNumber(objValue)) {
                let array = "";
                return array = objValue + srcValue;
            }
            if (_.isObject(objValue)) {
                if (Array.isArray(srcValue) && srcValue.filter(el => el !== null)) {
                    return objValue;
                } else {
                    let obj = srcValue;
                    for (let d in objValue) {
                        if (!obj[d]) obj[d] = {};
                        for (let h in objValue[d]) {
                            if (!obj[d][h]) obj[d][h] = {};
                            for (let s in objValue[d][h]) {
                                if (_.isObject(objValue[d][h])) {
                                    if (!obj[d][h][s]) {
                                        obj[d][h][s] = objValue[d][h][s];
                                    } else {
                                        obj[d][h][s] += objValue[d][h][s];
                                    }
                                }
                            }
                        }
                    }
                    return obj;
                }
            }
        }
        data.map(d => {
            delete d.articles;
            delete d.analyzed;
            delete d.generated_text;
            newData = _.mergeWith(newData, d, customizer);
        });
        let obj = {};

        for (const [key, value] of Object.entries(newData)) {
            if (key == "score") {
                obj[key] = newData[key];
            } else if (key == "scores_quarts") {
                obj[key] = {};
                for (let d in newData[key]) {
                    if (!obj[key][d]) {
                        obj[key][d] = {}
                    }
                    for (let h in newData[key][d]) {
                        if (!obj[key][d][h]) {
                            obj[key][d][h] = {}
                        }
                        for (let s in newData[key][d][h]) {
                            if (_.isObject(newData[key][d][h])) {
                                if (!obj[key][d][h][s]) {
                                    obj[key][d][h][s] = newData[key][d][h][s];
                                } else {
                                    obj[key][d][h][s] += newData[key][d][h][s];
                                }
                            }
                        }
                    }
                }
            } else if (key == "first_view" || key == "lastModified") {
                obj[key] = newData[key].sort();
            } else if (key == "articlesCluster") {
                obj[key] = [];
                for (let i = 0; i < newData[key].length; i++) {
                    let id = newData[key][i]._id;
                    if (!obj[key][id]) {
                        obj[key][id] = {};
                    }
                    obj[key][id] = _.mergeWith(obj[key][id], newData[key][i], customizer);
                }
            } else {
                obj[key] = Array.from(new Set(newData[key]));
            }
        }
        setClusters(obj);
    }

    useEffect(() => {
        let searchQuery = {...props.query }
        if (props.query.clusterEntities == "" && props.query.clusterLemmes == "" && 
            ((selectedTopic && selectedTopic.clusterEntities && selectedTopic.clusterEntities != null) || (selectedTopic && selectedTopic.clusterLemmes && selectedTopic.clusterLemmes != null))) {
            if (selectedTopic.clusterEntities != null) searchQuery["clusterEntities"] = selectedTopic.clusterEntities
            if (selectedTopic.clusterLemmes != null) searchQuery["clusterLemmes"] = selectedTopic.clusterLemmes
        }
        let newData = [];
        let promises = [];
        async function fetchData() {
            clustersList.map(c => {
                promises.push(axios.post(`${config.backEndServer}/api/cluster/${c._id}`,
                    { query: props.query },
                    { withCredentials: true }));
            })
            await Promise.all(promises).then((res) => {
                res.forEach(r => {
                    newData.push(r.data);
                });
            });
            if (props.query.editeur != "all" && (searchQuery.clusterEntities != "" || searchQuery.clusterLemmes != "")) {
                await axios.post(`${config.backEndServer}/api/cluster/theme`, searchQuery, {withCredentials: true, timeout: 180000})
                .then((res) => {
                    setTheme(res.data);
                });
            }
            mergeClusters(newData);
        }
        fetchData();
    }, [])

    // const handleDelete = (c) => () => {
    //     let getItems = JSON.parse(localStorage.getItem("selectedClusters"));
    //     const index = getItems.findIndex(i => i._id == c._id);
    //     getItems.splice(index, 1);
    //     localStorage.setItem("selectedClusters", JSON.stringify(getItems));
    //     mergeClusters(getItems);
    // }

    const mergeCols = (arraysToMerge, arraysTitles) => {
        const nbCols = arraysToMerge.length;
        const nbRows = arraysToMerge[0].length;
        let merged = [];
        for (let i = 0; i < nbRows; i++) {
            let item = {};
            for (let k = 0; k < nbCols; k++) {
                item[arraysTitles[k]] = arraysToMerge[k][i];
            }
            merged.push(item);
        }
        return merged;
    }

    const [editorArticles] = clusters.articlesCluster ? clusters.articlesCluster.filter(a => query.editeur === a._id) : [];
    let coverage = 0;
    if (editorArticles) {
        if (!editorArticles.score)
            coverage = 1;
        else {
            for (let i = 0; i < editorArticles.titre.length; i++) {
                if (editorArticles.lastModified[i] >= moment().startOf('day').format('YYYY-MM-DD HH:mm:00'))
                    coverage = 3;
                coverage = Math.max(coverage, 2);
            }
        }
    }
    const optionsColumn = {
        exporting: {
            fallbackToExportServer: false
        },
    };

    if (fullTimeFrame === true) {
        fromDate = clusters.first_view[0].slice(0, -5) + '00:00';
        toDate = clusters.lastModified[clusters.lastModified.length - 1];
    }

    const isScoreQuarts = typeof clusters.scores_quarts !== 'undefined';
    const { score, dataChart, scores } = getGraphQuarter(clusters.scores_quarts, fromDate, toDate);
    const socialChart = getSocialHour(clusters.articlesCluster ? clusters.articlesCluster.map(editeur => editeur.social_shares_jours) : [], fromDate, toDate);

    const classement = (data) => {
        let nb = 0;
        let tableRows = [];
        data = data.sort((a, b) => fullTimeFrame === true ? b.score - a.score: b.scoreFiltre - a.scoreFiltre);
        const sortedData = Object.entries(data).sort((a,b)=> fullTimeFrame === true ? b[1].score - a[1].score : b[1].scoreFiltre - a[1].scoreFiltre);
        for (const [key, value] of sortedData) {
            if (fullTimeFrame === true ? data[key].score > 0 : data[key].scoreFiltre > 0) {
                nb += 1;
                if (Array.isArray(data[key]._id)) data[key]._id = data[key]._id[0];
                tableRows.push(<TableRow key={data[key]._id} className={data[key]._id === query.editeur ? classes.expose : null} >
                      <TableCell className={data[key]._id === query.editeur ? classes.exposeRow : null}>
                          {nb}
                      </TableCell>
                      <TableCell className={data[key]._id === query.editeur ? classes.exposeRow : null}>
                          {data[key]._id}
                      </TableCell>
                      <TableCell className={data[key]._id === query.editeur ? classes.exposeRowNowrap : classes.nowrap}>
                          {splitNumbers(fullTimeFrame === true ? data[key].score : data[key].scoreFiltre)}
                      </TableCell>
                      <TableCell className={data[key]._id === query.editeur ? classes.exposeRow : null}>
                          {Math.round((fullTimeFrame === true ? data[key].score : data[key].scoreFiltre) / score * 100)}%
                      </TableCell>
                      <TableCell className={data[key]._id === query.editeur ? classes.exposeRow : null}>
                          {
                              mergeCols([fullTimeFrame === true ? data[key].scores : data[key].scoresFiltres, data[key].titre, data[key].articleId], ['score', 'titre', 'article'])
                                  .sort((a, b) => b.score - a.score)
                                  .map((item) =>
                                      item.score > 0 ?
                                          <TableRow key={item.score} className={classes.tableLink} onClick={() => window.open(`${window.location.origin}/dashboard/article/${item.article}`)} >
                                              {Math.round(item.score / score * 100)}% - {decode(item.titre)}</TableRow>
                                          : null
                                  )}
                      </TableCell>
                  </TableRow>);
            }
        }
        return tableRows;
    }
    const entiteArr = [], lemmesArr = [];
    const onlyUnique = (item, type) => {
        let i = type == 'entiteArr' 
            ? entiteArr.findIndex(x => (
                x['Text'].normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() == item['Text'].normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()) 
                || x['Text'].includes(item['Text']) 
                || item['Text'].split(" ").some(text => text == x['Text'])) 
            : lemmesArr.findIndex(x => (x['lemme'].normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() == item['lemme'].normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()));
        if (i == -1) {
            type == 'entiteArr' 
            ? entiteArr.push(item) 
            : lemmesArr.push(item);
        } else {
            return type == 'entiteArr' 
            ? entiteArr[i]['count'] > item['count'] ? null : entiteArr[i] = item 
            : lemmesArr[i]['count'] > item['count'] ? null : lemmesArr[i] = item;
        }
        return null;
    }

    if (clusters.entities) 
        clusters.entities.sort((a, b) => b.count - a.count).filter((i) => onlyUnique(i, 'entiteArr'));
    if (clusters.lemmes) 
        clusters.lemmes.sort((a, b) => b.count - a.count).filter((i) => onlyUnique(i, 'lemmesArr'));

    const handleChangeFull = () => {
        setFullTimeFrame(!fullTimeFrame);
    };

    if (value === 0) {
        openTab = <SelectedClustersResume 
                classes={classes} 
                clustersList={clustersList} 
                query={query} 
                fullTimeFrame={fullTimeFrame} 
                handleChangeFull={handleChangeFull} 
                clusters={clusters} 
                score={score} 
                coverage={coverage}
                displayTrend={displayTrend}
                dataChart={dataChart}
                optionsColumn={optionsColumn}
                socialChart={socialChart}
                entiteArr={entiteArr}
                lemmesArr={lemmesArr}
                isScoreQuarts={isScoreQuarts}
                sourceScore={sourceScore}
                scores={scores}
                sujet={props.sujet}
                theme={theme}
                selectedTopic={selectedTopic}
            />
    } else if (value === 1) {
        openTab = <SelectedClustersClassement 
                classement={classement}
                clusters={clusters}
                classes={classes}
                query={query}
                fullTimeFrame={fullTimeFrame}
                handleChangeFull={handleChangeFull}
                score={score}
                sujet={props.sujet}
            />
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    if (clusters && Object.keys(clusters).length > 0) {
        return (
            <div className="article-container">
                <Button variant="contained" color="primary" className={classes.button} onClick={props.mergeClusters}><ArrowBackIcon /> {props.sujet ? "Revenir vers les sujets" : "Changer les clusters"}</Button>
                <Paper style={{width: 320, margin: '0 auto', marginBottom: 25}}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab id="resume" label="résumé" />
                        <Tab id="classement" label="classement" />
                    </Tabs>
                </Paper>
                <div>
                    {openTab}
                </div>
            </div>
        );
    } else {
        return (
            <div className={classes.filler} >
                <CircularProgress className={classes.progress} />
            </div>
        );
    }
}

SelectedClustersSummary.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SelectedClustersSummary);
