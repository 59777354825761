import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';

import ReactChartkick, { LineChart, AreaChart, ColumnChart } from 'react-chartkick';
import Highcharts from 'highcharts'
import HC_exporting from 'highcharts/modules/exporting'
import HC_offlineExporting from 'highcharts/modules/offline-exporting'
import Datapoint from './Datapoint';
import { withStyles } from '@material-ui/core/styles';


import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';

import config from '../../config';
import { getDisplayColors, getQuarterColor, customColor, getTitle, categoriesOptions, calcQuarterScore,
  getGraphQuarter
} from '../utils'


HC_exporting(Highcharts);
HC_offlineExporting(Highcharts);
ReactChartkick.addAdapter(Highcharts);

const decode = (string) => {
  let finalString = '';
  try { finalString = decodeURIComponent(escape(string)) }
  catch(error) { finalString = string }
  return finalString;
}

const styles = {
  sectionTitle: {
    marginTop: '80px !important'
  },
  divider: {
    marginBottom: 15,
    marginTop: 15
  }
};

class ClusterArticle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cluster: null,
    }
  };

  componentDidMount = () => {
    axios.post(`${config.backEndServer}/api/cluster/${this.props.cluster_id}?nolookup=true`,
      { query: this.props.query },
      { withCredentials: true, timeout: 5000 })
      .then((res) => {
        this.setState({ render: true, cluster: res.data });
      }).catch((err) => {
        this.setState({ render: true, cluster: null})
      });
  }

  render = () => {
    if (this.state.render) {
      const { classes } = this.props;
      const { fromDate, toDate } = this.props.query;
      const { score, dataChart } = getGraphQuarter(this.state.cluster.scores_quarts, fromDate, toDate);

      if (!this.state.cluster || !this.state.cluster.expose)
        return (
          <div>
            <h3>Aucun Cluster Détecté</h3>
          </div>
        );

      return (
        <Fragment>
          <Grid item xs={12}>
            <h3>Evolution du Cluster Parent</h3>
            <AreaChart data={dataChart} colors={Object.values(customColor)} stacked={true} />
            <Divider className={classes.divider} variant="middle" />
          </Grid>
          <Grid item xs={6}>
            <Datapoint title={decode(getTitle(this.state.cluster))}
            subtitle={moment(this.state.cluster.lastModified).format('LLL')}
            info={`${this.state.cluster.urls.length} Articles`} link={`/dashboard/cluster/${this.state.cluster._id}`} />
          </Grid>
        </Fragment>
      );
    }
    else {
      return (
        <div >
          <CircularProgress />
        </div>
      )
    }
  }
}

ClusterArticle.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ClusterArticle);
