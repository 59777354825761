import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import moment from 'moment';
import config from '../config';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Info from '@material-ui/icons/Info';
import Up from '@material-ui/icons/TrendingUp';
import Down from '@material-ui/icons/TrendingDown';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Coverage from './sub_components/Coverage';
import SelectedClustersSummary from './sub_components/SelectedClustersSummary';
import CoverageStatus from './CoverageStatus';
import { getTitle, splitNumbers } from './utils';

const styles = theme => ({
  listContainer: {
    paddingBottom: 150
  },
  filler: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 400
  },
  card: {
    width: 'auto',
    maxWidth: 800,
    margin: '0 auto',
    marginTop: 10,
    marginBottom: 10
  },
  grid: {
    textAlign: 'center',
    minHeight: 100
  },
  info: {
    marginTop: 5,
    fontSize: 14,
    fontWeight: 700
  },
  label: {
    fontWeight: 700,
    fontSize: 11,
    lineHeight: '11px',
    color: 'rgba(0, 0, 0, 0.34)',
    textAlign: 'center'
  },
  titre: {
    fontSize: 16,
    fontWeight: 700
  },
  btnsField: {
    display: "flex",
    width: 500,
    margin: "auto",
  },
  button: {
    display: 'block',
    margin: 'auto'
  },
  dialogWindow: {
    width: 600,
    margin: 'auto'
  },
  closeBtn: {
    cursor: "pointer",
    color: "red"
  }
});

const getQuarter = (start) => {
  return moment().subtract((start + 1) * 15 + moment().minute() % 15, 'minutes').format('HH_mm-') + (moment().subtract((start + 1) * 15 + moment().minute() % 15, 'minutes').minute() + 15);
}

const displayTrend = (cluster, classes, page) => {
  let recentTrend = null, olderTrend = null;
  let shortStart = null, shortEnd = null, longStart = null, longEnd = null;

  let today = moment().format('YYYY-MM-DD');

  if (!cluster.scores_quarts[today])
    if (page == "liste")
      return <Grid item xs={1}></Grid>;
    else
      return null;

  if (cluster.scores_quarts[today][getQuarter(0)])
    shortStart = cluster.scores_quarts[today][getQuarter(0)].score;
  if (cluster.scores_quarts[today][getQuarter(3)])
    shortEnd = cluster.scores_quarts[today][getQuarter(3)].score;
  if (cluster.scores_quarts[today][getQuarter(0)] && cluster.scores_quarts[today][getQuarter(1)])
    longStart = cluster.scores_quarts[today][getQuarter(0)].score + cluster.scores_quarts[today][getQuarter(1)].score;
  if (cluster.scores_quarts[today][getQuarter(14)] && cluster.scores_quarts[today][getQuarter(15)])
    longEnd = cluster.scores_quarts[today][getQuarter(14)].score + cluster.scores_quarts[today][getQuarter(15)].score;

  if (!!(shortStart) && !!(shortEnd)) {
    if (shortStart > shortEnd)
      recentTrend = (<Up />);
    else
      recentTrend = (<Down />);
  }
  if (!!(longStart) && !!(longEnd)) {
    if (longStart > longEnd)
      olderTrend = (<Up />);
    else
      olderTrend = (<Down />);
  }

  if (recentTrend === null && olderTrend === null)
    if (page == "liste")
      return <Grid item xs={1}></Grid>;
    else
      return null;

  if (page == "liste") {
    return (
      <Grid item xs={1}>
        {recentTrend ?
          <div>
            <div className={classes.info}>
              {recentTrend}
            </div>
            <div className={classes.label}>
              sur 1h
          </div>
          </div>
          : null
        }
        {olderTrend ?
          <div>
            <div className={classes.info}>
              {olderTrend}
            </div>
            <div className={classes.label}>
              sur 4h
            </div>
          </div>
          : null
        }
      </Grid>
    );
  } else {
    return (
      <Card style={{
        width: 'auto',
        maxWidth: 120,
        height: '107px',
        margin: 5,
        display: 'inline-block',
        padding: 10
      }}>
        {recentTrend ?
          <div>
            <div className={classes.info}>
              {recentTrend}
            </div>
            <div className={classes.label}>
              sur 1h
          </div>
          </div>
          : null
        }
        {olderTrend ?
          <div>
            <div className={classes.info}>
              {olderTrend}
            </div>
            <div className={classes.label}>
              sur 4h
            </div>
          </div>
          : null
        }
      </Card>
    );
  }
}

class SelectClusterList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      render: false,
      clusters: [],
      isLoading: false,
      isLoadingDetailed: false,
      pagination: 0,
      total: null,
      apiLemmes: null,
      merge: false,
      sujetPopUp: false,
      topicName: "",
      attributeTopic: "",
      sujetsListe: []
    }
  };

  getData = () => {
    if (!this.state.isLoading && this.state.total !== this.state.clusters.length) {
      this.setState({ isLoading: true });
      axios.post(`${config.backEndServer}/api/cluster`, {
        ...this.props.query,
        pagination: this.state.pagination,

      }, { withCredentials: true })
        .then((res) => {
          if (res.data.pageInfo)
            this.setState({
              render: true,
              clusters: [...res.data.clusters],
              isLoading: false,
              pagination: this.state.pagination + res.data.clusters.length,
              total: res.data.pageInfo[0] ? res.data.pageInfo[0].count : 0,
              apiLemmes: res.data.pageInfo[0] && res.data.pageInfo[0].lemmes ? res.data.pageInfo[0].lemmes : null,
            });
          else {
            const toAppend = res.data.clusters.filter(x => !this.state.clusters.some(n => n._id === x._id))
            if (!toAppend.length)
              this.setState({
                isLoading: false,
                total: this.state.clusters.length
              })
            else
              this.setState({
                clusters: [...this.state.clusters, ...toAppend],
                isLoading: false,
                pagination: this.state.pagination + res.data.clusters.length,
                category: this.state.category,
                position: this.state.position,
                entities: this.state.entities,
                lemmes: this.state.lemmes,
                orphan: this.state.orphan
              });
          }
        });
    }
  };

  getTopic = () => {
    axios.post(`${config.backEndServer}/api/cluster/gettopic`, {...this.props.query }, {withCredentials: true})
      .then((res) => {
        this.setState({ sujetsListe: res.data[0].clusters });
      });
  }

  getDetails = () => {
    const clustersToUpdate = this.state.clusters.filter(c => typeof c.coverage === 'undefined').slice(0, 10);
    if (!this.state.isLoadingDetailed && clustersToUpdate.length) {
      this.setState({ isLoadingDetailed: true });
      axios.post(`${config.backEndServer}/api/cluster/details`, { ids: clustersToUpdate.map(c => c._id), editeur: this.props.query.editeur, groupe: this.props.query.groupe }, { withCredentials: true })
        .then((res) => {
          const details = res.data;
          const newClusters = this.state.clusters.map(c => {
            const index = details.findIndex(t => c._id === t._id)
            if (index > -1) {
              if (!!(this.props.coverage)) {
                c.coverage = details[index].coverage;
                c.coverageList = details[index].coverageList;
                c.urlList = details[index].urls;
                c.lastDateArticleList = details[index].lastDateArticleList;
                c.lastExposedList = details[index].lastExposedList;
              }
            }
            return c;
          });
          this.setState({ clusters: newClusters, isLoadingDetailed: false });
        });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, false);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!!(this.props.coverage) && !this.state.isLoading && (prevState.isLoading || prevState.isLoadingDetailed)) {
      this.getDetails();
    }
  }

  onScroll = () => {
    if (
      (
        this.state.clusters.length && !this.state.isLoading  && !this.state.merge &&
        window.innerHeight + window.scrollY) >= (ReactDOM.findDOMNode(this).offsetHeight)
    ) {
      this.getData();
    }
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.onScroll, false);
    this.getData();
  }

  selectedClusters = (event, cluster) => {
    let { name, checked } = event.target;
    let getItems = JSON.parse(localStorage.getItem("selectedClusters"));
    let selectedClusters = [];
    if (getItems != null && getItems.length > 0) {
      selectedClusters = getItems;
    }
    if (checked) {
      selectedClusters.push(cluster);
      let newClusters = this.state.clusters;
      const index = newClusters.indexOf(cluster);
      newClusters.splice(index, 1);
    } else {
      const index = selectedClusters.findIndex(c => c._id == cluster._id);
      selectedClusters.splice(index, 1);
      this.state.clusters.unshift(cluster);

      if (this.state.foundInTopic && this.state.foundInTopic.length > 0) {
        const indexTopic = this.state.foundInTopic.findIndex(c => c._id == cluster._id);
        indexTopic != -1 && this.state.foundInTopic.splice(indexTopic, 1);
      }
    }
    this.setState({
      [name]: checked,
    });
    if (selectedClusters.length > 0) {
      localStorage.setItem("selectedClusters", JSON.stringify(selectedClusters));
    } else {
      localStorage.removeItem("selectedClusters");
      this.setState({ sujetPopUp: false });
    }
  }

  mergeClusters = () => {
    this.setState({ merge: !this.state.merge });
  }

  openNewPopUp = () => {
    this.getTopic();
    this.setState({ sujetPopUp: !this.state.sujetPopUp });
  }

  handleChange = event => {
    if (event.target.name == "attributeTopic") this.checkIfClusterAlreadyInList(event.target.value)
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  checkIfClusterAlreadyInList = (clusterToCheck) => {
    let found = this.state.sujetsListe.find(i => i._id == clusterToCheck);
    const clusters = JSON.parse(localStorage.getItem("selectedClusters"));
    let ids = [];

    found && found.clustersLists.map(i => clusters.map(c => {
      if (c._id == i._id) ids.push(c)
    }))
    this.setState({ foundInTopic: ids })
  }

  saveTopic = () => {
    const clusters = JSON.parse(localStorage.getItem("selectedClusters"));
    let ids = [];
    clusters.map(i => ids.push(i._id));

    axios.post(`${config.backEndServer}/api/cluster/topic`, { 
      topicName : this.state.topicName, 
      clusters: ids, 
      attributeTopic: this.state.attributeTopic,
      fromDate: this.props.query.fromDate,
      toDate: this.props.query.toDate,
      query: this.props.query
    }, {withCredentials: true})
      .then((res) => {
        if (res.data.msg == "ok") {
          toast.success('Les modifications sont faites', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          localStorage.removeItem("selectedClusters");
          this.setState({ sujetPopUp: !this.state.sujetPopUp });
        }
      });
  }

  ClusterList = (c, index, classes, checked) => {
    return (
      <Card key={index} className={classes.card}>
        <Grid className={classes.grid} container justify="center" alignItems="center" spacing={8}>
          <Grid item className="color-fill" xs={1}>
            <Checkbox
              checked={checked}
              onChange={(event) => { this.selectedClusters(event, c) }}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </Grid>
          <Grid item xs={1}>
            <div className={classes.info}>
              {splitNumbers(c.score)}
            </div>
            <div className={classes.label} >
              score
        </div>
            <div className={classes.info}>
              {c.urls.length}
            </div>
            <div className={classes.label}>
              articles
        </div>
          </Grid>
          <Grid item xs={1}><Coverage coverage={c.coverage} /></Grid>
          {displayTrend(c, classes, "liste")}
          <Grid className={classes.titre} item xs={6}>
            {getTitle(c)}
          </Grid>
          <Grid item xs={2}>
            <a href={`/dashboard/cluster/${c._id}`} target="_blank">
              <Info />
            </a>
          </Grid>
        </Grid>
      </Card>
    )
  }

  render = () => {
    const { classes, query } = this.props;
    let { clusters, render, merge, sujetPopUp, sujetsListe, foundInTopic } = this.state;
    const getClusters = JSON.parse(localStorage.getItem("selectedClusters"));
    if (render) {
      if (!merge) {
        return (
          <div className="article-container">
            {this.props.coverage && !this.props.noSTatus ? <CoverageStatus query={query} /> : null}
            <h1>Clusters choisis</h1>
            {getClusters && getClusters.map((c, index) => {
              const checked = true;
              return this.ClusterList(c, index, classes, checked)
            })}
            {getClusters && 
              <div className={classes.btnsField}>
                <Button variant="contained" color="primary" className={classes.button} onClick={this.mergeClusters}>Générer la liste</Button>
                <Button variant="contained" color="secondary" className={classes.button} onClick={this.openNewPopUp}>Gérer le sujet</Button>
              </div>
            }
            <h1>Tous les clusters ({this.state.total} résultats)</h1>
            <div className={classes.listContainer} >
              {clusters.map((c, index) => {
                const checked = false;
                return this.ClusterList(c, index, classes, checked)
              })}
              {this.state.isLoading ?
                <div className={classes.filler} >
                  <CircularProgress className={classes.progress} />
                </div>
                : null}
            </div>

            <Dialog open={sujetPopUp} onClose={this.openNewPopUp}>
              <DialogTitle className={classes.dialogWindow}>Management de sujet</DialogTitle>
              <DialogContent>
                <form>
                  <div style={{marginTop:"20px"}}>
                    <div>Liste des clusters choisis : </div>
                    {getClusters && getClusters.map((i, index) => <div style={{display: "flex", justifyContent: "space-between", marginLeft: "10px"}} key={index}>{index + 1}. {i.titre_selectionne != null && i.titre_selectionne != "Sans titre" && i.titre_selectionne != "" ? i.titre_selectionne : i.titre} <span className={classes.closeBtn}><ClearIcon onClick={(event) => { this.selectedClusters(event, i) }}/></span></div>)}
                  </div>
                  <TextField
                      margin="dense"
                      id="topicName"
                      label="Créer un nouveau sujet"
                      type="text"
                      name="topicName"
                      fullWidth
                      onChange={this.handleChange}
                  />
                  <div style={{marginTop:"20px"}}>OU</div>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel id="demo-simple-select-label">Attribuer {getClusters && getClusters.length > 1 ? "ces clusters" : "ce cluster"} au sujet existant</InputLabel>
                    <Select
                      id="demo-simple-select"
                      name="attributeTopic"
                      value={this.state.attributeTopic ? this.state.attributeTopic : ""}
                      onChange={this.handleChange}
                    >
                      {
                        sujetsListe && sujetsListe.length > 0 &&
                        sujetsListe.map(i => {
                          return (<MenuItem key={i.name} value={i._id}>{i.name}</MenuItem>)
                        })
                      }
                    </Select>
                  </FormControl>
                      {
                        foundInTopic && foundInTopic.length > 0 &&
                        <div style={{marginTop:"20px"}}>
                          <div>Un des clusters choisis est déjà présent dans le sujet : </div>
                          {foundInTopic.map((i, index) => <div style={{display: "flex", justifyContent: "space-between", marginLeft: "10px"}}>{index + 1}. {i.titre_selectionne != null && i.titre_selectionne != "Sans titre" && i.titre_selectionne != "" ? i.titre_selectionne : i.titre}</div>)}
                        </div>
                      }
                </form>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.openNewPopUp} color="primary">
                  Annuler
                </Button>
                <Button onClick={this.saveTopic} color="primary">
                  Sauvegarder les changements
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        );
      } else {
        return (
          <SelectedClustersSummary
            clustersList={getClusters}
            query={this.props.query}
            mergeClusters={this.mergeClusters}
            displayTrend={displayTrend}
            getQuarter={getQuarter}
          />)
      }

    } else {
      return (
        <div className={classes.filler} >
          <CircularProgress className={classes.progress} />
        </div>
      );
    }
  };
}

SelectClusterList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SelectClusterList);
