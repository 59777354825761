import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';

import { splitNumbers } from '../utils';

const styles = {
  card: {
    width: 'auto',
    maxWidth: 800,
    margin: 5,
    display: 'block',
    color: 'rgba(0, 0, 0, 0.74)',
    fontWeight: 700,
    fontSize: 13,
  },
  expsummary: {
    fontSize: 14,
    flexBasis: '33.33%',
    flexShrink: 0,
    whiteSpace: 'nowrap'
  }
};

function SitemapCard(props) {
  const { classes, user } = props;
  var liste = [];
  for (var i = 0; i < props.sitemap.sitemaps.length; i++) {
    let error = props.sitemap.sitemaps[i].error == true ? "error" : "primary";
    if (i > 0) {
      liste.push(<Divider variant="inset" component="li" key={"divider" + props.sitemap.sitemaps[i].nom} />);
    }
    liste.push(
      <ListItem key={props.sitemap.sitemaps[i].nom} alignItems="flex-start">
        <ListItemText
          secondary={
            <React.Fragment>
              <Typography component="span" className={classes.inline} color={error}>
                { props.sitemap.sitemaps[i].code + " " + props.sitemap.sitemaps[i].sitemap }
              </Typography>
              <Typography className={classes.expsummary} color="textSecondary" component="p">
                {"dernier article crawlé : " + moment(props.sitemap.sitemaps[i].dernier_article_crawle).fromNow() }
              </Typography>
              <Typography className={classes.expsummary} color="textSecondary" component="p">
                {"dernier crawl sitemap : " + moment(props.sitemap.sitemaps[i].dernier_crawl).fromNow() }
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>
    );
  }
  return (
    <Card className={classes.card} key={props.sitemap._id}>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.expsummary} gutterBottom component="p">
            {props.sitemap._id}
          </Typography>
          <Typography className={classes.expsummary} color="textSecondary" component="p">
            { "Ce jour : " + (props.sitemap.articles_jour != undefined ? splitNumbers(props.sitemap.articles_jour) : 0) }
          </Typography>
          <Typography className={classes.expsummary} color="textSecondary" component="p">
            { "En direct : " + (props.sitemap.articles_direct != undefined ? splitNumbers(props.sitemap.articles_direct) : 0) }
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <List className={classes.root}>
            {liste}
          </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Card>
  );
}

SitemapCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SitemapCard);
