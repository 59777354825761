import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import config from '../config';

import MUIDataTable from 'mui-datatables';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactChartkick, { AreaChart, ColumnChart, LineChart } from 'react-chartkick';
import Highcharts from 'highcharts'
import Datapoint from './sub_components/Datapoint';
import HC_exporting from 'highcharts/modules/exporting'
import HC_offlineExporting from 'highcharts/modules/offline-exporting'
import ArticlesGraph from './ArticlesGraph';

import { customColor, saveColumnSetup, displayScore } from './utils';

HC_exporting(Highcharts);
HC_offlineExporting(Highcharts);
ReactChartkick.addAdapter(Highcharts)

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  dense: {
    marginTop: 16,
  },
  fab: {
    margin: theme.spacing.unit,
  },
  filler: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 400
  },
  dataContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'nowrap'
  }
});

const formatDate = (a, lenToCut) => {
  if (lenToCut === 9)
    return moment(a, 'YYYY-MM-DD HH').format('D/M')
  return moment(a, 'YYYY-MM-DD HH').format('H[h]')
}

const calcScore = (articles, source) => {
  // let series = [{
  //   name: 'Desktop',
  //   data: {}
  // }, {
  //   name: 'Mobile',
  //   data: {}
  // }, {
  //   name: 'Flipboard',
  //   data: {}
  // }];

  const totalEntries = {
    desktop: 0,
    mobile: 0,
    // flipboard: 0,
    applenews: 0
  }

  if (articles.length === 0)
    return {totalEntries };

  articles.forEach(el => {
    // if (el[3]) {
      // totalEntries.flipboard += 1
    // }
    if (el[3]) {
      totalEntries.applenews += 1
    }
    if (el[4]) {
      totalEntries.desktop += 1
    }
    if (el[5]) {
      totalEntries.mobile += 1
    }
  });

  return { totalEntries };
}

const decode = (string) => {
  let finalString = '';
  try { finalString = decodeURIComponent(escape(string)) }
  catch(error) { finalString = string }
  return finalString;
}

class ArticlesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      render: 'false',
      articles: [],
    }
  };

  getData = () => {
    const query = this.props.changeEditeur ? this.props.query : {...this.props.query, ...{editeur: 'all'} };
    axios.post(`${config.backEndServer}/api/article`, this.props.query
    , {withCredentials: true, timeout: 180000 })
      .then((res) => {
        this.setState({ render: 'true', articles: res.data });
      })
      .catch(err => {
        this.setState({ render: 'timeout'});
      });
  };

  componentDidUpdate = (prevProps) => {
    if ((prevProps.query.fromDate !== this.props.query.fromDate) || (prevProps.query.toDate !== this.props.query.toDate))
      this.getData();
  }

  // getTableData = (action, tableState) => {
  //   console.log(action, tableState);
  // };

  componentDidMount = () => {
    this.getData();
  }

  componentDidUpdate = (prevProps) => {
    if ((prevProps.query.fromDate !== this.props.query.fromDate) || (prevProps.query.toDate !== this.props.query.toDate))
      this.getData();
  }

  render = () => {
    const { classes, isAdmin, displaySelect } = this.props;

    const articleSettings = JSON.parse(localStorage.getItem('articleSettings'));
    const exposedArticles = this.state.articles.filter(a => a[2]);

    const columns = [
      { name: '#', options: {display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (tableMeta.rowIndex + 1);
        },
        sort: false
      } },
      { name: 'Editeur', options: { display: this.props.query.editeur != "all" ? false : articleSettings['Editeur'] } },
      { name: 'Score Total', options: { display: this.props.query.limit ? true : articleSettings['Score'],  sortDirection: 'desc' } },
      // { name: 'Score Flipboard',
      //   options: { display: this.props.query.source === 'flipboard' ? true : articleSettings['Score Flipboard'] }
      // },
      { name: 'Score AppleNews',
        options: { display: this.props.query.source === 'applenews' || this.props.query.limit ? true : articleSettings['Score AppleNews'] }
      },
      { name: "Score du Cluster Parent", options: { display: this.props.query.limit ? true : 'excluded' } },
      { name: 'G.News Desktop', options: { display: this.props.query.limit ? false : articleSettings['Desktop'] } },
      { name: 'G.News Mobile', options: { display: this.props.query.limit ? false : articleSettings['Mobile'] } },
      { name: 'Titre', options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return decode(value)
          },
          display: articleSettings['Titre']
        }
      },
      { name: 'G.News A la une Desktop', options: { display: articleSettings['Top Desktop'] } },
      { name: 'G.News A la une Mobile', options: { display: articleSettings['Top Mobile'] } },
      { name: 'G.News Sections Desktop', options: { display: articleSettings['Cat Desktop'] } },
      { name: 'G.News Sections Mobile', options: { display: articleSettings['Cat Mobile'] } },
      { name: 'G.News Topic Desktop', options: { display: articleSettings['Topic Desktop'] } },
      { name: 'G.News Topic Mobile', options: { display: articleSettings['Topic Mobile'] } },
      { name: 'Dernière Modification',  options: { display: articleSettings['Dernière Modification'] } },
      { name: 'id', options: { display: articleSettings['id'] } },
      { name: 'Première apparition', options: { display: this.props.query.limit ? true : articleSettings['Première apparition'] } },
      { name: "Heure d'apparition Sitemap", options: { display: articleSettings["Heure d'apparition Sitemap"] } },
      { name: 'Première exposition', options: { display: articleSettings['Première exposition'] } },
      { name: 'Derniere Modification Sitemap', options: { display: 'false' } },
      { name: 'Partages Sociaux', options: { display: articleSettings['Partages Sociaux'] } },
      { name: 'Premiere exposition  Google Desktop', options: { display: 'false' } },
      { name: 'Premiere exposition Google Mobile', options: { display: 'false' } },
      // { name: 'Premiere exposition Flipboard', options: { display: 'false' } },
      { name: 'Premiere exposition Applenews', options: { display: 'false' } },
      { name: 'Derniere Modification Google Desktop', options: { display: 'false' } },
      { name: 'Derniere Modification Google Mobile', options: { display: 'false' } },
      // { name: 'Derniere Modification Flipboard', options: { display: 'false' } },
      { name: 'Derniere Modification Applenews', options: { display: 'false' } },
      { name: 'URL', options: { display: 'false' } },
    ];

    const options = {
      filter: false,
      selectableRows: false,
      responsive: "stacked",
      rowsPerPage: 50,
      rowsPerPageOptions: [10,50, 100],
      // onCellClick: (colData, cellMeta) => {
      //   if (cellMeta.colIndex === 12) {
      //     window.open(colData);
      //   }
      // },
      downloadOptions: { filename: 'articles.csv', separator: ';' },
      onRowClick: (rowData) => window.open(`${window.location.origin}/dashboard/article/${rowData[15]}`),
      onColumnViewChange: (changedColumn, action) => saveColumnSetup('articleSettings', changedColumn, action),
      // serverSide: true,
      // onTableChange: (action, tableState) => {
      //   this.getTableData(action, tableState);
      // }
    };

    if (this.state.render === 'true') {
      const { editeur } = this.state;
      const { totalEntries } = calcScore(this.state.articles);
      const optionsArea =  {
        chart: {
          zoomType: 'x',
        },
        plotOptions: {
            area: {
                stacking: 'normal',
                lineColor: '#666666',
                lineWidth: 1,
                marker: {
                    lineWidth: 1,
                    lineColor: '#666666'
                }
            }
        },
        exporting: {
             fallbackToExportServer: false
         }
      };
      const optionsColumn = {
        plotOptions: {
          series: {
           cursor: 'pointer',
           events: {
               click: function (event) {
                   console.log(
                       event
                   );
               }
           }
         }
       },
        exporting: {
             fallbackToExportServer: false
         }
      };

      return (
        <div>
          {
            this.props.changeEditeur && this.props.query.editeur !== 'all' ?
              <div>
                <h1>Articles correspondants à la recherche</h1>
                <div className={classes.dataContainer}>
                  <Datapoint title="Total" info={exposedArticles.length} />
                  <Datapoint title="Google Desktop" info={totalEntries.desktop} color="desktop-color" />
                  <Datapoint title="Google Mobile" info={totalEntries.mobile} color="mobile-color" />
                  { /*}<Datapoint title="Flipboard" info={totalEntries.flipboard} color="flipboard-color" /> */}
                  <Datapoint title="Apple News" info={totalEntries.applenews} color="applenews-color" />
                </div>
                <div className="article-container">
                  <div className="chart-container">
                    { /*
                    { expositionSeries && Object.keys(expositionSeries).length > 0 ?
                      <Fragment>
                        <h3>Nouveaux articles exposés</h3>
                        <LineChart colors={Object.values(customColor)} data={expositionSeries} library={optionsColumn} />
                      </Fragment>
                      : null }
                    { creationSeries && Object.keys(creationSeries).length > 0 ?
                      <Fragment>
                        <h3>Nouveaux articles apparus en Sitemap</h3>
                        <LineChart colors={Object.values(customColor)} data={creationSeries} library={optionsColumn} />
                      </Fragment>
                      : null }
                    { /*
                      <h3>Evolution de la visibilité totale</h3>
                      <ColumnChart stacked={true} colors={Object.values(customColor)} data={groupedSeries} library={optionsColumn} />
                      <h3>Evolution du score (date de référence: première apparition)</h3>
                      <AreaChart data={series} colors={Object.values(customColor)} library={optionsArea}/>
                    */}
                  </div>
                </div>
              </div>
            : null
          }
          <MUIDataTable className="datatable"
            title={"Articles"}
            data={exposedArticles}
            columns={columns}
            options={options}
          />
        </div>
        );
    } else if (this.state.render === 'timeout') {
      return (
        <div className={classes.filler} >
          <h3>Trop de résultats, délais de chargement expiré. Veuillez sélectionner des paramètres de filtre plus spécifiques.</h3>
        </div>
      )
    }
      else {
      return (
        <div className={classes.filler} >
          <CircularProgress className={classes.progress} />
        </div>
      )
    }
  };
}


ArticlesList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ArticlesList);
