import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import moment from 'moment';
import config from '../config';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Up from '@material-ui/icons/TrendingUp';
import Down from '@material-ui/icons/TrendingDown';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ClearIcon from '@material-ui/icons/Clear';

import SelectedClustersSummary from './sub_components/SelectedClustersSummary';
import { splitNumbers, decode } from './utils';

const styles = theme => ({
    listContainer: {
        paddingBottom: 150
    },
    filler: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 400
    },
    card: {
        width: '-webkit-fill-available',
        maxWidth: 950,
        margin: '0 auto',
        marginTop: 10,
        marginBottom: 10
    },
    grid: {
        textAlign: 'center',
        minHeight: 100
    },
    info: {
        marginTop: 5,
        fontSize: 14,
        fontWeight: 700
    },
    label: {
        fontWeight: 700,
        fontSize: 11,
        lineHeight: '11px',
        color: 'rgba(0, 0, 0, 0.34)',
        textAlign: 'center'
    },
    titre: {
        fontSize: 16,
        fontWeight: 700
    },
    btnsField: {
        display: "flex",
        width: 500,
        margin: "auto",
    },
    button: {
        // display: 'block',
        margin: 10,
        width: 30
    },
    dialogWindow: {
        width: 600,
        margin: 'auto'
    },
    closeBtn: {
        cursor: "pointer",
        color: "red"
    },
    cardContent: {
        display: "flex",
        justifyContent: "space-between",
        padding: 10
    },
    cardContentInfo: {
        display: 'flex',
        alignItems: 'center',
        width: "90%"
    },
    cardContentInfoData: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: "20%"
    },
    cardContentInfoDataClassement: {
        textAlign: "center",
        width: "30%"
    },
    cardContentBtn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    tableLink: {
        cursor: 'pointer',
        paddingTop: 5,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    time: {
        width: 40,
        margin: 'auto',
        fontSize: 14,
        fontWeight: 700
    },
    content: {
        display: 'flex',
        alignItems: 'center'
    }
});

const getQuarter = (start) => {
    return moment().subtract((start + 1) * 15 + moment().minute() % 15, 'minutes').format('HH_mm-') + (moment().subtract((start + 1) * 15 + moment().minute() % 15, 'minutes').minute() + 15);
}

const displayTrend = (cluster, classes, page) => {
    let recentTrend = null, olderTrend = null;
    let shortStart = null, shortEnd = null, longStart = null, longEnd = null;

    let today = moment().format('YYYY-MM-DD');

    if (!cluster.scores_quarts[today])
        if (page == "liste")
            return <Grid item xs={1}></Grid>;
        else
            return null;

    if (cluster.scores_quarts[today][getQuarter(0)])
        shortStart = cluster.scores_quarts[today][getQuarter(0)].score;
    if (cluster.scores_quarts[today][getQuarter(3)])
        shortEnd = cluster.scores_quarts[today][getQuarter(3)].score;
    if (cluster.scores_quarts[today][getQuarter(0)] && cluster.scores_quarts[today][getQuarter(1)])
        longStart = cluster.scores_quarts[today][getQuarter(0)].score + cluster.scores_quarts[today][getQuarter(1)].score;
    if (cluster.scores_quarts[today][getQuarter(14)] && cluster.scores_quarts[today][getQuarter(15)])
        longEnd = cluster.scores_quarts[today][getQuarter(14)].score + cluster.scores_quarts[today][getQuarter(15)].score;

    if (!!(shortStart) && !!(shortEnd)) {
        if (shortStart > shortEnd)
            recentTrend = (<Up />);
        else
            recentTrend = (<Down />);
    }
    if (!!(longStart) && !!(longEnd)) {
        if (longStart > longEnd)
            olderTrend = (<Up />);
        else
            olderTrend = (<Down />);
    }

    if (recentTrend === null && olderTrend === null)
        if (page == "liste")
            return <Grid item xs={1}></Grid>;
        else
            return null;

    if (page == "liste") {
        return (
            <Grid item xs={1}>
                {recentTrend ?
                    <div>
                        <div className={classes.info}>
                            {recentTrend}
                        </div>
                        <div className={classes.label}>
                            sur 1h
                        </div>
                    </div>
                    : null
                }
                {olderTrend ?
                    <div>
                        <div className={classes.info}>
                            {olderTrend}
                        </div>
                        <div className={classes.label}>
                            sur 4h
                        </div>
                    </div>
                    : null
                }
            </Grid>
        );
    } else {
        return (
            <Card style={{
                width: 'auto',
                maxWidth: 120,
                height: '107px',
                margin: 5,
                display: 'inline-block',
                padding: 10
            }}>
                {recentTrend ?
                    <div>
                        <div className={classes.info}>
                            {recentTrend}
                        </div>
                        <div className={classes.label}>
                            sur 1h
                        </div>
                    </div>
                    : null
                }
                {olderTrend ?
                    <div>
                        <div className={classes.info}>
                            {olderTrend}
                        </div>
                        <div className={classes.label}>
                            sur 4h
                        </div>
                    </div>
                    : null
                }
            </Card>
        );
    }
}

class SelectClusterList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            render: false,
            clusters: [],
            isLoading: false,
            isLoadingDetailed: false,
            isLoadingDetailedClassement: false,
            pagination: 0,
            total: null,
            apiLemmes: null,
            merge: false,
            sujetPopUp: false,
            topicName: "",
            attributeTopic: "",
            getClusters: [],
            selectedTopic: {}
        }
    };

    getData = () => {
        if (!this.state.isLoading && this.state.total !== this.state.clusters.length) {
            this.setState({ isLoading: true });
            axios.post(`${config.backEndServer}/api/cluster/gettopic`, {
                ...this.props.query,
                pagination: this.state.pagination,
            }, { withCredentials: true })
                .then((res) => {
                    if (res.data[0].pageInfo)
                        this.setState({
                            render: true,
                            clusters: [...res.data[0].clusters],
                            isLoading: false,
                            pagination: this.state.pagination + res.data[0].clusters.length,
                            total: res.data[0].pageInfo[0] ? res.data[0].pageInfo[0].count : 0,
                            apiLemmes: res.data[0].pageInfo[0] && res.data[0].pageInfo[0].lemmes ? res.data[0].pageInfo[0].lemmes : null,
                        });
                    else {
                        const toAppend = res.data[0].clusters.filter(x => !this.state.clusters.some(n => n._id === x._id))
                        if (!toAppend.length)
                            this.setState({
                                isLoading: false,
                                total: this.state.clusters.length
                            })
                        else
                            this.setState({
                                clusters: [...this.state.clusters, ...toAppend],
                                isLoading: false,
                                pagination: this.state.pagination + res.data[0].clusters.length,
                                category: this.state.category,
                                position: this.state.position,
                                entities: this.state.entities,
                                lemmes: this.state.lemmes,
                                orphan: this.state.orphan
                            });
                    }
                });
        }
    };

    getDetails = () => {
        const clustersToUpdate = this.state.clusters.filter(c => c.done == undefined).slice(0, 10);
        if (!this.state.isLoadingDetailed && clustersToUpdate.length) {
            this.setState({ isLoadingDetailed: true });
            axios.post(`${config.backEndServer}/api/cluster/gettopic/details`, { ids: clustersToUpdate.map(c => c._id), fromDate: this.props.query.fromDate, toDate: this.props.query.toDate }, { withCredentials: true })
                .then((res) => {
                    const details = res.data;
                    const newClusters = this.state.clusters.map(c => {
                        const index = details.findIndex(t => c._id === t._id)
                        if (index > -1) {
                            if (!!(this.props.coverage)) {
                                c.score = details[index].score;
                                c.clustersLists = details[index].clustersLists;
                                c.done = true;
                            }
                        }
                        return c;
                    });
                    newClusters.map(i => {
                        if (!i.done) return i.done = true
                    })
                    this.setState({ clusters: newClusters, isLoadingDetailed: false });
                })
        }
    }

    getDetailsClassement = () => {
        const clustersToUpdate = this.state.clusters.filter(c => c.articlesCluster == undefined).slice(0, 5);
        if (!this.state.isLoadingDetailedClassement && clustersToUpdate.length) {
            this.setState({ isLoadingDetailedClassement: true });
            axios.post(`${config.backEndServer}/api/cluster/gettopic/details/classement`, { ids: clustersToUpdate.map(c => c._id), fromDate: this.props.query.fromDate, toDate: this.props.query.toDate }, { withCredentials: true })
                .then((res) => {
                    const details = res.data;
                    const newClusters = this.state.clusters.map(c => {
                        const index = details.findIndex(t => c._id === t._id)
                        if (index > -1) {
                            if (!!(this.props.coverage)) {
                                c.articlesCluster = details[index].articlesCluster;
                            }
                        }
                        return c;
                    });
                    newClusters.map(i => {
                        clustersToUpdate.map(c => {
                            if (c._id == i._id && !i.articlesCluster) return i.articlesCluster = []
                        })
                    })
                    this.setState({ clusters: newClusters, isLoadingDetailedClassement: false });
                });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll, false);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!!(this.props.coverage) && !this.state.isLoading && (prevState.isLoading || prevState.isLoadingDetailed)) {
            this.getDetails();
        }
        if (this.props.query.editeur != "all" && !!(this.props.coverage) && !this.state.isLoading && (prevState.isLoading || prevState.isLoadingDetailedClassement)) {
            this.getDetailsClassement();
        }
    }

    onScroll = () => {
        if (
            (
                this.state.clusters.length && !this.state.isLoading && !this.state.merge &&
                window.innerHeight + window.scrollY) >= (ReactDOM.findDOMNode(this).offsetHeight)
        ) {
            this.getData();
        }
    }

    componentDidMount = () => {
        window.addEventListener('scroll', this.onScroll, false);
        this.getData();
    }

    mergeClusters = () => {
        this.setState({ merge: !this.state.merge });
    }

    openNewPopUp = (sujets = {}) => {
        if (Object.keys(sujets).length > 0) {
            this.setState({ topicName: sujets.name, topicId: sujets._id, topicClusters: sujets.clustersLists });
        } else {
            this.setState({ sujetsListe: [], topicName: "", topicId: "", topicClusters: [] });
        }
        this.setState({ sujetPopUp: !this.state.sujetPopUp });
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    setAndLaunch = (liste) => {
        this.setState({ getClusters: liste.clustersLists, selectedTopic: liste });
        this.mergeClusters()
    }

    updateClusterListe = (event, cluster) => {
        let getItems = this.state.topicClusters;
        let selectedClusters = [];
        if (getItems != null && getItems.length > 0) {
            selectedClusters = getItems;
        }
        const index = selectedClusters.findIndex(c => c._id == cluster._id);
        selectedClusters.splice(index, 1);
        this.setState({ topicClusters: selectedClusters });
    }

    saveTopic = (name, topicClusters) => {
        let ids = [];
        topicClusters.map(i => ids.push(i._id));

        axios.post(`${config.backEndServer}/api/cluster/topic`, {
            topicName: name,
            clusters: ids,
            update: true,
            id: this.state.topicId
        }, { withCredentials: true })
            .then((res) => {
                if (res.data.msg == "ok") {
                    toast.success('Les modifications sont faites', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    this.setState({ sujetPopUp: !this.state.sujetPopUp });
                }
            });
    }

    deleteTopic = () => {
        axios.post(`${config.backEndServer}/api/cluster/deletetopic`, {
            id: this.state.topicId
        }, { withCredentials: true })
            .then((res) => {
                if (res.data.msg == "ok") {
                    this.setState({ sujetPopUp: !this.state.sujetPopUp });
                    window.location.reload();
                }
            });
    }

    toggleCard = (index) => {
        if (index != this.state.index) {
            this.setState({ extendCard: true, index: index });
        } else if (index == this.state.index) {
            this.setState({ extendCard: !this.state.extendCard, index: -1 });
        } else {
            this.setState({ extendCard: !this.state.extendCard, index: index });
        }
    }

    mergeCols = (arraysToMerge, arraysTitles) => {
        const nbCols = arraysToMerge.length;
        const nbRows = arraysToMerge[0].length;
        let merged = [];
        for (let i = 0; i < nbRows; i++) {
            let item = {};
            for (let k = 0; k < nbCols; k++) {
                item[arraysTitles[k]] = arraysToMerge[k][i];
            }
            merged.push(item);
        }
        return merged;
    }

    ClusterList = (c, index, classes) => {
        let found = c.articlesCluster ? c.articlesCluster.filter(s => s.score > 0).filter(i => i._id == this.props.query.editeur) : [];
        let findIndex = c.articlesCluster ? c.articlesCluster.filter(s => s.score > 0).findIndex(i => i._id == this.props.query.editeur) : -1;

        let urls = [];
        c.clustersLists && c.clustersLists.map(i => urls.push(...i.urls));

        let liste = [];
        found[0] && this.mergeCols([found[0].scores ? found[0].scores : found[0].scoresFiltres, found[0].titre, found[0].articleId], ['score', 'titre', 'article']).sort((a, b) => b.score - a.score)
            .map((item) => item.score > 0 && liste.push(<div className={classes.tableLink} onClick={() => window.open(`${window.location.origin}/dashboard/article/${item.article}`)}>{decode(item.titre)}</div>))

        return (
            <div key={index} className={classes.content}>
                <div className={classes.time}>{c.date_creation && moment(c.date_creation).format("DD/MM HH:mm")}</div>
                <div className={classes.time}>{c.fromDate && moment(c.fromDate).format("DD/MM")} {c.toDate && moment(c.toDate).format("DD/MM")}</div>
                <Card className={classes.card}>
                    <div className={classes.cardContent}>
                        <div className={classes.cardContentInfo} onClick={() => this.toggleCard(index)}>
                            <div className={classes.cardContentInfoData}>
                            {
                                (c.score || urls.length > 0) &&
                                <Fragment>
                                    <div className={classes.info}>
                                        {c.score ? splitNumbers(c.score) : 0}
                                    </div>
                                    <div className={classes.label} >
                                        score
                                    </div>
                                    <div className={classes.info}>
                                        {urls.length}
                                    </div>
                                    <div className={classes.label}>
                                        articles
                                    </div>
                                    <div className={classes.info}>
                                        {c.clustersLists ? c.clustersLists.length : 0}
                                    </div>
                                    <div className={classes.label}>
                                        clusters
                                    </div>
                                </Fragment>
                            }
                            </div>
                            <div>
                                <div className={classes.titre}>
                                    {c.name}
                                </div>
                            </div>
                        </div>
                        {
                            this.props.query.editeur != "all" && found.length > 0 ?
                                <div className={classes.cardContentInfoDataClassement}>
                                    <div className={classes.info}>
                                        {findIndex + 1} / {c.articlesCluster.filter(s => s.score > 0).length}
                                    </div>
                                    <div className={classes.label}>
                                        classement
                                    </div>
                                    <div className={classes.info}>
                                        {found[0].titre.length}
                                    </div>
                                    <div className={classes.label}>
                                        {found[0].titre.length > 1 ? "articles au total" : "article au total"}
                                    </div>
                                    <div className={classes.info}>
                                        {liste.length > 0 && liste[0]}
                                    </div>
                                    <div className={classes.label}>
                                        top article
                                    </div>
                                </div>
                                :
                                <div className={classes.cardContentInfoDataClassement}></div>
                        }
                        <div className={classes.cardContentBtn}>
                            <Button variant="contained" color="primary" className={classes.button} onClick={() => this.setAndLaunch(c)}>Détails</Button>
                            <Button variant="contained" color="secondary" className={classes.button} onClick={() => this.openNewPopUp(c)}><EditIcon fontSize="small" /></Button>
                        </div>
                    </div>
                    {this.state.extendCard && this.state.index == index ?
                        <div>
                            <Divider variant="middle" />
                            <Divider variant="middle" />
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Url</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {urls.sort().map(u =>
                                        <TableRow key={u}>
                                            <TableCell className={classes.forceBreak}>
                                                <a href={`/dashboard/article/url/${encodeURIComponent(u)}`} >{u}</a>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                        : null}
                </Card>
            </div>
        )
    }

    render = () => {
        const { classes, query } = this.props;
        let { clusters, render, merge, getClusters, sujetPopUp, sujetsListe, topicName, clustersClassement, topicClusters, selectedTopic } = this.state;
        if (render) {
            if (!merge) {
                if (this.state.total > 0) {
                    return (
                        <div className="article-container">
                            <h1>Tous les sujets ({this.state.total} résultats)</h1>
                            <div className={classes.listContainer} >
                                {clusters.map((c, index) => {
                                    return this.ClusterList(c, index, classes)
                                })}
                                {this.state.isLoading ?
                                    <div className={classes.filler} >
                                        <CircularProgress className={classes.progress} />
                                    </div>
                                    : null}
                            </div>

                            <Dialog open={sujetPopUp} onClose={() => this.openNewPopUp()}>
                                <DialogTitle className={classes.dialogWindow}>Management de sujet</DialogTitle>
                                <DialogContent>
                                    <form>
                                        <TextField
                                            margin="dense"
                                            id="topicName"
                                            label="Modifier le nom du sujet"
                                            type="text"
                                            name="topicName"
                                            value={topicName ? topicName : ""}
                                            fullWidth
                                            onChange={this.handleChange}
                                        />
                                        <div style={{ marginTop: "20px" }}>
                                            <div>Liste des clusters : </div>
                                            {topicClusters && topicClusters.map((i, index) => <div style={{ display: "flex", justifyContent: "space-between", marginLeft: "10px" }}>{index + 1}. {i.titre_selectionne != null && i.titre_selectionne != "Sans titre" && i.titre_selectionne != "" ? i.titre_selectionne : i.titre} <span className={classes.closeBtn}><ClearIcon onClick={(event) => { this.updateClusterListe(event, i) }} /></span></div>)}
                                        </div>
                                    </form>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => this.deleteTopic()} color="secondary" style={{ position: "absolute", left: 0 }}>
                                        <DeleteIcon />
                                    </Button>
                                    <Button onClick={() => this.openNewPopUp()} color="primary">
                                        Annuler
                                    </Button>
                                    <Button onClick={() => this.saveTopic(topicName, topicClusters)} color="primary">
                                        Sauvegarder les changements
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    );
                } else {
                    return (
                        <div className="article-container">
                            <h1>Pas de données</h1>
                        </div>
                    )
                }
            } else {
                return (
                    <SelectedClustersSummary
                        clustersList={getClusters}
                        query={this.props.query}
                        mergeClusters={this.mergeClusters}
                        displayTrend={displayTrend}
                        getQuarter={getQuarter}
                        sujet={true}
                        selectedTopic={selectedTopic}
                    />)
            }
        } else {
            return (
                <div className={classes.filler} >
                    <CircularProgress className={classes.progress} />
                </div>
            );
        }
    };
}

SelectClusterList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SelectClusterList);
