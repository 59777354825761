import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import config from '../config';

import MUIDataTable from 'mui-datatables';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ButtonMU from '@material-ui/core/Button';
import LinkIcon from '@material-ui/icons/Link';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactChartkick, { AreaChart, ColumnChart, LineChart } from 'react-chartkick';
import Highcharts from 'highcharts'
import Datapoint from './sub_components/Datapoint';
import HC_exporting from 'highcharts/modules/exporting'
import HC_offlineExporting from 'highcharts/modules/offline-exporting'

import { customColor, saveColumnSetup, displayScore } from './utils';

HC_exporting(Highcharts);
HC_offlineExporting(Highcharts);
ReactChartkick.addAdapter(Highcharts)

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  dense: {
    marginTop: 16,
  },
  fab: {
    margin: theme.spacing.unit,
  },
  filler: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 400
  },
  dataContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'nowrap'
  }
});

const formatDate = (a, lenToCut) => {
  if (lenToCut === 9)
    return moment(a, 'YYYY-MM-DD HH').format('D/M')
  return moment(a, 'YYYY-MM-DD HH').format('H[h]')
}

const decode = (string) => {
  let finalString = '';
  try { finalString = decodeURIComponent(escape(string)) }
  catch(error) { finalString = string }
  return finalString;
}

class SitemapList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      render: 'false',
      articles: [],
    }
  };

  getData = () => {
    // const query = this.props.changeEditeur ? { ...this.props.query, sitemap: true }
    //   : {...this.props.query, editeur: 'all', sitemap: true };
    axios.post(`${config.backEndServer}/api/article`, { ...this.props.query, sitemap: true }
    , {withCredentials: true, timeout: 60000 })
      .then((res) => {
        this.setState({ render: 'true', articles: res.data });
      })
      .catch(err => {
        this.setState({ render: 'timeout'});
      });
  };

  componentDidMount = () => {
    this.getData();
  }

  render = () => {
    const { classes, isAdmin, displaySelect, onLocalHome } = this.props;

    let columns = [
        { name: '#', options: {display: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (tableMeta.rowIndex + 1);
          },
          sort: false
        } },
        { name: 'Editeur', options: { display: false } },
        { name: 'Score', options: { display: true, sortDirection: 'desc' } },
        // { name: 'Score Flipboard', options: { display: false } },
        { name: 'Score AppleNews',  options: { display: false } },
        { name: 'Desktop', options: { display: true } },
        { name: 'Mobile', options: { display: true } },
        { name: 'Titre', options: { display: true } },
        { name: 'Top Desktop', options: { display: false } },
        { name: 'Top Mobile', options: { display: false } },
        { name: 'Cat Desktop', options: { display: false } },
        { name: 'Cat Mobile', options: { display: false } },
        { name: 'Topic Desktop', options: { display: false } },
        { name: 'Topic Mobile', options: { display: false } },
        { name: 'Mise à jour Sitemap', options: { display: false } },
        { name: 'Heure de publication', options: { display: true } },
        { name: 'Dernière Modification', options: { display: true } },
        { name: 'Première exposition', options: { display: false } },
        { name: 'URL', options: {
          display: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (<a href={tableMeta.rowData[17]} target="_blank"><ButtonMU><LinkIcon /></ButtonMU></a>)
          }
        } },
        { name: 'id', options: { display: false } },
    ]
    if (onLocalHome)
      columns = [
          { name: '#', options: {display: false,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (tableMeta.rowIndex + 1);
            },
            sort: false
          } },
          { name: 'Editeur', options: { display: true } },
          { name: 'Score', options: { display: true,  } },
          { name: 'Score AppleNews',  options: { display: false } },
          { name: 'Desktop', options: { display: false } },
          { name: 'Mobile', options: { display: false } },
          { name: 'Titre', options: { display: true } },
          { name: 'Top Desktop', options: { display: false } },
          { name: 'Top Mobile', options: { display: false } },
          { name: 'Cat Desktop', options: { display: false } },
          { name: 'Cat Mobile', options: { display: false } },
          { name: 'Topic Desktop', options: { display: false } },
          { name: 'Topic Mobile', options: { display: false } },
          { name: 'Mise à jour Sitemap', options: { display: true, sortDirection: 'desc' } },
          { name: 'Heure de publication', options: { display: true } },
          { name: 'Dernière Modification', options: { display: true } },
          { name: 'Première exposition', options: { display: false } },
          { name: 'URL', options: {
            display: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (<a href={tableMeta.rowData[17]} target="_blank"><ButtonMU><LinkIcon /></ButtonMU></a>)
            }
          } },
          { name: 'id', options: { display: false } },
      ]

    const options = {
      filter: false,
      selectableRows: false,
      responsive: "stacked",
      rowsPerPage: 50,
      rowsPerPageOptions: [10,50, 100],
      downloadOptions: { filename: 'sitemaps.csv', separator: ';' },
      // onRowClick: (rowData) => window.open(`${window.location.origin}/dashboard/article/${rowData[rowData.length - 1]}`),
      onCellClick: (colData, cellMeta) => {
        if (cellMeta.colIndex !== 17) {
          const id = this.state.articles[cellMeta.rowIndex].length;
          window.open(`${window.location.origin}/dashboard/article/${this.state.articles[cellMeta.dataIndex][id - 1]}`)
        }
      },
      onColumnViewChange: (changedColumn, action) => saveColumnSetup('articleSettings', changedColumn, action)
    };

    if (this.state.render === 'true') {
      const { editeur } = this.state;

      return (
        <div>
          <MUIDataTable className="datatable"
            title={"Sitemaps"}
            data={this.state.articles}
            columns={columns}
            options={options}
          />
        </div>
        );
    } else if (this.state.render === 'timeout') {
      return (
        <div className={classes.filler} >
          <h3>Trop de résultats, délais de chargement expiré. Veuillez sélectionner des paramètres de filtre plus spécifiques.</h3>
        </div>
      )
    }
      else {
      return (
        <div className={classes.filler} >
          <CircularProgress className={classes.progress} />
        </div>
      )
    }
  };
}


SitemapList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SitemapList);
