import React, { Component, Fragment } from 'react';
import SitemapsList from './SitemapsList';
import SitemapsStates from './SitemapsStates';
import SitemapEditeur from './SitemapEditeur';
import moment from 'moment';

const Sitemaps = (props) => {
  // if timeRange more than a month => Render graph only
  const isLongRange = props.query.editeur && props.query.editeur !== 'all' && moment(props.query.toDate).diff(props.query.fromDate, 'days') > 30
  const isAdmin = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).isAdmin : null;
  return (
    <div>
      {
        props.query.editeur && props.query.editeur !== 'all' ?
        <h1>Sitemaps pour {props.query.editeur}</h1>
        : <h1>Sitemaps</h1>
      }
      {
        props.query.editeur && props.query.editeur !== 'all' && isAdmin &&
        <Fragment>
          <SitemapsStates query={props.query} />
          <h1></h1>
        </Fragment>
      }
      <SitemapEditeur query={props.query} onlySitemap={true} isAdmin />
      <h1></h1>
      <SitemapsList {...props} />
    </div>
  );
}

export default Sitemaps;
