import React  from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import WordCloud from "react-d3-cloud";

const styles = {
  container: {
    width: 600,
    height: 400,
    cursor: 'pointer'
  }
};

const handleWordClick = (word) => {
  window.open(`/dashboard/clusters/${word.text}`)
}

function TagList(props) {
  const { classes, array } = props;
  let total = 0;

  if (!array)
    return null;
  // array.forEach(a => total += a.count);
  // array.sort((a, b) => b.count - a.count);
  const words = array.map(e => { return { value: e.count * 100, text: e.Text }; })
  const fontSizeMapper = word => Math.log2(word.value) * 5;
  return (
    <div className={classes.container}>
      <WordCloud data={words} fontSizeMapper={fontSizeMapper} font={'sans-serif'} width={600} height={300} onWordClick={handleWordClick}/>,
      { /* array.map((t, index) =>
        <div key={index} className={classes.tagContainer}>
          <div key={index} className={classes.tag} style={{ transform: `scale(${1.5 - 0.5 * (t.count - total) / total })` }}  >
            {t.text}
          </div>
        </div>
        )
      */ }
    </div>
  );
}

TagList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TagList);
