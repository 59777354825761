import React from 'react';
import moment from 'moment';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Info from '@material-ui/icons/Info';
import Up from '@material-ui/icons/TrendingUp';
import Down from '@material-ui/icons/TrendingDown';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';

import TagList from './TagList';
import Datapoint from './Datapoint';
import { getGraphQuarter } from '../utils';

const styles = {
    lineContainer: {
        position: 'relative',
        height: "fit-content",
        marginTop: 20,
        marginBottom: 10,
        padding: 9,
        borderWidth: '1px 1px 1px 1px',
        borderColor: 'white',
        borderStyle: 'solid',
        backgroundColor: '#005b85bd',
        color: 'white',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontWeight: 700,
        fontSize: '1.1em',
        height: "fit-content",
        marginLeft: -300,
        borderRadius: 5
    },
    words: {
        display: 'flex',
        marginBottom: 10,
        alignItems: 'baseline'
    },
    info: {
        marginTop: 5,
        fontSize: 14,
        fontWeight: 700,
        color: 'black'
    },
    label: {
        fontWeight: 700,
        fontSize: 11,
        lineHeight: '11px',
        color: 'rgba(0, 0, 0, 0.34)',
        textAlign: 'center'
    },
}

const getQuarter = (start) => {
    return moment().subtract((start + 1) * 15 + moment().minute() % 15, 'minutes').format('HH_mm-') + (moment().subtract((start + 1) * 15 + moment().minute() % 15, 'minutes').minute() + 15);
}

const displayTrend = (cluster, classes) => {
    let recentTrend = null, olderTrend = null;
    let shortStart = null, shortEnd = null, longStart = null, longEnd = null;

    let today = moment().format('YYYY-MM-DD');

    if (!cluster.scores_quarts[today])
        return null;

    if (cluster.scores_quarts[today][getQuarter(0)])
        shortStart = cluster.scores_quarts[today][getQuarter(0)].score;
    if (cluster.scores_quarts[today][getQuarter(3)])
        shortEnd = cluster.scores_quarts[today][getQuarter(3)].score;
    if (cluster.scores_quarts[today][getQuarter(0)] && cluster.scores_quarts[today][getQuarter(1)])
        longStart = cluster.scores_quarts[today][getQuarter(0)].score + cluster.scores_quarts[today][getQuarter(1)].score;
    if (cluster.scores_quarts[today][getQuarter(14)] && cluster.scores_quarts[today][getQuarter(15)])
        longEnd = cluster.scores_quarts[today][getQuarter(14)].score + cluster.scores_quarts[today][getQuarter(15)].score;

    if (!!(shortStart) && !!(shortEnd)) {
        if (shortStart > shortEnd)
            recentTrend = (<Up />);
        else
            recentTrend = (<Down />);
    }
    if (!!(longStart) && !!(longEnd)) {
        if (longStart > longEnd)
            olderTrend = (<Up />);
        else
            olderTrend = (<Down />);
    }

    if (recentTrend === null && olderTrend === null)
        return null;

    return (
        <Card style={{
            width: 'auto',
            maxWidth: 120,
            height: '107px',
            margin: 5,
            display: 'inline-block',
            padding: 10
        }}>
            {recentTrend ?
                <div>
                    <div className={classes.info}>
                        {recentTrend}
                    </div>
                    <div className={classes.label}>
                        sur 1h
                    </div>
                </div>
                : null
            }
            {olderTrend ?
                <div>
                    <div className={classes.info}>
                        {olderTrend}
                    </div>
                    <div className={classes.label}>
                        sur 4h
                    </div>
                </div>
                : null
            }
        </Card>
    );
}

const transformHours = (date) => {
    let showHours = "";
    let hours = Math.floor(date / 60);
    let minutes = (date % 60);
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    showHours = `${hours}:${minutes}`
    return showHours;
}

function ActuLineInfo(props) {
    const { classes, cluster, rangeStart, rangeEnd, articles, editeur, dataToGet } = props;

    let timeTotalHomeInMinutes = 0;
    let timeTotalAlauneInMinutes = 0;
    for (let i = 0; i < articles.length; i++) {
        for (let k = 0; k < articles[i][dataToGet].length; k++) {
            let start = articles[i][dataToGet][k].start;
            let end = articles[i][dataToGet][k].end;
            let diff = moment(end).diff(moment(start), 'minutes')
            timeTotalHomeInMinutes += diff;
        }
    }
    if (dataToGet == "dateEnHome") {
        for (let i = 0; i < articles.length; i++) {
            if (articles[i]["dateAlaune"] && articles[i]["dateAlaune"].length > 0) {
                for (let k = 0; k < articles[i]["dateAlaune"].length; k++) {
                    let start = articles[i]["dateAlaune"][k].start;
                    let end = articles[i]["dateAlaune"][k].end;
                    let diff = moment(end).diff(moment(start), 'minutes')
                    timeTotalAlauneInMinutes += diff;
                }
            }
        }
    }

    let totalForEditor = 0;
    if (editeur) {
        let editorsArticles = articles.filter(i => i.editeur == editeur)
        for (let i = 0; i < editorsArticles.length; i++) {
            for (let k = 0; k < editorsArticles[i][dataToGet].length; k++) {
                let start = editorsArticles[i][dataToGet][k].start;
                let end = editorsArticles[i][dataToGet][k].end;
                let diff = moment(end).diff(moment(start), 'minutes')
                totalForEditor += diff;
            }
        }
    }

    let entities = cluster.entities.sort((a, b) => b.count - a.count).slice(0, 6);
    let lemmes = cluster.lemmes.sort((a, b) => b.count - a.count).slice(0, 6);
    const { score } = getGraphQuarter(cluster.scores_quarts, moment(rangeStart).format('YYYY-MM-DDTHH:mm'), moment(rangeEnd).format('YYYY-MM-DDTHH:mm'));
    return (
        <div className={classes.lineContainer}>
            <Grid container justify="space-between" spacing={12}>
                {
                    entities.length > 0 &&
                    <Grid item xs={6}>
                        <div className={classes.words}>
                            Entités : <TagList array={entities.map(a => a["Text"])} />
                        </div>
                    </Grid>
                }
                {
                    lemmes.length > 0 &&
                    <Grid item xs={6}>
                        <div className={classes.words}>
                            Lemmes : <TagList array={lemmes.map(a => a["lemme"])} />
                        </div>
                    </Grid>
                }
            </Grid>
            <div className="datapoint-container">
                <Datapoint title="Score Complet Cluster" info={cluster.score} />
                <Datapoint title="Score selon les filtres" info={score} />
                {displayTrend(cluster, classes)}
                <Datapoint title="Première Détection" info={moment(cluster.first_view).format('LLL')} />
                <Datapoint title="Dernière Modification" info={moment(cluster.lastModified).format('LLL')} />
                <Datapoint title="Nombre d'Articles" info={cluster.urls.length} />
                <Card style={{ height: '108px', margin: 5, display: 'inline-block', padding: 10 }}>
                    <div>
                        <div style={{ fontWeight: 400, fontSize: 14, color: 'rgba(0, 0, 0, 0.54)', textAlign: 'center', marginBottom: '0.35em', fontFamily: ["Roboto", "Helvetica", "Arial", 'sans-serif'], lineHeight: '2em' }}>
                            Plus d'info
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <a href={`/dashboard/cluster/${cluster._id}`} target="_blank">
                                <Info />
                            </a>
                        </div>
                    </div>
                </Card>
            </div>
            <div className={classes.words}>
                Temps total d'exposition {props.titre} : <TagList array={[transformHours(timeTotalHomeInMinutes)]} />
            </div>
            {
                dataToGet == "dateEnHome" && timeTotalAlauneInMinutes != 0 &&
                <div className={classes.words}>
                    Temps total d'exposition à la Une : <TagList array={[transformHours(timeTotalAlauneInMinutes)]} />
                </div>
            }
            {
                editeur &&
                <div className={classes.words}>
                    {`Temps total d'exposition en Home de ${editeur}`} : <TagList array={[transformHours(totalForEditor)]} />
                </div>
            }
        </div>
    )
}

ActuLineInfo.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ActuLineInfo);
