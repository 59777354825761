import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import config from '../config';
import _ from 'lodash';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    fieldContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
    listContainer: {
        paddingBottom: 150
    },
    filler: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 400
    },
    ddl: {
        position: 'absolute',
        right: 30
    },
    subtitle: {
        color: 'grey',
        textAlign: 'center'
    },
    fab: {
        margin: theme.spacing.unit,
        width: 42,
        height: 42,
        backgroundColor: '#57AAD9',
        marginTop: 20
    }
});

class CeaArticle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            render: false,
            articles: [],
            isLoading: false,
        }
    };

    getData = () => {
        if (!this.state.isLoading) {
            this.setState({ isLoading: true });
            axios.get(`${config.backEndServer}/api/article/cea`,
            { withCredentials: true })
                .then((res) => {
                    this.setState({
                        render: true,
                        articles: [...res.data.articles],
                        isLoading: false,
                    });
                });
        }
    };

    componentDidMount = () => {
        this.getData();
    }

    render = () => {
        const { classes, query } = this.props;
        let { articles } = this.state;


        if (this.state.render) {
            return (
                <div className="article-container">
                    <h1>Faits Divers</h1>
                    <h4 style={{ textAlign: "center" }}>Dernier passage : {moment().startOf('hour').format('DD-MM-YYYY HH:mm')}</h4>
                    <h1></h1>
                    {articles.map(a =>
                      <div>
                      <Card>
                        {a.titre}
                        <h1></h1>
                        {a.articles[0].chapo}
                        <h1></h1>
                        <div style={{ padding: "15px", textAlign: "start" }}><strong>Texte généré avec remudosum : </strong>{a.generated}</div>
                        <h1></h1>
                        {a.articles[0].texte}
                      </Card>
                      <h1></h1>
                      </div>
                    )}
                </div>
            );
        } else {
            return (
                <div className={classes.filler} >
                    <CircularProgress className={classes.progress} />
                </div>
            );
        }
    };
}

CeaArticle.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CeaArticle);
