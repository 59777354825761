import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import config from '../config';

import MUIDataTable from 'mui-datatables';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactChartkick, { AreaChart, ColumnChart, LineChart } from 'react-chartkick';
import Highcharts from 'highcharts'
import Datapoint from './sub_components/Datapoint';
import HC_exporting from 'highcharts/modules/exporting'
import HC_offlineExporting from 'highcharts/modules/offline-exporting'

HC_exporting(Highcharts);
HC_offlineExporting(Highcharts);
ReactChartkick.addAdapter(Highcharts)

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  dense: {
    marginTop: 16,
  },
  fab: {
    margin: theme.spacing.unit,
  },
  filler: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 400
  },
  dataContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'nowrap'
  }
});

const capitalize = words => words.split(' ').map( w =>  w.substring(0,1).toUpperCase()+ w.substring(1)).join(' ')

const optionsOrder = {
  rang_norme_score: 'Score Total',
  rang_norme_score_google_complet: 1,
  rang_norme_score_applenews: 2,
  rang_norme_score_flipboard: 3,
  rang_norme_score_google_mobile_webview: 7,
  rang_norme_score_google_desktop: 4,
  rang_norme_score_google_desktop_cat: 5,
  rang_norme_score_google_mobile_webview_cat: 8,
  rang_norme_score_google_desktop_top: 6,
  rang_norme_score_google_mobile_webview_top: 9,
  rang_norme_score_a_la_une_complet: 1,
  rang_norme_score_a_la_une_desktop: 1,
  rang_norme_score_a_la_une_mobile: 1,
  rang_norme_score_economie: 9,
  rang_norme_score_france: 2,
  rang_norme_score_homepage: 2,
  rang_norme_score_international: 3,
  rang_norme_score_sante: 23,
  'rang_norme_score_science_high-tech': 21,
  rang_norme_score_sports: 185,
  rang_norme_score_culture: 4,
  rang_norme_score_mobile_complet: 3,
  rang_norme_score_mobile_culture: 4,
  rang_norme_score_mobile_economie: 9,
  rang_norme_score_mobile_france: 2,
  rang_norme_score_mobile_homepage: 2,
  rang_norme_score_mobile_international: 3,
  rang_norme_score_mobile_sante: 24,
  'rang_norme_score_mobile_science_high-tech': 21,
  rang_norme_score_mobile_sports: 171,
  rang_norme_score_desktop_culture: 4,
  rang_norme_score_desktop_economie: 9,
  rang_norme_score_desktop_france: 2,
  rang_norme_score_desktop_homepage: 2,
  rang_norme_score_desktop_international: 3,
  rang_norme_score_desktop_sante: 19,
  'rang_norme_score_desktop_science_high-tech': 21,
  rang_norme_score_desktop_sports: 177
}

const getOptions = (ranking) => {
  const options = [{ value: 'rang_norme_score', label: 'Score Total'}];
;
  for (let option in optionsOrder) {
    if (option !== 'rang_norme_score')
      options.push({ value: option, label: capitalize(option.replace('rang_norme_score_', '').replace('_webview', '').replace(/_/g, ' ')) });
  }
  return options;
}

const displayOption = (ranking) => {
  const options = {'rang_norme_score': 'Score Total'};
  for (let option in optionsOrder) {
    if (option !== 'rang_norme_score')
      options[option] = capitalize(option.replace('rang_norme_score_', '').replace('_webview', '').replace(/_/g, ' '));
  }
  return options;
}

const getScore = (field, editeur) => {
  const entries = {};

  editeur.ranking.forEach((curr, index) => {
    if (curr[field] !== 0)
      entries[editeur.jours[index] + ' 00:00'] = curr[field];
  });
  return entries;
};

class RankingEditeurs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      render: false,
      editeur: {},
      field: ['rang_norme_score']
    }
  };

  getData = () => {
    axios.post(`${config.backEndServer}/api/editeur/ranking`, this.props.query
    , {withCredentials: true})
      .then((res) => {
        this.setState({ render: true, editeur: res.data[0] });
      });
  };

  handleChangeMultiple = event => {
    this.setState({ field: event.target.value })
  };

  componentDidMount = () => {
    this.getData();
  }

  componentDidUpdate = (prevProps) => {
    if ((prevProps.query.fromDate !== this.props.query.fromDate) || (prevProps.query.toDate !== this.props.query.toDate))
      this.getData();
  }

  render = () => {
    const { classes, isAdmin, displaySelect } = this.props;
    const { render, editeur, field } = this.state;
    const { fromDate, toDate } = this.props.query;

    if (render) {
      if (!(editeur && editeur.ranking && editeur.jours))
        return null;

      const fieldOptions = getOptions(editeur.ranking[0]);
      const displayOptions = displayOption();
      const scoreCharts = field.map(entry => { return { "name": displayOptions[entry], "data": getScore(entry, editeur) } });

      const optionsColumn = {
        exporting: {
           fallbackToExportServer: false
         },
         yAxis: {
           reversed: true,
           min: 1,
           startOnTick: false,
           endOnTick: false,
         },
      };

      return (
        <div>
          <div className="article-container">
            <div className="chart-container">
              <h3>Classement Editeur</h3>
              <Select
                id="category"
                multiple
                label="Categorie"
                className={classes.textField}
                value={field}
                onChange={this.handleChangeMultiple}
                margin="normal"
                renderValue={selected => selected.map(select => displayOptions[select]).join(', ')}
              >
                {fieldOptions.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    <Checkbox checked={field.indexOf(option.value) > -1} />
                    <ListItemText primary={option.label} />
                  </MenuItem>
                ))}
              </Select>
              <LineChart data={scoreCharts} library={optionsColumn} />
            </div>
          </div>
        </div>
        );
    } else {
      return (
        <div className={classes.filler} >
          <CircularProgress className={classes.progress} />
        </div>
      )
    }
  };
}


RankingEditeurs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RankingEditeurs);
