import React, { Component } from 'react';
import axios from 'axios';
import MUIDataTable from 'mui-datatables';
import PropTypes from 'prop-types';

import config from '../config';
import { saveColumnSetup } from './utils';

const decode = (string) => {
    let finalString = '';
    try { finalString = decodeURIComponent(escape(string)) }
    catch (error) { finalString = string }
    return finalString;
}

class PartageFb extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'Partages ' + this.props.query.share.charAt(0).toUpperCase() + this.props.query.share.slice(1),
            articles: [],
        }
    };

    getData = () => {
        const query = this.props.changeEditeur ? this.props.query : { ...this.props.query, ...{ editeur: 'all' } };
        axios.post(`${config.backEndServer}/api/article/partages`, this.props.query
            , { withCredentials: true, timeout: 60000 })
            .then((res) => {
                this.setState({ articles: res.data });
            })
            .catch(err => {
                console.error(err);
            });
    };

    componentDidUpdate = (prevProps) => {
        if ((prevProps.query.fromDate !== this.props.query.fromDate) || (prevProps.query.toDate !== this.props.query.toDate))
            this.getData();
    }

    componentDidMount = () => {
        this.getData();
    }

    render = () => {
        const articleSettings = JSON.parse(localStorage.getItem('articleSettings'));
        const exposedArticles = this.state.articles.filter(a => a[2]);

        const columns = [
            {
                name: '#', options: {
                    display: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (tableMeta.rowIndex + 1);
                    },
                    sort: false
                }
            },
            { name: 'Editeur', options: { display: articleSettings['Editeur'] } },
            {
                name: 'Partages ' + this.props.query.share.charAt(0).toUpperCase() + this.props.query.share.slice(1), options: {
                    sortDirection: 'desc'
                }
            },
            { name: 'Score', options: { display: articleSettings['Score'] } },
            {
                name: 'Score AppleNews',
                options: { display: this.props.query.source === 'applenews' ? true : articleSettings['Score AppleNews'] }
            },
            { name: 'G.News Desktop', options: { display: articleSettings['Desktop'] } },
            { name: 'G.News Mobile', options: { display: articleSettings['Mobile'] } },
            {
                name: 'Titre', options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return decode(value)
                    },
                    display: articleSettings['Titre']
                }
            },
            { name: 'G.News A la une Desktop', options: { display: articleSettings['Top Desktop'] } },
            { name: 'G.News A la une Mobile', options: { display: articleSettings['Top Mobile'] } },
            { name: 'G.News Sections Desktop', options: { display: articleSettings['Cat Desktop'] } },
            { name: 'G.News Sections Mobile', options: { display: articleSettings['Cat Mobile'] } },
            { name: 'G.News Topic Desktop', options: { display: articleSettings['Topic Desktop'] } },
            { name: 'G.News Topic Mobile', options: { display: articleSettings['Topic Mobile'] } },
            { name: 'Dernière Modification', options: { display: articleSettings['Dernière Modification'] } },
            { name: 'id', options: { display: articleSettings['id'] } },
            { name: 'Première apparition', options: { display: articleSettings['Première apparition'] } },
            { name: "Heure d'apparition Sitemap", options: { display: articleSettings["Heure d'apparition Sitemap"] } },
            { name: 'Première exposition', options: { display: articleSettings['Première exposition'] } },
            { name: 'Derniere Modification Sitemap', options: { display: 'false' } },
            { name: 'Total de partages Sociaux', options: { display: articleSettings['Partages Sociaux'] } },
            { name: 'Premiere exposition  Google Desktop', options: { display: 'false' } },
            { name: 'Premiere exposition Google Mobile', options: { display: 'false' } },
            { name: 'Premiere exposition Applenews', options: { display: 'false' } },
            { name: 'Derniere Modification Google Desktop', options: { display: 'false' } },
            { name: 'Derniere Modification Google Mobile', options: { display: 'false' } },
            { name: 'Derniere Modification Applenews', options: { display: 'false' } },
            { name: 'URL', options: { display: 'false' } },
        ];

        const options = {
            filter: false,
            selectableRows: false,
            responsive: "stacked",
            rowsPerPage: 50,
            rowsPerPageOptions: [10, 50, 100],
            downloadOptions: { filename: 'partage_fb.csv', separator: ';' },
            onRowClick: (rowData) => window.open(`${window.location.origin}/dashboard/article/${rowData[14]}`),
            onColumnViewChange: (changedColumn, action) => saveColumnSetup('articleSettings', changedColumn, action),
        };

        return (
            <div>
                <h1>{this.state.title}</h1>
                <div>
                    <MUIDataTable className="datatable"
                        title={"Articles partagés"}
                        data={exposedArticles}
                        columns={columns}
                        options={options}
                    />
                </div>
            </div>
        )
    }
}

PartageFb.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default PartageFb;