import React, { Component, Fragment } from 'react';
import moment from 'moment';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DesktopIcon from '@material-ui/icons/DesktopWindows';
import MobileIcon from '@material-ui/icons/Smartphone';
import Info from '@material-ui/icons/Info';
import Up from '@material-ui/icons/TrendingUp';
import Down from '@material-ui/icons/TrendingDown';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Coverage from './Coverage';
import { getTitle, categoriesOptions, splitNumbers } from '../utils';

import TagList from './TagList';
import FullClusterLine from './FullClusterLine';

const styles = {
  card: {
    cursor: 'pointer',
    width: 'auto',
    maxWidth: 800,
    margin: '0 auto',
    marginTop: 10,
    marginBottom: 10
  },
  cardCea: {
    width: 'auto',
    maxWidth: 800,
    margin: '0 auto',
    marginTop: 10,
    marginBottom: 10
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  trend: {
    position: 'absolute',
    left: -30
  },
  title: {
    fontSize: 14,
    textAlign: 'center',
  },
  grid: {
    textAlign: 'center',
    minHeight: 100
  },
  pos: {
    marginBottom: 12,
  },
  info: {
    marginTop: 5,
    fontSize: 14,
    fontWeight: 700
  },
  label: {
    fontWeight: 700,
    fontSize: 11,
    lineHeight: '11px',
    color: 'rgba(0, 0, 0, 0.34)',
    textAlign: 'center'
  },
  tagLabel: {
    color: 'rgba(0, 0, 0, 0.74)',
    fontWeight: 700,
    fontSize: 11
  },
  titre: {
    fontSize: 16,
    fontWeight: 700
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  forceBreak: {
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-all',
    wordBreak: 'break-word',
    hyphens: 'auto',
  },
  cea5W: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
    width: '-webkit-fill-available',
    padding: "0 15px", 
    '& div': {
      width: 200,
      textAlign: "start"
    }
  },
};


const getQuarter = (start) => {
  return moment().subtract((start + 1)* 15 + moment().minute() % 15, 'minutes').format('HH_mm-') + (moment().subtract((start + 1)* 15 + moment().minute() % 15, 'minutes').minute() + 15);
}

const displayTrend = (cluster, classes) => {
  let recentTrend = null, olderTrend = null;
  let shortStart = null, shortEnd = null, longStart = null, longEnd = null;

  let today = moment().format('YYYY-MM-DD');

  if (!cluster.scores_quarts[today])
    return <Grid item xs={1}></Grid>;

  if (cluster.scores_quarts[today][getQuarter(0)])
    shortStart = cluster.scores_quarts[today][getQuarter(0)].score;
  if (cluster.scores_quarts[today][getQuarter(3)])
    shortEnd = cluster.scores_quarts[today][getQuarter(3)].score;
  if (cluster.scores_quarts[today][getQuarter(0)] && cluster.scores_quarts[today][getQuarter(1)])
    longStart = cluster.scores_quarts[today][getQuarter(0)].score + cluster.scores_quarts[today][getQuarter(1)].score;
  if (cluster.scores_quarts[today][getQuarter(14)] && cluster.scores_quarts[today][getQuarter(15)])
    longEnd = cluster.scores_quarts[today][getQuarter(14)].score + cluster.scores_quarts[today][getQuarter(15)].score;

  if (!!(shortStart) && !!(shortEnd)) {
    if (shortStart > shortEnd)
      recentTrend = (<Up />);
    else
      recentTrend = (<Down />);
  }
  if (!!(longStart) && !!(longEnd)) {
    if (longStart > longEnd)
      olderTrend = (<Up />);
    else
      olderTrend = (<Down />);
  }

  if (recentTrend === null && olderTrend === null)
    return <Grid item xs={1}></Grid>;

  return (
    <Grid item xs={1}>
    { recentTrend ?
      <div>
        <div className={classes.info}>
          { recentTrend }
        </div>
        <div className={classes.label}>
          sur 1h
        </div>
      </div>
        : null
      }
      { olderTrend ?
        <div>
          <div className={classes.info}>
            { olderTrend }
          </div>
          <div className={classes.label}>
            sur 4h
          </div>
        </div>
        : null
      }
    </Grid>
  );
}

class ClusterLine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      extendCard: false
    }
  };

  toggleCard = () => {
    this.setState({ extendCard: !this.state.extendCard });
  }

  render = () => {
    const { classes, editeur } = this.props;
    const cluster = {};

    if (!this.props.cluster)
      return null;
    return (
      <Card className={this.props.cea ? classes.cardCea : classes.card} onClick={this.toggleCard}>
        <Grid className={classes.grid} container justify="center" alignItems="center" spacing={8}>
          <Grid item className="color-fill" xs={1}>
            <span>
              {this.props.position}
            </span>
          </Grid>
          <Grid item xs={1}>
            <div className={classes.info}>
              {splitNumbers(this.props.cluster.score) }
            </div>
            <div className={classes.label} >
              score
            </div>
            <div className={classes.info}>
              { this.props.cluster.urls.length }
            </div>
            <div className={classes.label}>
              articles
            </div>
          </Grid>
          <Grid item xs={1}><Coverage coverage={this.props.cluster.coverage} /></Grid>
          { displayTrend(this.props.cluster, classes) }
          <Grid className={classes.titre} item xs={6}>
            { getTitle(this.props.cluster) }
          </Grid>
          { /** <Grid item xs={2}>
            <div className={classes.tagLabel} >
              Categories
            </div>
            <TagList array={this.props.cluster.categories.filter(p => typeof categoriesOptions[p] !== 'undefined').map(p => categoriesOptions[p])} />
          </Grid> **/ }
          <Grid item xs={2}>
            <a href={`/dashboard/cluster/${this.props.cluster._id}`} target="_blank">
              <Info />
            </a>
          </Grid>
          { this.props.cea == true && 
              <Fragment>
                <div style={{ padding: "15px", textAlign: "start" }}><strong>Texte généré avec remudosum : </strong>{this.props.cluster.generated_text}</div>
                {this.props.cluster.analyzed &&
                  <div className={classes.cea5W}>
                    <div><strong>Who : </strong>{this.props.cluster.analyzed.who}</div>
                    <div><strong>What or Why : </strong>{this.props.cluster.analyzed.whatOrwhy}</div>
                    <div><strong>Where : </strong>{this.props.cluster.analyzed.where}</div>
                    <div><strong>When : </strong>{this.props.cluster.analyzed.when}</div>
                  </div>
                }
              </Fragment>
          }
        </Grid>
        { !this.props.cea && this.state.extendCard ?
          <div>
            <Divider variant="middle" />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Url</TableCell>
                  <TableCell>Dernière Modification</TableCell>
                  <TableCell>Dernière Exposition</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.cluster.urls.map(u =>
                  this.props.cluster.urlList && this.props.cluster.urlList.indexOf(u) > -1
                      && this.props.cluster.coverageList[this.props.cluster.urlList.indexOf(u)] !== 0 ?
                  <TableRow key={u} className={`cluster-${this.props.cluster.coverageList[this.props.cluster.urlList.indexOf(u)]}-color-fill cluster-color-link`}>
                    <TableCell className={classes.forceBreak}>
                      <a href={`/dashboard/article/url/${encodeURIComponent(u)}`} >{u}</a>
                    </TableCell>
                    <TableCell>
                      { this.props.cluster.lastDateArticleList[this.props.cluster.urlList.indexOf(u)] !== 0 ?
                          moment(this.props.cluster.lastDateArticleList[this.props.cluster.urlList.indexOf(u)]).fromNow()
                        : null}
                    </TableCell>
                    <TableCell>
                      { this.props.cluster.lastExposedList[this.props.cluster.urlList.indexOf(u)] !== 0 ?
                          moment(this.props.cluster.lastExposedList[this.props.cluster.urlList.indexOf(u)]).fromNow()
                          : null
                      }
                    </TableCell>
                  </TableRow>
                    :
                    <TableRow key={u}>
                      <TableCell className={classes.forceBreak}>
                        <a href={`/dashboard/article/url/${encodeURIComponent(u)}`} >{u}</a>
                      </TableCell>
                      <TableCell>
                    </TableCell>
                    <TableCell>
                    </TableCell>
                    </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          : null }
      </Card>
    );
  };
}

ClusterLine.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ClusterLine);
