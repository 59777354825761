import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import config from '../config';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import DownloadIcon from '@material-ui/icons/SaveAlt';
import PlayIcon from '@material-ui/icons/PlayArrow';
import ReplayIcon from '@material-ui/icons/Replay';

import MUIDataTable from 'mui-datatables';

import Cookies from 'js-cookie';

import { saveColumnSetup } from './utils';

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#57AAD9',
    color: theme.palette.common.white,
    fontSize: 16,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  ddl: {
  },
  subtitle: {
    color: 'grey',
    textAlign: 'center'
  },
  fab: {
    margin: theme.spacing.unit,
    width: 42,
    height: 42,
    backgroundColor: '#57AAD9',
    marginTop: 20
  }
});

class Reporting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      render: false,
      reportingUrl: '',
      status: 'none',
      dateReporting: ''
    }
  };

  startDailyReport = () => {
    this.props.socket.emit('startDailyReport', this.props.query);
  }

  componentDidMount = () => {
    this.props.socket.subDailyReportStatus((err, status, reportingUrl, dateReporting) => this.setState({render: true, status: status, reportingUrl: reportingUrl, dateReporting: dateReporting}));
    this.props.socket.emit('checkDailyReportStatus', this.props.query);
  }

  render = () => {
    const { classes, isAdmin } = this.props;
    const { reportingUrl, status, dateReporting } = this.state;

    const editeurSettings = JSON.parse(localStorage.getItem('editeurSettings'));

    if (this.state.render) {
      if (status === 'none') {
        return (
          <div>
            <h1></h1>
            <h1></h1>
            <h3>Lancer le reporting</h3>
            <Fab color="primary" aria-label="Start" className={classes.fab} onClick={this.startDailyReport}>
              <PlayIcon />
            </Fab>
          </div>
        );
      }  else if (status === 'existing' || status === 'done') {
        return (
          <div>
            <h1></h1>
            <h1></h1>
            <h3>Reporting généré le {moment(dateReporting).format('DD/MM HH:mm')}</h3>
            <a target="_blank" className={classes.ddl} href={config.backEndServer + '/reporting/' + reportingUrl} download>
              <Fab color="primary" aria-label="Download" className={classes.fab}>
                <DownloadIcon />
              </Fab>
            </a>
            <Fab color="primary" aria-label="Replay" className={classes.fab} onClick={() => this.startDailyReport()}>
              <ReplayIcon />
            </Fab>
          </div>
        );
    } else if (status === 'processing') {
      return (
        <div>
          <h1></h1>
          <h1></h1>
          <CircularProgress className={classes.progress} />
          <h3>Progression en cours, lancé le {moment(dateReporting).format('DD/MM HH:mm')}</h3>
          <Fab color="primary" aria-label="Replay" className={classes.fab} onClick={() => this.startDailyReport()}>
            <ReplayIcon />
          </Fab>
        </div>
      )
      }
    } else {
      return (
        <div className={classes.filler} >
          <CircularProgress className={classes.progress} />
        </div>
      );
    }
  };
}

Reporting.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Reporting);
