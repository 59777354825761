import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import config from '../config';

import MUIDataTable from 'mui-datatables';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ReactChartkick, { AreaChart, ColumnChart, LineChart } from 'react-chartkick';
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting'
import HC_offlineExporting from 'highcharts/modules/offline-exporting'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Datapoint from './sub_components/Datapoint';
import DataEntry from './sub_components/DataEntry';
import Word from './sub_components/Word';

HC_exporting(Highcharts);
HC_offlineExporting(Highcharts);
ReactChartkick.addAdapter(Highcharts)

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  dense: {
    marginTop: 16,
  },
  fab: {
    margin: theme.spacing.unit,
  },
  filler: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 400
  },
  dataContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

const categoriesOptions = [
  { value: 'all', label: 'Toutes' },
  { value: 'homepage', label: 'A la Une' },
  { value: 'france', label: 'France' },
  { value: 'international', label: 'International'},
  { value: 'économie', label: 'Economie' },
  { value: 'science_high-tech', label: 'Science'},
  { value: 'culture', label: 'Culture' },
  { value: 'sports', label: 'Sport' },
  { value: 'santé', label: 'Sante' }
];

class ArticlesGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      render: false,
      entities: [],
      lemmes: [],
      clusterCategory: 'all',
      // coverageStatus: false
    }
  };

  getData = () => {
    axios.post(`${config.backEndServer}/api/cluster/keywords`, {...this.props.query, clusterCategory: this.state.clusterCategory}
    , {withCredentials: true})
      .then((res) => {
        this.setState({ render: true, entities: res.data.entities, lemmes: res.data.lemmes});

        const keywordList = res.data.entities.map(e => e.text);
        axios.post(`${config.backEndServer}/api/cluster/keywords/details`, {...this.props.queryDay, keywords: keywordList},
          {withCredentials: true})
          .then(res => {
            const details = res.data;
            const newEntities = this.state.entities.map(w => {
              const index = details.findIndex(t => w.text.toLowerCase() === t._id);
              if (index > -1) {
                // if (!!(this.props.coverage)) {
                  w.coverage = details[index].coverage;
                  // c.coverageList = details[index].coverageList;
                  // c.urlList = details[index].urls;
                  // c.lastDateArticleList = details[index].lastDateArticleList;
                  // c.lastExposedList = details[index].lastExposedList;
                // }
              }
              return w;
            });
            console.log(newEntities);
            this.setState({ entities: newEntities })
          })
      });
  };

  componentDidMount = () => {
    this.getData();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.clusterCategory !== this.state.clusterCategory)
      this.getData();
  }

  render = () => {
    const { classes, isAdmin, query, newQuery, queryDay } = this.props;
    const { render, entities, lemmes } = this.state;

    if (!entities)
      return null;
    if (render) {
      return (
        <div className="article-container">
          <TextField
            id="category"
            select
            label="Categorie"
            className={classes.textField}
            value={this.state.clusterCategory}
            onChange={event => this.setState({ clusterCategory: event.target.value })}
            SelectProps={{
              MenuProps: {
                className: classes.menu,
              },
            }}
            margin="normal"
            variant="filled"
          >
            {categoriesOptions.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <div className="datapoint-container">
          <h3>Entités</h3>
            { entities.map((e) => <Word query={queryDay} newQuery={newQuery} coverage={e.coverage}  entity={e.text} lemmes={e.linkedLemmes.map(lem => lem.text)}/>) }
          </div>
          {
            lemmes.length ?
            <div className="datapoint-container">
              <h3>Sujet fort sans entités</h3>
              { lemmes.map((l) => <Word query={queryDay} newQuery={newQuery} lemmes={l.map(lem => lem.text)}/>) }
            </div>
            : null
          }

        </div>
      );
    } else {
      return (
        <div className={classes.filler} >
          <CircularProgress className={classes.progress} />
        </div>
      )
    }
  };
}


ArticlesGraph.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ArticlesGraph);
