import React, { Component } from 'react';
import axios from 'axios';
import MUIDataTable from 'mui-datatables';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

import config from '../../config';
import { saveColumnSetup } from '../utils';

const styles = theme => ({
    filler: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 400
    }
});

class ApplenewsEditeurs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editeurs: [],
            render: false,
            query: this.props.query
        }
    };

    getData = () => {
        axios.post(`${config.backEndServer}/api/editeur`, {...this.state.query,applenews: true}, { withCredentials: true })
            .then((res) => {
                this.setState({ render: true, editeurs: res.data || [] });
            })
    };


    componentDidMount = () => {
        this.setState({ query: {...this.props.query, applenews: true} });
        this.getData();
    }

    render = () => {
        const { optionsTable, classes } = this.props;

        const editeurSettings = JSON.parse(localStorage.getItem('editeurSettings'));

        const columns = [
            {
                name: '#', options: {
                    display: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (tableMeta.rowIndex + 1);
                    },
                    sort: false
                }
            },
            { name: 'Groupe Editeur', options: { display: editeurSettings['Groupe Editeur'] } },
            { name: 'Articles Exposes', options: { display: false } },
            { name: 'Nouveaux Articles', options: { display: false } },
            { name: 'Articles Applenews', options: { display: true, sortDirection: 'desc' } },
            { name: 'Score', options: { display: false } },
            { name: 'Score Flipboard', options: { display: editeurSettings['Score Flipboard'] } },
            { name: 'Score Applenews', options: { display: true } },
            { name: 'G.News A la une', options: { display: editeurSettings['A la une'] } },
            { name: 'G.News Sections', options: { display: editeurSettings['Sections'] } },
            { name: 'G.News Topic', options: { display: editeurSettings['Topic'] } },
            { name: 'Homepage', options: { display: editeurSettings['Homepage'] } },
            { name: 'France', options: { display: editeurSettings['France'] } },
            { name: 'International', options: { display: editeurSettings['International'] } },
            { name: 'Economie', options: { display: editeurSettings['Economie'] } },
            { name: 'Sciences et Technos', options: { display: editeurSettings['Sciences et Technos'] } },
            { name: 'Divertissement', options: { display: editeurSettings['Divertissement'] } },
            { name: 'Sports', options: { display: editeurSettings['Sports'] } },
            { name: 'Sante', options: { display: editeurSettings['Sante'] } }
        ];

        let options = {
            filter: false,
            selectableRows: false,
            responsive: "stacked",
            rowsPerPage: 50,
            rowsPerPageOptions: [10, 50, 100],
            onRowClick: (rowData) => {
                delete this.props.query.limit;
                this.props.newQuery({ ...this.props.query, ...{ editeur: rowData[1] } },
                    () => { window.open(`${window.location.origin}/dashboard/articles`), "/dashboard/applenews"
         });
            },
            onColumnSortChange: (changedColumn, direction) => {
                console.log(changedColumn, direction)
            },
            onColumnViewChange: (changedColumn, action) => saveColumnSetup('editeurSettings', changedColumn, action)
        };
        options = { ...options, ...optionsTable };


        if (this.state.render) {
            return (
                <MUIDataTable
                    title={"Editeurs"}
                    data={this.state.editeurs}
                    columns={columns}
                    options={options}
                />
            );
        } else {
            return (
                <div className={classes.filler} >
                    <CircularProgress className={classes.progress} />
                </div>
            );
        }
    };
}


export default withStyles(styles)(ApplenewsEditeurs);