import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash'
import moment from 'moment';

import MUIDataTable from 'mui-datatables';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Info from '@material-ui/icons/Info';
import ButtonMU from '@material-ui/core/Button';
import LinkIcon from '@material-ui/icons/Link';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import ActuLineCluster from './ActuLineCluster.js'
import ActuList from './ActuList.js'

import { getTitle, getOffset } from '../utils'

const styles = {
  clusterContainer: {
    width: 980,
    paddingLeft: 150
  },
  meter: {
    width: 980,
    position: 'relative',
    height: 18,
    marginBottom: 20,
    overflow: 'hidden',
    marginLeft: 150
  },
  hour: {
    position: 'absolute',
  }
}

class ActusClusters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCluster: null
    }
  };

  onClick = (c) => {
    if (this.state.selectedCluster && c._id === this.state.selectedCluster._id)
      this.setState({ selectedCluster: null })
    else
      this.setState({ selectedCluster: c })
  }

  render = () => {
    const { selectedCluster } = this.state
    const { articles, classes, isAdmin, rangeStart, rangeEnd, editeur, dataToGet } = this.props;
    let clusteredArticles = articles.filter(a => a.clusterArticle.length)
    let clusters = articles.filter(a => a.clusterArticle.length).map(a => a.clusterArticle[0])

    let clusterExposition = {}
    for (let i = 0; i < clusteredArticles.length; i++) {
      if (clusterExposition[clusteredArticles[i].clusterArticle[0]._id])
        clusterExposition[clusteredArticles[i].clusterArticle[0]._id] += clusteredArticles[i][dataToGet].reduce((prev, curr) => {
          let start = curr.start;
          let end = curr.end;
          if (curr.start < rangeStart.format('YYYY-MM-DD HH:mm:ss'))
            start = rangeStart
          else if (curr.start > rangeEnd.format('YYYY-MM-DD HH:mm:ss'))
            start = rangeEnd
          if (curr.end < rangeStart.format('YYYY-MM-DD HH:mm:ss'))
            end = rangeStart
          else if (curr.end > rangeEnd.format('YYYY-MM-DD HH:mm:ss'))
            end = rangeEnd
          return moment(end).diff(moment(start)) + prev
        }, 0)
      else
        clusterExposition[clusteredArticles[i].clusterArticle[0]._id] = clusteredArticles[i][dataToGet].reduce((prev, curr) => {
          let start = curr.start;
          let end = curr.end;
          if (curr.start < rangeStart.format('YYYY-MM-DD HH:mm:ss'))
            start = rangeStart
          else if (curr.start > rangeEnd.format('YYYY-MM-DD HH:mm:ss'))
            start = rangeEnd
          if (curr.end < rangeStart.format('YYYY-MM-DD HH:mm:ss'))
            end = rangeStart
          else if (curr.end > rangeEnd.format('YYYY-MM-DD HH:mm:ss'))
            end = rangeEnd
          return moment(end).diff(moment(start)) + prev
      }, 0)
    }
    clusters = clusters.map(c => { return {...c, clusterExposition: clusterExposition[c._id] }})
    clusters = _.uniqBy(clusters, e => e._id)
    clusters = clusters.sort((a,b) => b.clusterExposition - a.clusterExposition)

    const hours = []

    for (let i = 1; i < rangeEnd.hours() - rangeStart.hours(); i++) {
      let startHour = moment(rangeStart)
      hours[i] = getOffset(startHour.set({hours: rangeStart.hours() + i, second: 0, millisecond: 0}), rangeStart, rangeEnd)
    }

    let clusterEditeur = [];
    if (editeur !== "Tous") {
      let clusterIds = articles.filter(a => a.editeur === editeur).map(a => a.cluster_id);
      clusterEditeur = clusters.filter(c => clusterIds.includes(c._id))
    }

    return (
      <div className='article-container'>
        <div className={classes.meter}>
          <div className={classes.hour} style={{left: 0, fontWeight: 700}}>{rangeStart.format('HH:mm')}</div>
          { hours.map((offset, i) => <div key={i} className={classes.hour} style={{left: offset}}>{rangeStart.hours() + i}:00</div>) }
          <div className={classes.hour} style={{right: 0, fontWeight: 700}}>{rangeEnd.format('HH:mm')}</div>
        </div>

        <div className={classes.clusterContainer}>
          {
            editeur == "Tous" ?
              clusters.map(c =>
                <ActuLineCluster key={c._id} articles={articles.filter(a => c._id === a.cluster_id)} cluster={c} onClick={this.onClick}
                selected={selectedCluster && c._id === selectedCluster._id} rangeStart={rangeStart} rangeEnd={rangeEnd} dataToGet={dataToGet} titre={this.props.titre} />
              )
            :
            clusterEditeur.map(c =>
                <ActuLineCluster key={c._id} articles={articles.filter(a => c._id === a.cluster_id)} cluster={c} onClick={this.onClick}
                selected={selectedCluster && c._id === selectedCluster._id} rangeStart={rangeStart} rangeEnd={rangeEnd} editeur={editeur} dataToGet={dataToGet} titre={this.props.titre} />
              )
          }
        </div>
      </div>
  )
  }
}

ActusClusters.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ActusClusters);
