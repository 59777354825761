import React, { Component, Fragment } from 'react';
import moment from 'moment';
import axios from 'axios';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Info from '@material-ui/icons/Info';
import Up from '@material-ui/icons/TrendingUp';
import Down from '@material-ui/icons/TrendingDown';
import Grid from '@material-ui/core/Grid';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'react-toastify/dist/ReactToastify.css';

import Coverage from './Coverage';
import { getTitle, splitNumbers, replaceCharacters } from '../utils';
import config from '../../config';

const styles = {
    card: {
        cursor: 'pointer',
        width: 'auto',
        maxWidth: 800,
        margin: '0 auto',
        marginTop: 10,
    },
    cardBis: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        cursor: 'default',
        width: 'auto',
        maxWidth: 800,
        margin: '0 auto',
        marginBottom: 10,
        marginTop: 1,
        padding: 10,
    },
    grid: {
        textAlign: 'center',
        minHeight: 100
    },
    info: {
        marginTop: 5,
        fontSize: 14,
        fontWeight: 700
    },
    label: {
        fontWeight: 700,
        fontSize: 11,
        lineHeight: '11px',
        color: 'rgba(0, 0, 0, 0.34)',
        textAlign: 'center'
    },
    titre: {
        fontSize: 16,
        fontWeight: 700,
    },
    titreCardContent: {
        fontSize: 16,
        fontWeight: 700,
        marginBottom: 10
    },
    tag: {
        display: 'table',
        backgroundColor: '#bd1e1e66',
        minWidth: 0,
        boxSizing: 'border-box',
        borderRadius: 5,
        margin: '5px 2px',
        fontSize: 16,
        padding: 5,
        color: 'rgb(82, 81, 110)',
        cursor: 'pointer'
    },
    specialTag: {
        display: 'table',
        backgroundColor: '#04890866',
        minWidth: 0,
        boxSizing: 'border-box',
        borderRadius: 5,
        margin: '5px 2px',
        fontSize: 16,
        padding: 5,
        color: 'rgb(82, 81, 110)',
        border: 'solid 2px #048908',
        cursor: 'pointer',
        boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)'
    },
    selectedTag: {
        display: 'inline-block',
        backgroundColor: '#57aad966',
        minWidth: 0,
        boxSizing: 'border-box',
        borderRadius: 5,
        margin: '5px 2px',
        fontSize: 16,
        padding: 5,
        color: 'rgb(82, 81, 110)',
        border: 'solid 2px #57aad9',
        boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)'
    },
    btn: {
        backgroundColor: '#57AAD9',
        marginTop: 20,
        alignSelf: 'center'
    },
    filler: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 100
    },
};


const getQuarter = (start) => {
    return moment().subtract((start + 1) * 15 + moment().minute() % 15, 'minutes').format('HH_mm-') + (moment().subtract((start + 1) * 15 + moment().minute() % 15, 'minutes').minute() + 15);
}

const displayTrend = (cluster, classes) => {
    let recentTrend = null, olderTrend = null;
    let shortStart = null, shortEnd = null, longStart = null, longEnd = null;

    let today = moment().format('YYYY-MM-DD');

    if (cluster.scores_quarts && !cluster.scores_quarts[today])
        return <Grid item xs={1}></Grid>;

    if (cluster.scores_quarts && cluster.scores_quarts[today][getQuarter(0)])
        shortStart = cluster.scores_quarts[today][getQuarter(0)].score;
    if (cluster.scores_quarts && cluster.scores_quarts[today][getQuarter(3)])
        shortEnd = cluster.scores_quarts[today][getQuarter(3)].score;
    if (cluster.scores_quarts && cluster.scores_quarts[today][getQuarter(0)] && cluster.scores_quarts[today][getQuarter(1)])
        longStart = cluster.scores_quarts[today][getQuarter(0)].score + cluster.scores_quarts[today][getQuarter(1)].score;
    if (cluster.scores_quarts && cluster.scores_quarts[today][getQuarter(14)] && cluster.scores_quarts[today][getQuarter(15)])
        longEnd = cluster.scores_quarts[today][getQuarter(14)].score + cluster.scores_quarts[today][getQuarter(15)].score;

    if (!!(shortStart) && !!(shortEnd)) {
        if (shortStart > shortEnd)
            recentTrend = (<Up />);
        else
            recentTrend = (<Down />);
    }
    if (!!(longStart) && !!(longEnd)) {
        if (longStart > longEnd)
            olderTrend = (<Up />);
        else
            olderTrend = (<Down />);
    }

    if (recentTrend === null && olderTrend === null)
        return <Grid item xs={1}></Grid>;

    return (
        <Grid item xs={1}>
            {recentTrend ?
                <div>
                    <div className={classes.info}>
                        {recentTrend}
                    </div>
                    <div className={classes.label}>
                        sur 1h
                    </div>
                </div>
                : null
            }
            {olderTrend ?
                <div>
                    <div className={classes.info}>
                        {olderTrend}
                    </div>
                    <div className={classes.label}>
                        sur 4h
                    </div>
                </div>
                : null
            }
        </Grid>
    );
}

class NewsboxLine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            extendCard: false,
            selected: [],
            expressions: [],
            isSent: false,
            isLoading: true,
        }
    };

    componentDidMount = () => {
        const { cluster } = this.props;
        let { expressions } = this.state;
        let entiteArr = [], lemmesArr = [];

        const onlyUnique = (item, type) => {
            let i = type == 'entiteArr' 
                ? entiteArr.findIndex(x => (
                    x['Text'].normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() == item['Text'].normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()) 
                    || x['Text'].includes(item['Text']) 
                    || item['Text'].split(" ").some(text => text == x['Text'])) 
                : lemmesArr.findIndex(x => (x['lemme'].normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() == item['lemme'].normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()));
            if (i == -1) {
                type == 'entiteArr' 
                ? entiteArr.push(item) 
                : lemmesArr.push(item);
            } else {
                return type == 'entiteArr' 
                ? entiteArr[i]['count'] > item['count'] ? null : entiteArr[i] = item 
                : lemmesArr[i]['count'] > item['count'] ? null : lemmesArr[i] = item;
            }
            return null;
        }

        if (cluster.entities) 
            cluster.entities.sort((a, b) => b.count - a.count).filter((i) => onlyUnique(i, 'entiteArr'));
        if (cluster.lemmes) 
            cluster.lemmes.sort((a, b) => b.count - a.count).filter((i) => onlyUnique(i, 'lemmesArr'));

        if (entiteArr) {
            entiteArr = entiteArr.slice(0, 3);
            lemmesArr = lemmesArr.slice(0, 3);

            const e1 = entiteArr[0] ? replaceCharacters(entiteArr[0]['Text']) : "";
            const e2 = entiteArr[1] ? replaceCharacters(entiteArr[1]['Text']) : "";
            const e3 = entiteArr[2] ? replaceCharacters(entiteArr[2]['Text']) : "";
            const l1 = lemmesArr[0] && lemmesArr[0].lemme ? replaceCharacters(lemmesArr[0].lemme) : "";
            const l2 = lemmesArr[1] && lemmesArr[1].lemme ? replaceCharacters(lemmesArr[1].lemme) : "";
            const l3 = lemmesArr[2] && lemmesArr[2].lemme ? replaceCharacters(lemmesArr[2].lemme) : "";

            const exp1 = e1 + " " + e2;
            const exp2 = e1 + " " + (e2 != "" ? e2 + " " : "") + e3;
            const exp3 = e1 + " " + (e2 != "" ? e2 + " " : "") + l1;
            const exp4 = e1 + " " + (e2 != "" ? e2 + " " : "") + l2;
            const exp5 = e1 + " " + (l1 != "" ? l1 + " " : "") + l2;
            const exp6 = e1 + " " + (l2 != "" ? l2 + " " : "") + l3;
            const exp7 = e2 + " " + (l1 != "" ? l1 + " " : "") + l2;
            exp1.trim() != "" && expressions.push(exp1.trim());
            exp2.trim() != "" && expressions.push(exp2.trim());
            exp3.trim() != "" && expressions.push(exp3.trim());
            exp4.trim() != "" && expressions.push(exp4.trim());
            exp5.trim() != "" && expressions.push(exp5.trim());
            exp6.trim() != "" && expressions.push(exp6.trim());
            exp7.trim() != "" && expressions.push(exp7.trim());
            expressions = [...new Set(expressions)];

            this.setState({ selected: expressions, expressions: expressions });
        }
    }

    getExpressionsSujet = async () => {
        let { cluster } = this.props;
        let { expressions } = this.state;
        let entiteArr = [], lemmesArr = [];
        let promises = [];

        if (cluster.newsboxExp && cluster.newsboxExp.length > 0) {
            this.setState({ isLoading: !this.state.isLoading });
            return
        }

        cluster.clustersLists.map(cl => {
            promises.push(axios.post(`${config.backEndServer}/api/cluster/${cl._id}`,
                { query: this.props.query },
                { withCredentials: true }));
        })
        await Promise.all(promises).then((res) => {
            cluster.entities = [];
            cluster.lemmes = [];
            res.forEach(r => {
                cluster.clustersLists.map(cl => {
                    if (cl._id == r.data._id) {
                        if (r.data.entities) cluster.entities = [...cluster.entities, ...r.data.entities]
                        if (r.data.lemmes) cluster.lemmes = [...cluster.lemmes, ...r.data.lemmes]
                    }
                })
            });
        });

        const onlyUnique = (item, type) => {
            let i = type == 'entiteArr' 
                ? entiteArr.findIndex(x => (
                    x['Text'].normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() == item['Text'].normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()) 
                    || x['Text'].includes(item['Text']) 
                    || item['Text'].split(" ").some(text => text == x['Text'])) 
                : lemmesArr.findIndex(x => (x['lemme'].normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() == item['lemme'].normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()));
            if (i == -1) {
                type == 'entiteArr' 
                ? entiteArr.push(item) 
                : lemmesArr.push(item);
            } else {
                return type == 'entiteArr' 
                ? entiteArr[i]['count'] > item['count'] ? null : entiteArr[i] = item 
                : lemmesArr[i]['count'] > item['count'] ? null : lemmesArr[i] = item;
            }
            return null;
        }

        if (cluster.entities) 
            cluster.entities.sort((a, b) => b.count - a.count).filter((i) => onlyUnique(i, 'entiteArr'));
        if (cluster.lemmes) 
            cluster.lemmes.sort((a, b) => b.count - a.count).filter((i) => onlyUnique(i, 'lemmesArr'));

        if (entiteArr) {
            entiteArr = entiteArr.slice(0, 3);
            lemmesArr = lemmesArr.slice(0, 3);

            const e1 = entiteArr[0] ? replaceCharacters(entiteArr[0]['Text']) : "";
            const e2 = entiteArr[1] ? replaceCharacters(entiteArr[1]['Text']) : "";
            const e3 = entiteArr[2] ? replaceCharacters(entiteArr[2]['Text']) : "";
            const l1 = lemmesArr[0] && lemmesArr[0].lemme ? replaceCharacters(lemmesArr[0].lemme) : "";
            const l2 = lemmesArr[1] && lemmesArr[1].lemme ? replaceCharacters(lemmesArr[1].lemme) : "";
            const l3 = lemmesArr[2] && lemmesArr[2].lemme ? replaceCharacters(lemmesArr[2].lemme) : "";

            const exp1 = e1 + " " + e2;
            const exp2 = e1 + " " + (e2 != "" ? e2 + " " : "") + e3;
            const exp3 = e1 + " " + (e2 != "" ? e2 + " " : "") + l1;
            const exp4 = e1 + " " + (e2 != "" ? e2 + " " : "") + l2;
            const exp5 = e1 + " " + (l1 != "" ? l1 + " " : "") + l2;
            const exp6 = e1 + " " + (l2 != "" ? l2 + " " : "") + l3;
            const exp7 = e2 + " " + (l1 != "" ? l1 + " " : "") + l2;
            exp1.trim() != "" && expressions.push(exp1.trim());
            exp2.trim() != "" && expressions.push(exp2.trim());
            exp3.trim() != "" && expressions.push(exp3.trim());
            exp4.trim() != "" && expressions.push(exp4.trim());
            exp5.trim() != "" && expressions.push(exp5.trim());
            exp6.trim() != "" && expressions.push(exp6.trim());
            exp7.trim() != "" && expressions.push(exp7.trim());
            expressions = [...new Set(expressions)];

            this.setState({ selected: expressions, expressions: expressions, isLoading: !this.state.isLoading });
        }
    }

    toggleCard = async (sujet) => {
        if (sujet === true && this.state.extendCard != true) {
            this.setState({ extendCard: !this.state.extendCard });
            await this.getExpressionsSujet();
        } else {
            this.setState({ extendCard: !this.state.extendCard, isLoading: !this.state.isLoading });
        }
    }

    handleCard = (e) => {
        const { innerText } = e.target;
        let selected = this.state.selected;
        if (selected.includes(innerText)) {
            selected = selected.filter(i => i != innerText);
        } else {
            selected.push(innerText)
        }
        this.setState({ selected: selected });
        e.target.classList.toggle(this.props.classes.specialTag)
    }

    saveExpressions = () => {
        axios.post(`${config.backEndServer}/api/cluster/expressions`, {
            clusterId: this.props.cluster._id,
            titre: this.props.cluster.titre_selectionne && this.props.cluster.titre_selectionne !== '' && this.props.cluster.titre_selectionne !== 'Sans titre' ? this.props.cluster.titre_selectionne : this.props.cluster.titre ? this.props.cluster.titre : this.props.cluster.name,
            selected: this.state.selected,
        }, { withCredentials: true })
        .then((res) => {
            this.props.updateNbExp(this.state.selected.length);
            toast.success('Les expressions sont sauvegardées', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.setState({ isSent: true });
            // axios.get(`${config.backEndServer}/api/cluster/expressions/${res.data.id}`,
            // { withCredentials: true })
            // .then((res) => {
            //     console.log(res.data)
            // });
        });
    }

    render = () => {
        const { classes, cluster, sujet } = this.props;
        let { selected, expressions, isSent, isLoading } = this.state;

        if (!cluster)
            return null;
        return (
            <Card className={classes.card}>
                <CardActionArea onClick={() => this.toggleCard(sujet)}>
                    <Grid className={classes.grid} container justify="center" alignItems="center" spacing={8}>
                        <Grid item className="color-fill" xs={1}>
                            <span>
                                {this.props.position}
                            </span>
                        </Grid>
                        {
                            sujet !== true ?
                            <Grid item xs={1}>
                                <div className={classes.info}>
                                    {splitNumbers(cluster.score)}
                                </div>
                                <div className={classes.label} >
                                    score
                                </div>
                                <div>
                                    <div className={classes.info}>
                                        {cluster.urls ? cluster.urls.length : 0}
                                    </div>
                                    <div className={classes.label}>
                                        articles
                                    </div>
                                </div>
                            </Grid>
                            :
                            <Grid item xs={2}>
                                <div className={classes.info}>
                                    {splitNumbers(cluster.score)}
                                </div>
                                <div className={classes.label} >
                                    score
                                </div>
                            </Grid>
                        }
                        {
                            sujet !== true &&
                            <Grid item xs={1}><Coverage coverage={cluster.coverage} /></Grid>
                        }
                        {
                            sujet !== true &&
                            displayTrend(cluster, classes)
                        }
                        {
                            sujet !== true ?
                            <Grid className={classes.titre} item xs={6}>
                                {getTitle(cluster)}
                            </Grid>
                            :
                            <Grid className={classes.titre} item xs={8} style={{textAlign: 'initial'}}>
                                {cluster.name}
                            </Grid>
                        }
                        {
                            sujet !== true &&
                            <Grid item xs={2}>
                                <a href={`/dashboard/cluster/${cluster._id}`} target="_blank">
                                    <Info />
                                </a>
                            </Grid>
                        }
                    </Grid>
                </CardActionArea>
                {this.state.extendCard ?
                    cluster.newsboxExp.length > 0 ?
                    <CardContent className={classes.cardBis}>
                        <div>
                            <div className={classes.titreCardContent}>Les expressions choisies :</div>
                            {cluster.newsboxExp[0].map((u, index) => {
                                return <div key={u} className={classes.selectedTag}> {u} </div>
                                }
                            )}
                        </div>
                    </CardContent>
                    :
                    isLoading == true ?
                    <CardContent className={classes.cardBis}>
                        <div className={classes.filler} >
                            <CircularProgress className={classes.progress} />
                        </div>
                    </CardContent>
                    :
                    <CardContent className={classes.cardBis}>
                        <div>
                            <div className={classes.titreCardContent}>Choisir les expressions :</div>
                            {[...new Set(expressions)].map((u, index) => {
                                return <div key={u} className={selected.includes(u) ? classes.specialTag : classes.tag} onClick={this.handleCard}> {u} </div>
                                }
                            )}
                        </div> 
                        {
                            !isSent && <Button variant="contained" color="primary" className={classes.btn} onClick={this.saveExpressions}> Valider les expressions </Button>
                        }
                    </CardContent>
                    : null
                }
            </Card>
            
        );
    };
}

NewsboxLine.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NewsboxLine);
