import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import ThemeSummary from './sub_components/ThemeSummary';
import ThemeEditor from './sub_components/ThemeEditor';
import ThemeRanking from './sub_components/ThemeRanking';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import config from '../config';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import DownloadIcon from '@material-ui/icons/SaveAlt';
import ReplayIcon from '@material-ui/icons/Replay';

const styles = theme => ({
  reporting: {
    position: 'absolute',
    marginTop: -30,
    right: 45,
    padding: 10,
    opacity: 0.8,
    fontSize: 11,
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: 'rgb(87, 170, 217, 0.8)',
    textAlign: 'center'
  },
  fab: {
    width: 42,
    height: 42,
    backgroundColor: '#57AAD9',
  },
  fillerReporting: {
    position: 'absolute',
    marginTop: -30,
    right: 45,
    padding: 10,
    opacity: 0.8,
    fontSize: 11,
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center'
  },
});

class Theme extends Component {
    constructor(props) {
      super(props);
      this.state = {
        value: 0,
        status: "none",
        reportingUrl: "",
        dateReporting: ''
      }
    };

    handleChange = (event, newValue) => {
      this.setState({ value: newValue });
    };

    getReporting = async () => {
      this.props.socket.emit('startThemeReport', this.props.query);
    };

    componentDidMount = () => {
      this.props.socket.subThemeReportStatus((err, status, reportingUrl, dateReporting) => this.setState({render: true, status: status, reportingUrl: reportingUrl, dateReporting: dateReporting}));
      this.props.socket.emit('checkThemeReportStatus', this.props.query);
    }

    render = () => {
      const { classes, isAdmin } = this.props;
      const { value, reportingUrl, dateReporting } = this.state;
      let openTab;

      if (value === 0) {
        openTab = <ThemeSummary {...this.props} />
      } else if (value === 1) {
        openTab = <ThemeEditor {...this.props} />
      } else {
        openTab = <ThemeRanking {...this.props} />
      }

      if (this.props.query.clusterEntities === '' && this.props.query.clusterLemmes === '')
        return (
          <div>
            <h1></h1>
            <h1></h1>
            <h1>Veuillez choisir des entites ou lemmes</h1>
          </div>
        );
      return (
        <div>
          <h1>Thématique</h1>
          {
            isAdmin == true &&
            <Fragment>
              {
                (this.state.status == "done" || this.state.status == "existing") &&
                <div className={classes.fillerReporting}>
                  <h3>Reporting généré le {moment(dateReporting).format('DD/MM HH:mm')}</h3>
                  <a target="_blank"  className={classes.ddl} href={config.backEndServer + '/reporting/' + reportingUrl} download>
                    <Fab color="primary" aria-label="Download" className={classes.fab}>
                      <DownloadIcon />
                    </Fab>
                  </a>
                  <h3></h3>
                  <Fab color="primary" aria-label="Replay" className={classes.fab} onClick={() => this.getReporting()}>
                    <ReplayIcon />
                  </Fab>
                </div>
              }
              {
                this.state.status == "none" &&
                <div>
                  <Button
                    className={classes.reporting}
                    id="reporting"
                    variant="contained"
                    color="primary"
                    onClick={() => this.getReporting()}
                  >
                    Générer le reporting
                  </Button>
                </div>
              }
              {
                this.state.status == "processing" &&
                <div className={classes.fillerReporting} >
                  <h3>Progression en cours, lancé le {moment(dateReporting).format('DD/MM HH:mm')}</h3>
                </div>
              }
            </Fragment>
          }
          <Paper style={{width: 500, margin: '0 auto', marginBottom: 25}}>
            <Tabs
              value={value}
              onChange={this.handleChange}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab id="resume" label="résumé" />
              <Tab id="editeur" label="éditeur" />
              <Tab id="classement" label="classement" />
            </Tabs>
          </Paper>
          <div className="">
            {openTab}
          </div>
        </div>
      )
    };
}

export default withStyles(styles)(Theme);
