import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { replaceCharacters } from '../utils.js';

const styles = {
  tag: {
    display: 'inline-block',
    backgroundColor: 'rgb(230, 230, 230)',
    minWidth: 0,
    boxSizing: 'border-box',
    borderRadius: 2,
    margin: 2,
    fontSize: 13,
    paddingLeft: 5,
    paddingRight: 5,
    color: 'rgb(82, 81, 110)'
  }
};

function TagList(props) {
  const { classes, array } = props;

  if (!array)
    return null;
  if (props.clusterLink)
    return (
      <div className={classes.container}>
        { array.map((t, index) => <a href={`/dashboard/couverture/${t}`}><div key={index} className={classes.tag}>{replaceCharacters(t)}</div></a>) }
      </div>
    );
  return (
    <div className={classes.container}>
      { array.map((t, index) => <div key={index} className={classes.tag}>{replaceCharacters(t)}</div>) }
    </div>
  );
}

TagList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TagList);
