import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DesktopIcon from '@material-ui/icons/DesktopWindows';
import MobileIcon from '@material-ui/icons/Smartphone';

import { splitNumbers } from '../utils';

const styles = {
  card: {
    width: 'auto',
    maxWidth: 275,
    margin: 5,
    display: 'inline-block'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
    textAlign: 'center',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-all',
    wordBreak: 'break-word',
    hyphens: 'auto'
  },
  pos: {
    marginBottom: 12,
  },
  info: {
    textAlign: 'center',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-all',
    wordBreak: 'break-word',
    hyphens: 'auto'
  },
  legend: {
    fontSize: 12,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
};

function Datapoint(props) {
  const { classes } = props;
  const bull = <span className={classes.bullet}>•</span>;
  let footer = '';
  if (props.footer) {
    if (props.footer.includes('desktop'))
      footer =  <DesktopIcon />
    else if (props.footer.includes('mobile'))
      footer = <MobileIcon />
    }

  if (props.info === null)
    return null;
  return (
    <Card className={`${props.color ?  props.color + ' ' : ''}${classes.card}`}>
      <a href={props.link} target="_blank">
        <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            {props.title}
          </Typography>
          <Typography className={classes.info} variant="h5" component="h2">
            {splitNumbers(props.info)}
          </Typography>
          <CardActions className={classes.actions}>
            {footer}
            <div className={classes.legend} color="textSecondary">
              {props.subtitle}
            </div>
          </CardActions>
        </CardContent>
      </a>
    </Card>
  );
}

Datapoint.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Datapoint);
