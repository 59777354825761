import React, { Component, Fragment } from 'react';
import axios from 'axios';
import config from '../config';

import PropTypes from 'prop-types';
import ApiData from './sub_components/ApiData';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import SitemapCard from './sub_components/SitemapCard';

const styles = theme => ({
  filler: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 400
  },
  centeredGrid: {
    display: 'grid',
    justifyContent: 'center',
    flexWrap: 'nowrap'
  }
});

class SitemapsStates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdmin: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).isAdmin : null,
      render: 'false',
      sitemaps: [],
    }
  };

  getData = () => {
    const query = this.props.query;
    axios.post(`${config.backEndServer}/api/sitemap`, query
      , {withCredentials: true, timeout: 60000 })
      .then((res) => {
        this.setState({ render: 'true', sitemaps: res.data });
      })
      .catch(err => {
        this.setState({ render: 'timeout'});
      });
  };

  componentDidMount = () => {
    this.getData();
  }

  liste = (sitemaps) => {
    var liste = [];
    for (var i = 0; i < sitemaps.length; i++) {
      liste.push(<SitemapCard key={"liste" + sitemaps[i]._id} sitemap={sitemaps[i]} />);
    }
    return liste;
  }

  render = () => {
    const { classes } = this.props;
    if (this.state.render === 'true') {
      return (
        <div className={classes.centeredGrid} >
          {
            this.props.displayDaily ?
              <Fragment>
                <h1>Aggrégation du jour</h1>
                <ApiData {...this.props} />
              </Fragment>
            : null
          }
          <h3>Suivi des Sitemaps Clients</h3>
            {this.liste(this.state.sitemaps.filter(s => s.sitemaps.some(c => c.priority === 'high')))}
          <h3>Autres Sitemaps</h3>
            {this.liste(this.state.sitemaps.filter(s => !s.sitemaps.some(c => c.priority === 'high')))}
        </div>
      );
    } else if (this.state.render === 'timeout') {
      return (
        <div className={classes.filler} >
          <h3>Trop de résultats, délais de chargement expiré. Veuillez sélectionner des paramètres de filtre plus spécifiques.</h3>
        </div>
      )
    } else if (this.state.isAdmin) {
      return (
        <div className={classes.filler} >
          <CircularProgress className={classes.progress} />
        </div>
      )
    } else {
      return (
        <div className={classes.filler} >
          {"Vous n'avez pas les droits pour afficher cette page"}
        </div>
      )
    }
  };
}


SitemapsStates.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SitemapsStates);
