import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import config from '../config';
import { CSVLink, CSVDownload } from "react-csv";

import MUIDataTable from 'mui-datatables';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import ReactChartkick, { AreaChart, ColumnChart, LineChart } from 'react-chartkick';
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting'
import HC_offlineExporting from 'highcharts/modules/offline-exporting'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Datapoint from './sub_components/Datapoint';
import DataEntry from './sub_components/DataEntry';
import Fab from '@material-ui/core/Fab';
import DownloadIcon from '@material-ui/icons/SaveAlt';

HC_exporting(Highcharts);
HC_offlineExporting(Highcharts);
ReactChartkick.addAdapter(Highcharts)

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  dense: {
    marginTop: 16,
  },
  fab: {
    margin: theme.spacing.unit,
  },
  filler: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 400
  },
  dataContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  warningTimeframe: {
    position: 'absolute',
    height: 90,
    width: 100,
    marginTop: -30,
    right: 90,
    padding: 10,
    opacity: 0.8,
    zIndex: 10000,
    fontSize: 11,
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: 'rgb(244, 155, 66, 0.8)',
    textAlign: 'center'
  },
  ddl: {
    position: 'absolute',
    right: 30
  },
  fab: {
    margin: theme.spacing.unit,
    width: 42,
    height: 42,
    backgroundColor: '#57AAD9',
    marginTop: 20
  }
});

const getScore = (exposed, field, groupedDay) => {
  const entries = {};
  exposed.jours.forEach((day, index) => {
    for (let hour in exposed.sitemap[index][field]) {
      // if (exposed.sitemap[index][field][hour] !== 0)
      if (!groupedDay)
        entries[day + ' ' + hour + ':00'] = exposed.sitemap[index][field][hour];
      else {
        if (entries[day + ' 00:00'])
          entries[day + ' 00:00'] += exposed.sitemap[index][field][hour];
        else
          entries[day + ' 00:00'] = exposed.sitemap[index][field][hour];
      }
    }
  })
  return entries;
};

const getScoreExpose = (exposed, groupedDay) => {
  const entries = {};
  const exposedLength = exposed.jours.length;
  if (exposedLength > 1) {
    exposed.jours.forEach((day, index) => {
      for (let hour in exposed.articles_exposes_heures[index]) {
        if (!groupedDay)
          entries[day + ' ' + hour + ':00'] = exposed.articles_exposes_heures[index][hour];
        else {
          entries[day + ' 00:00'] = exposed.articles_exposes_array[index];
        }
      }
    })
    return entries;
  } else {
    exposed.jours.forEach((day, index) => {
      if (!groupedDay) {
        for (let b = 0; b < 24; b++) {
          if (b < 10) {
            entries[`0${b}:00`] = 0;
          } else {
            entries[`${b}:00`] = 0;
          }
        }
      }
      for (let hour in exposed.articles_exposes_heures[index]) {
        // if (exposed.articles_exposes_heures[index][hour] !== 0)
        if (!groupedDay)
          entries[hour + ':00'] = exposed.articles_exposes_heures[index][hour];
        else {
          entries[day + '00:00'] = exposed.articles_exposes_array[index];
        }
      }
    })
    return entries;
  }
};

const getAveragePerDay = (exposed, groupedDay, averageMonth) => {
  if (groupedDay) {
    const entries = {};
    for (let day in averageMonth) {
      entries[day + ' 00:00'] = averageMonth[day];
    }
    return entries;
  } else {
    return exposed;
  }
};

const passedDays = (day) => {
  let dates = [];
  const dayWeek = moment().format('dddd');
  let today = day;
  let back = 4;
  while (back > 0) {
    today = moment(today).subtract(7, 'days').format("YYYY-MM-DD");
    dates.push(today);
    back--;
  }
  return dates;
};

class ArticlesGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      render: false,
      exposed: [],
      groupedDay: false
    }
  };

  componentDidUpdate = (prevProps) => {
    if ((prevProps.query.fromDate !== this.props.query.fromDate) || (prevProps.query.toDate !== this.props.query.toDate))
      this.getData();
  }

  getData = () => {
    const query = this.props.changeEditeur ? this.props.query : { ...this.props.query, ...{ editeur: 'all' } };
    axios.post(`${config.backEndServer}/api/editeur/exposition`, this.props.query
      , { withCredentials: true })
      .then((res) => {
        this.setState({ render: true, exposed: res.data[0] });
        this.getAverage();
      })
  };

  getAverage = async () => {
    if (!this.state.exposed) return;
    let result = [];
    let sum = [];
    let average = [];
    let averageMonth = [];
    let unique = [...new Set(this.state.exposed.jours)];
    if (unique.length > 8) return;
    //Pour chaque jour, on boucle
    for(const day of unique ) {
      result[day] = [];
      let promises = [];
      const dates = passedDays(day);
      // Pour chaque 4 précédents même jour, on récupère l'exposition
      dates.forEach((date) => {
        let oldData = Object.assign({}, this.props.query);
        oldData.fromDate = date + 'T00:00';
        oldData.toDate = date + 'T23:59';
        promises.push(axios.post(`${config.backEndServer}/api/editeur/exposition`, oldData, { withCredentials: true }));
      });
      // On stocke les 4 précédentes exposition dans un tableau avec comme clé, la date du jour à afficher
      await Promise.all(promises).then((res) => {
        res.forEach(r => {
          result[day].push(r.data[0]);
        });
      });
      // on somme les expositions pour chaque heure et on les stocke dans un tableau avec comme clé la date du jour à afficher
      sum[day] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      for (let i = 0; i < result[day].length; i++) {
        if (result[day][i] == undefined) result[day][i] = { articles_exposes_heures: [] };

        for (let a = 0; a < result[day][i].articles_exposes_heures.length; a++) {
          let articleExpo = result[day][i].articles_exposes_heures[a];
          sum[day] = [result[day][i].articles_exposes_heures[a]].reduce((accumulator, currentValue) => {
            for (let c in currentValue) {
              accumulator[parseInt(c)] += currentValue[c];
            }
            return accumulator;
          }, sum[day]);
        }
      };

      let grouped = 0;
      for (let i = 0; i < result[day].length; i++) {
        if (result[day][i] == undefined) result[day][i] = { articles_exposes: 0 };
        grouped += result[day][i].articles_exposes;
      };
      grouped = Math.round(grouped / result[day].length);
      averageMonth[day] = grouped;
      // on calcule la moyenne du mois pour le jour et on stocke dans un tableau avec comme clé le jour
      average[day] = [];
      sum[day].forEach(s => {
        average[day].push(s / result[day].length);
      });
    }
    let averageArticle = {};
    unique.forEach((day, index) => {
      for (let b = 0; b < 24; b++) {
        if (b < 10) {
          if (unique.length == 1) {
            averageArticle[`0${b}:00`] = 0;
          } else {
            averageArticle[`${day} 0${b}:00`] = 0;
          }
        } else {
          if (unique.length == 1) {
            averageArticle[`${b}:00`] = 0;
          } else {
            averageArticle[`${day} ${b}:00`] = 0;
          }
        }
      }
      for (let hour in this.state.exposed.articles_exposes_heures[index]) {
        if (hour < 10) {
          hour = hour.slice(1);
          if (unique.length == 1) {
            averageArticle[`0${hour}:00`] = Math.round(average[day][hour]);
          } else {
            averageArticle[`${day} 0${hour}:00`] = Math.round(average[day][hour]);
          }
        } else {
          if (unique.length == 1) {
            averageArticle[`${hour}:00`] = Math.round(average[day][hour]);
          } else {
            averageArticle[`${day} ${hour}:00`] = Math.round(average[day][hour]);
          }
        }
      }
    });
    this.setState({ averageArticle, averageMonth });
  };

  componentDidMount = () => {
    this.getData();
  }

  render = () => {
    const { classes, isAdmin, onlySitemap, query } = this.props;
    const { render, exposed, groupedDay, averageArticle, averageMonth } = this.state;

    if (!exposed)
      return null;
    if (render) {
      const sitemapSeries = [];

      let unique = [...new Set(exposed.jours)];
      let expositionSeries = [];
      expositionSeries.push({ "name": "Articles exposés aujourd'hui", "data": getScoreExpose(exposed, groupedDay) });
      if (unique.length <= 8) {
        expositionSeries.push({ "name": "Moyenne du mois", "data": getAveragePerDay(averageArticle, groupedDay, averageMonth) });
      }
      sitemapSeries.push({ "name": "Nouveaux Articles", "data": getScore(exposed, 'articles_heures', groupedDay) });
      sitemapSeries.push({ "name": "Articles Modifies", "data": getScore(exposed, 'modifs_articles_heures', groupedDay) });

      const csvContent = [['date', 'Nouveaux Articles', 'Articles Modifies']];
      for (let i in sitemapSeries[0].data) {
        csvContent.push([i, sitemapSeries[0].data[i], sitemapSeries[1].data[i]])
      };

      return (
        <div className="article-container">
          <div>
            <Paper id="warning-switch" className={classes.warningTimeframe}>
              <div>
                Groupement par heure/jour
              </div>
              <Switch
                checked={groupedDay}
                onChange={() => { this.setState({ groupedDay: !groupedDay }) }}
                color="primary"
              />
            </Paper>
          </div>
          <div className="chart-container">
            {isAdmin ?
              <CSVLink data={csvContent} filename={"sitemaps.csv"}>
                <a className={classes.ddl} download>
                  <Fab color="primary" aria-label="Download" className={classes.fab}>
                    <DownloadIcon />
                  </Fab>
                </a>
              </CSVLink>
            : null }
            <div id="exposition-chart">
              <h3>{onlySitemap === true ? "Articles Publiés par Heure" : "Articles Exposés par Heure"}</h3>
              {
                onlySitemap === true ? null
                : <LineChart data={expositionSeries} />
              }
            </div>
            { exposed.sitemap[0].articles_heures ?
              <div id="sitemaps-chart">
                <h3>Sitemap</h3>
                <LineChart data={sitemapSeries} />
              </div>
              : null }
          </div>
        </div>
      );
    } else {
      return (
        <div className={classes.filler} >
          <CircularProgress className={classes.progress} />
        </div>
      )
    }
  };
}


ArticlesGraph.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ArticlesGraph);
