import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import config from '../../config';

import MUIDataTable from 'mui-datatables';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ReactChartkick, { AreaChart, ColumnChart, LineChart } from 'react-chartkick';
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting'
import HC_offlineExporting from 'highcharts/modules/offline-exporting'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import TagList from './TagList';
import ClusterList from '../ClusterList';
import Word from './Word';

import { splitNumbers, getGraphArray, customColor, getTitle } from '../utils';

HC_exporting(Highcharts);
HC_offlineExporting(Highcharts);
ReactChartkick.addAdapter(Highcharts)

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '-webkit-fill-available',
    backgroundColor: 'white',
    marginTop: 'inherit'
  },
  dense: {
    marginTop: 16,
  },
  fab: {
    margin: theme.spacing.unit,
  },
  filler: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 400
  },
  dataContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

const categoriesOptions = [
  { value: 'all', label: 'Toutes' },
  { value: 'homepage', label: 'A la Une' },
  { value: 'france', label: 'France' },
  { value: 'international', label: 'International'},
  { value: 'économie', label: 'Economie' },
  { value: 'science_high-tech', label: 'Science'},
  { value: 'culture', label: 'Culture' },
  { value: 'sports', label: 'Sport' },
  { value: 'santé', label: 'Sante' }
];

class ArticlesGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      render: false,
      theme: {},
      clusters: [],
      cluster: ""
    }
  };

  getData = () => {
    axios.post(`${config.backEndServer}/api/cluster/theme`, {...this.props.query }
    , {withCredentials: true, timeout: 180000})
      .then((res) => {
        this.setState({ render: true, theme: res.data});
      });
  };

  componentDidMount = () => {
    this.getData();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.clusterCategory !== this.state.clusterCategory)
      this.getData();
  }

  handleClusters = (clusters) => {
    this.setState({ clusters: clusters });
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
};

  render = () => {
    const { classes, isAdmin, query, newQuery, queryDay } = this.props;
    const { render, theme, clusters, cluster } = this.state;


    if (!theme)
      return null;
    if (render) {
      let days = [], scores = [], editeurScores = [], editeurDays = [], clusterScores = {};

      const optionsColumn = {
        exporting: {
           fallbackToExportServer: false
         }
      };

      return (
        <Fragment>
          <div id="entiteLemmes">
            <h3>Entités</h3>
            <TagList array={theme.entities} />
            <h3>Lemmes</h3>
            <TagList array={theme.lemmes} />
          </div>
          <div className="article-container">
            <div className="chart-container">
              <h3>Evolution du score du thème</h3>
              { /* <span>
                <TextField
                  id="cluster"
                  select
                  label="Sélectionner un cluster"
                  className={classes.textField}
                  value={this.state.cluster}
                  size="small"
                  onChange={this.handleChange('cluster')}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin="normal"
                >
                    <MenuItem value=""> Aucun </MenuItem>
                  {clusters.sort((a, b) => new Date(a.first_view) - new Date(b.first_view)).map(cluster => (
                    <MenuItem key={cluster.titre} value={cluster.titre}>
                      {moment(cluster.first_view).format('DD/MM/YYYY HH:mm')} - {getTitle(cluster)}
                    </MenuItem>
                  ))}
                </TextField>
              </span> */}
              <div id="scoreTheme">
                <AreaChart colors={Object.values(customColor)} stacked={true} data={theme.dataChart} library={optionsColumn} />
              </div>
            </div>
            {
              query.editeur && query.editeur !== 'all' ?
              <div className="chart-container">
                <h3>Evolution du score editeur sur le thème</h3>
                <div id="scoreEditeur">
                  <AreaChart colors={Object.values(customColor)} stacked={true} data={theme.editeurDataChart} library={optionsColumn} />
                </div>
              </div>
              : null
            }
          </div>
          <ClusterList {...this.props} coverage={true} noSTatus={true} handleClusters={this.handleClusters} />
        </Fragment>
      );
    } else {
      return (
        <div className={classes.filler} >
          <CircularProgress className={classes.progress} />
        </div>
      )
    }
  };
}


ArticlesGraph.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ArticlesGraph);
