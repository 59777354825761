import config from '../config';
import { io } from "socket.io-client";


const socket = io(config.backEndServer, {
  transports: ['websocket'],
});

socket.subDailyReportStatus = (cb) => {
  socket.on('dailyReportStatus', (status, reportingUrl, dateReporting) => cb(null, status, reportingUrl, dateReporting));
}

socket.subThemeReportStatus = (cb) => {
  socket.on('themeReportStatus', (status, reportingUrl, dateReporting) => cb(null, status, reportingUrl, dateReporting));
}

export { socket };
