import React, { Component } from 'react';
import ButtonMU from '@material-ui/core/Button';
import { Link, Redirect } from 'react-router-dom'
import axios from 'axios'
import config from './config'
import './css/Main.css'
import { Input } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { withAlert } from 'react-alert';

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputs: {
                password:'',
                email:''
            },
            successfullLogin: false,
            errors: {}
        }
    }

    // add the inputs values in theire [name] in state
    handleInput = (e) => {
        let target = e.target;
        let name = target.name;
        this.cleanErrors(name); // on each type clean the errors to remove the "error" mark on each input
        let value = target.value;
        const {inputs} = this.state;
        inputs[name] = value;
        this.setState({inputs}, () => config.alertD(this.state.inputs[name])); // add the input in its proper property in the <inputs> object in state
    }


    // clean the errors from the <errors> object in the state
    cleanErrors = (name) => {
        const {errors} = this.state;
        errors[name] = false;
        errors.errStatus = '';
        if (name == 'email') {
        }
        this.setState({errors});
    }

    // simple login
    Login = (e) => {
        e.preventDefault()
        config.alertD('this.state.inputs.email', this.state.inputs.email)
        config.alertD('this.state.inputs.password', this.state.inputs.password)
        if (this.state.inputs.email && this.state.inputs.password) {
            axios.post(`${config.backEndServer}/login`, {
                email:this.state.inputs.email,
                password:this.state.inputs.password
            },
            {withCredentials: true})
            .then((res) => {
                config.alertD('this user res on login ', res.data)
                if (res.status === 200 && res.data) {
                localStorage.setItem('user', JSON.stringify({
                  email: res.data.email,
                  editeur: res.data.editeur,
                  isAdmin: res.data.isAdmin,
                  role: res.data.role
                }));
                  localStorage.removeItem('query');
                  this.setState({ successfullLogin: true });
                }
            })
            .catch(e => {
                console.log(e);
                // if there is n error - catching the response status to handle the error (usualy 401 for wrong data)
                let errStatus = e.response.status;
                const {errors} = this.state;
                errors.errStatus = errStatus.toString()
                this.setState({errors})
            })
        }
        config.alertD('couldnt do it')
    }


    // protectedRoute = () => {
    //     axios.post(`${config.backEndServer}/protected`, {
    //         token:this.state.token // passing the token to access the route
    //     })
    //     .then(() => {
    //         Swal('yey', 'awesome! I see you signed in(:', 'success') // show if the token is right
    //     })
    //     .catch((e) => {
    //         if (e) {
    //             Swal('oops' , 'login to see it(:' , 'error') // show if token is wrong or there is no token passed
    //         }
    //     })
    // }




  render() {
    const successfullLogin = this.state.successfullLogin

    if (successfullLogin)
      return (
        <Redirect to='/dashboard' />
      );

    return (
        <div className=''>

                <div className='admin-signup-section container-signup container'>
                  <div>
                      <img src="/logo-eperf-consulting.png" width="160" />
                      <div>

                          <div>
                              <Input className='admin-form' onChange={e => this.handleInput(e)} value={this.state.inputs.email} name="email"  type="text" placeholder="email"/>
                              <Input error={this.state.errors.password ? true : false}  className='admin-form' onChange={e => this.handleInput(e)} value={this.state.inputs.password} name="password"  type="password"  placeholder="password" />
                          </div>

                          {this.state.errors.errStatus == '401' ? <label style={{color:'red'}}>Le mot de passe ou l'email sont invalides</label> : null}

                          <br/>

                          <div>
                              <ButtonMU className='btn-signup' variant="contained" color="primary" onClick={this.Login}>Login</ButtonMU>
                          </div>
                      </div>
                  </div>
            </div>

        </div>
    );
  }
}

export default withAlert(Login);
