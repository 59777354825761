import React, { Component } from 'react';
import ButtonMU from '@material-ui/core/Button';
import { Link, Redirect } from 'react-router-dom'
import axios from 'axios'
import config from './config'
import './css/Main.css'
import { Input } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import RefreshIcon from '@material-ui/icons/Refresh';


class ErrorPage extends Component {
  render() {
    return (
        <div className=''>
          <div className='admin-signup-section container-signup container'>
            <div>
                <img src="/logo-eperf-consulting.png" width="160" />
                <div>
                    <div>
                      <h1>Le dashboard est actuellement en maintenance, veuillez réessayer ultérieurement</h1>
                    </div>
                    <br/>
                    <div>
                        <a href="/dashboard"><ButtonMU className='btn-signup' variant="contained" color="primary"><RefreshIcon /></ButtonMU></a>
                    </div>
                </div>
            </div>
          </div>
        </div>
    );
  }
}

export default ErrorPage;
