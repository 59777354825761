import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import ApplenewsArticles from './sub_components/ApplenewsArticles';
import ApplenewsEditeurs from './sub_components/ApplenewsEditeurs';

class Applenews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0
        }
    };

    handleChange = (event, newValue) => {
        this.setState({ value: newValue });
    };

    render = () => {
        let actualValue = this.state.value;
        let openTab;

        if (actualValue === 0) {
            openTab = (
                <div>
                    <ApplenewsArticles query={{ ...this.props.query }} />
                </div>);
        } else if (actualValue === 1) {
            openTab = (
                <div>
                    <ApplenewsEditeurs newQuery={this.props.newQuery} query={{ ...this.props.query }} />
                </div>
            );
        }

        return (
            <div>
                <h1>AppleNews</h1>
                <Paper style={{ width: 320, margin: '0 auto', marginBottom: "4em" }}>
                    <Tabs
                        value={this.state.value}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab label="Articles vus" />
                        <Tab label="Editeurs exposés" />
                    </Tabs>
                </Paper>
                {openTab}
            </div>
        )
    }
}

export default Applenews;