import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Info from '@material-ui/icons/Info';
import Edit from '@material-ui/icons/Edit'
import Visibility from '@material-ui/icons/Visibility'
import moment from 'moment'

import ActuList from './ActuList.js'
import { splitNumbers, getTitle, favicon, getOffset, getLen } from '../utils'


const styles = theme => ({
  icon: {
    width: 16,
    height: 16,
    marginRight: 2,
  },
  lineContainer: {
    position: 'relative',
    height: 44,
    marginBottom: 10,
    cursor: 'pointer',
  },
  eventContainer: {
    top: 5,
    height: 'inherit',
    position: 'absolute',
    paddingLeft: 5,
    paddingRight: 5,
    backgroundColor: '#57AAD9',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  selectedEventContainer: {
    top: 5,
    height: 'inherit',
    position: 'absolute',
    paddingLeft: 5,
    paddingRight: 5,
    backgroundColor: '#57AAD9',
  },
  selectedContainer: {
    width: 980,
    marginLeft: -300,
    marginTop: 25,
    marginBottom: 25
  },
  selectedArticle: {
    height: 'inherit',
    position: 'absolute',
    paddingLeft: 5,
    paddingRight: 5,
    backgroundColor: '#57AAD9',
    color: 'white'
  },
  lineTitle: {
    padding: 9,
    borderWidth: '1px 1px 1px 1px',
    borderColor: 'white',
    borderStyle: 'solid',
    backgroundColor: '#57AAD9',
    color: 'white',
    position: 'absolute',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontWeight: 700,
    width: 300,
    fontSize: '1.1em',
    height: 54,
  },
  timeSpent: {
    position: 'absolute',
    color: '#57AAD9',
    left: -340,
    top: 'calc(50% - 5px)'
  }
});

function ActuLineArticle(props) {
  const { classes, article, onClick, selected, rangeStart, rangeEnd, cluster, dataToGet } = props;


  const htmlArticle = []

  const firstOffset = article[dataToGet][0].start

  for (let i = 0; i < article[dataToGet].length; i++) {
    let firstDate = moment(article[dataToGet][i].start, 'YYYY-MM-DD HH:mm:ss')
    if (firstDate < rangeStart)
      firstDate = rangeStart
    let lastDate = moment(article[dataToGet][i].end, 'YYYY-MM-DD HH:mm:ss')
    if (lastDate > rangeEnd)
      lastDate = rangeEnd
    const offsetX = getOffset(firstDate, rangeStart, rangeEnd)
    const length = getOffset(lastDate, rangeStart, rangeEnd) - offsetX;

    htmlArticle.push(
      <Card key={i} className={selected ? classes.selectedEventContainer : classes.eventContainer} style={{left: offsetX, width: length}}>
        {cluster && <img className={classes.icon} src={favicon[article.editeur]} /> }
      </Card>
    )
  }

  const transformHours = (date) => {
    let showHours = "";
    let hours = Math.floor(date / 60);
    let minutes = (date % 60);
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    showHours = `${hours}:${minutes}`
    return showHours;
}

  let timeTotalPerArticle = 0;
  for (let k = 0; k < article[dataToGet].length; k++) {
    let start = moment(article[dataToGet][k].start, 'YYYY-MM-DD HH:mm:ss');
    if (start < rangeStart) start = rangeStart
    let end = moment(article[dataToGet][k].end, 'YYYY-MM-DD HH:mm:ss');
    if (end > rangeEnd) end = rangeEnd
    let diff = moment(end).diff(moment(start), 'minutes')
    if (diff < 0) diff = 0 
    timeTotalPerArticle += diff;
  }

  return (
    <Fragment>

      <div className={classes.lineContainer} onClick={() => onClick(article)}>
        <div className={classes.timeSpent} >
          { transformHours(timeTotalPerArticle) }
        </div>
        <div className={classes.lineTitle} style={{marginLeft: -300}}>
          {article.titre}
        </div>
        {htmlArticle}

      </div>
      { selected &&
        <div className={classes.selectedContainer} >
          <ActuList articles={[article]} />
        </div>
      }
    </Fragment>
  );
}

ActuLineArticle.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ActuLineArticle);
