import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import config from '../config';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';

import MUIDataTable from 'mui-datatables';

import Cookies from 'js-cookie';

import { saveColumnSetup } from './utils';

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#57AAD9',
    color: theme.palette.common.white,
    fontSize: 16,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  filler: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 400
  }
});

class EditeursList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      render: false,
      editeurs: [],
      order: 'desc',
      orderBy: 'lastModified',
    }
  };

  getData = () => {
    axios.post(`${config.backEndServer}/api/editeur`, this.props.query, { withCredentials: true })
      .then((res) => {
        this.setState({ render: true, editeurs: res.data || [] });
      })
      .finally(() => {
        const queryData = JSON.parse(localStorage.getItem('query'));
        const userData = JSON.parse(localStorage.getItem('user'));

        this.state.editeurs.forEach((editeur, index) => {

          let allTr = document.querySelectorAll("tr");
          let getTBody = document.querySelector("tbody");

          if (userData.editeur == editeur[1] || queryData.query.editeur == editeur[1]) {
            allTr.forEach(elem => {
              if (userData.editeur != "all" && queryData.query.editeur == editeur[1] && userData.editeur != queryData.query.editeur) return;
              if (elem.innerText.includes(editeur[1])) {
                elem.style.backgroundColor = "#57AAD9";
                elem.addEventListener("mouseenter", (event) => {
                  event.target.style.backgroundColor = "rgba(87, 170, 217,0.5)";
                  setTimeout(function () {
                    event.target.style.backgroundColor = "#57AAD9";
                  }, 400);
                }, false);
                if (elem.querySelectorAll("td")[1].innerText > 10) {
                  getTBody.prepend(elem);
                }
              };
            });
          }
        })
      });
  };

  // handleRequestSort = (event, property) => {
  //   const orderBy = property;
  //   let order = 'desc';
  //
  //   if (this.state.orderBy === property && this.state.order === 'desc') {
  //     order = 'asc';
  //   }
  //
  //   this.setState({ order, orderBy });
  // };

  componentDidMount = () => {
    this.getData();
  }

  addRanking = () => {

  }

  render = () => {
    const { classes, isAdmin, optionsTable } = this.props;

    const editeurSettings = JSON.parse(localStorage.getItem('editeurSettings'));

    const columns = [
      { name: '#', options: {display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (tableMeta.rowIndex + 1);
        },
        sort: false
      } },
      { name: 'Groupe Editeur', options: { display: editeurSettings['Groupe Editeur'] } },
      { name: 'Articles Exposes', options: { display: editeurSettings['Articles'] } },
      { name: 'Nouveaux Articles', options: { display: editeurSettings['Articles'] } },
      { name: 'Articles Applenews', options: { display: editeurSettings['Articles Applenews'] } },
      { name: 'Score', options: { display: editeurSettings['Score'],  sortDirection: 'desc' } },
      { name: 'Score Flipboard', options: { display: editeurSettings['Score Flipboard'] } },
      { name: 'Score Applenews', options: { display: editeurSettings['Score Flipboard'] } },
      { name: 'G.News A la une', options: { display: editeurSettings['A la une']} },
      { name: 'G.News Sections', options: { display: editeurSettings['Sections']} },
      { name: 'G.News Topic', options: { display: editeurSettings['Topic']} },
      { name: 'Homepage', options: { display: editeurSettings['Homepage']} },
      { name: 'France', options: { display: editeurSettings['France']} },
      { name: 'International', options: { display: editeurSettings['International']} },
      { name: 'Economie', options: { display: editeurSettings['Economie']} },
      { name: 'Sciences et Technos', options: { display: editeurSettings['Sciences et Technos']} },
      { name: 'Divertissement', options: { display: editeurSettings['Divertissement']} },
      { name: 'Sports', options: { display: editeurSettings['Sports'] } },
      { name: 'Sante', options: { display: editeurSettings['Sante'] } }
    ];

    let options = {
      filter: false,
      selectableRows: false,
      responsive: "stacked",
      rowsPerPage: 50,
      rowsPerPageOptions: [10, 50, 100],
      onRowClick: (rowData) => {
          delete this.props.query.limit;
          this.props.newQuery({...this.props.query, ...{ editeur: rowData[1] } },
            () => { window.open(`${window.location.origin}/dashboard/articles`) });
        },
      onColumnSortChange: (changedColumn, direction) => {
        console.log(changedColumn, direction)
      },
      onColumnViewChange: (changedColumn, action) => saveColumnSetup('editeurSettings', changedColumn, action)
    };
    options = {... options, ...optionsTable};

    if (this.state.render) {
      return (
        <MUIDataTable
          title={"Editeurs"}
          data={this.state.editeurs}
          columns={columns}
          options={options}
        />
      );
    } else {
      return (
        <div className={classes.filler} >
          <CircularProgress className={classes.progress} />
        </div>
      );
    }
  };
}

EditeursList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EditeursList);
