import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import config from '../config';
import { withStyles } from '@material-ui/core/styles';
import Swal from "sweetalert2";

// import TimeGraph from './sub_components/TimeGraph';
import ReactChartkick, { LineChart, AreaChart, ColumnChart } from 'react-chartkick';
import Highcharts from 'highcharts'
import HC_exporting from 'highcharts/modules/exporting'
import HC_offlineExporting from 'highcharts/modules/offline-exporting'
import Datapoint from './sub_components/Datapoint';
import DataEntry from './sub_components/DataEntry';
import TagList from './sub_components/TagList';
import ClusterArticle from './sub_components/ClusterArticle';

import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import ReportIcon from '@material-ui/icons/Report';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import { getGraphQuarter, getQuarterColors, customColor, getSocialHour, socialColor, splitNumbers, replaceCharacters } from './utils'

HC_exporting(Highcharts);
HC_offlineExporting(Highcharts);
ReactChartkick.addAdapter(Highcharts);
momentDurationFormatSetup(moment);

const decode = (string) => {
  if (!string)
    return null;
  let finalString = '';
  try { finalString = decodeURIComponent(escape(string)) }
  catch(error) { finalString = string }
  return finalString;
}

const styles = {
  sectionTitle: {
    marginTop: '80px !important'
  },
  divider: {
    marginBottom: 15,
    marginTop: 15
  },
  categoryTitle: {
    textAlign: 'center',
    margin: 5
  },
  score: {
    fontSize: '1.6rem',
    opacity: 0.8
  },
  tag: {
    display: 'inline-block',
    backgroundColor: 'rgb(230, 230, 230)',
    minWidth: 0,
    boxSizing: 'border-box',
    borderRadius: 2,
    margin: 2,
    fontSize: 13,
    paddingLeft: 5,
    paddingRight: 5,
    color: 'rgb(82, 81, 110)',
    cursor: 'pointer'
  },
  warningTimeframe: {
    position: 'fixed',
    height: 90,
    width: 100,
    top: 200,
    right: 20,
    padding: 10,
    opacity: 0.8,
    zIndex: 10000,
    fontSize: 11,
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: 'rgb(244, 155, 66, 0.8)',
    textAlign: 'center'
  },
  reportErrors: {
    position: 'fixed',
    width: 100,
    top: 300,
    right: 20,
    padding: 10,
    cursor: 'pointer',
    opacity: 0.8,
    zIndex: 10000,
    fontSize: 11,
    fontWeight: 700,
    backgroundColor: 'rgba(244, 82, 66, 0.68)',
    textAlign: 'center',
    '& button': {
      backgroundColor: 'transparent',
      border: 'none',
      width: '-webkit-fill-available',
    }
  }
};

const sourceScore = {
  score_google_desktop: 'Google Desktop',
  score_google_mobile_webview: 'Google Mobile',
  score_flipboard_mobile: 'Flipboard Mobile',
  score_applenews: 'Applenews'
};

const sourceColor = {
  score_google_desktop: 'desktop-color',
  score_google_mobile_webview: 'mobile-color',
  score_flipboard_mobile: 'flipboard-color',
  score_applenews: 'applenews-color'
};

const displayName = {
  "score_applenews": 'Applenews',
  "score_google_desktop_top": 'A la une',
  "score_google_desktop_cat": 'Sections',
  "score_google_desktop_topic": 'Topic',
  "score_google_mobile_webview_top": 'A la une',
  "score_google_mobile_webview_cat": 'Sections',
  "score_google_mobile_webview_topic": 'Topic'
}

const displayLifetime = (string) => {
  if (!string || string === 'None')
    return null;

  const regex = /[0-9]{1,2}-[0-9]{1,2}-([0-9]{1,2}) ([0-9]{1,2}):([0-9]{1,2}):([0-9]{1,2})/;
  const time = regex.exec(string);
  const diff = moment.duration(`${time[1]}.${time[2]}:${time[3]}:${time[4]}`);
  if (diff > 172800000)
    return moment.duration(diff).format("M [mois] d [jours] h [hrs] m [mins]")
  else
    return moment.duration(diff).format("M [mois] d [jour] h [hrs] m [mins]")
}

const DataCategory = (props) => {
  const { scores, category, classes } = props;

  return (
    <Grid item xs={4} className={sourceColor[category]}>
      <div className="data-entries-title">
        <Typography className={classes.categoryTitle} variant="h5" component="h2" gutterBottom>
          {`${sourceScore[category]} - `}
          <span className={classes.score} >{splitNumbers(scores[category])}</span>
        </Typography>
      </div>
      <div className="data-entries">
        {
          Object.keys(scores || {}).map(key => {
            if (displayName[key] && scores[key] && key.indexOf(category) > -1 && key !== category)
              return <DataEntry title={displayName[key]} info={scores[key]} />
            return null;
          })
        }
      </div>
    </Grid>
  );
}

class Article extends Component {
  constructor(props) {
    super(props);
    this.state = {
      article: null,
      fullTimeFrame: false,
      isOpen: false,
    }
  };

  componentDidMount = () => {
    let articleUrl = `${config.backEndServer}/api/article/${this.props.match.params.id}`
    if (this.props.match.params.url)
      articleUrl = `${config.backEndServer}/api/article/url/${this.props.match.params.url}`;
    axios.post(articleUrl,
      this.props.query,
      { withCredentials: true })
      .then((res) => {
        this.setState({ render: true, article: res.data });
      });
  }

  goToEditeur = () => {
      this.props.newQuery({...this.props.query, ...{ group: 'editeur', editeur: this.state.article.editeur } },
        () => { window.open(`${window.location.origin}/dashboard/articles`) });
  }

  goToCouverture = (newParams) => {
      this.props.newQuery({...this.props.query, ...newParams },
        () => { window.open(`${window.location.origin}/dashboard/couverture`) });
  }

  handleOpenOrClose = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleChange = () => event => {
    this.setState({[event.target.name]: event.target.value});
  }

  handleSubmit = (data) => () => {
    axios.post(`${config.backEndServer}/api/article/report`, { titleId: this.state.article._id, position : data.valueSelect, comment : data.commentInput },
    { withCredentials: true })
    .then((res) => {
      Swal.fire(
        'Ok',
        'Data ajouté !',
        'success'
      );
      this.setState({ isOpen: !this.state.isOpen })
    })
    .catch((err) => {
      Swal.fire(
        'Erreur',
        err.message,
        'error'
      )
    });
  }

  render = () => {
    if (this.state.render) {
      const { classes } = this.props;
      const { fullTimeFrame, article } = this.state;
      let { fromDate, toDate } = this.props.query;
      const isScoreQuarts = typeof article.scores_quarts !== 'undefined';

      if (Object.entries(article).length === 0) {
        return (
          <div>
            <h1 className={classes.sectionTitle}>Impossible d'afficher cet article</h1>
          </div>
        )
      }
      // UGLY
      if (article.titre.includes("Edition du soir")) {
        if (fromDate != article.first_exposition.slice(0, -8) + 'T00:00' && fromDate > article.first_exposition.slice(0, -9) + 'T00:00')
          fromDate = article.first_exposition.slice(0, -8) + '00:00';
        article.titre = article.titre.slice(0, -43);
      }
      if (fullTimeFrame === true) {
          if (!!(article.dateArticle))
            fromDate = article.dateArticle.slice(0, -5) + '00:00';
          else
            fromDate = article.first_view.slice(0, -5) + '00:00';
          toDate = article.lastModified;
      }

      // console.log(article);
      const { score, dataChart, scores } = getGraphQuarter(article.scores_quarts, fromDate, toDate);
      const socialChart = getSocialHour(article.social_shares_jours, fromDate, toDate);
      const clusters = article.clusterArticle;
      const optionsArea = {
      chart: {
        zoomType: 'x',
      },
      plotOptions: {
          area: {
              stacking: 'normal',
              lineColor: '#666666',
              lineWidth: 1,
              marker: {
                  lineWidth: 1,
                  lineColor: '#666666'
              }
          }
      },
      exporting: {
           fallbackToExportServer: false
       },
      };
      const optionsColumn = {
        chart: {
          zoomType: 'x',
        },
        exporting: {
             fallbackToExportServer: false
         },
      };
      const admin = JSON.parse(localStorage.getItem('user'));

      return (
        <div className="article-container">
          <h1 className={classes.sectionTitle}>{decode(article.titre)}</h1>
          <h5>{decode(article.description)}</h5>
          <h5><a target="_blank" href={article.url}>{article.url}</a></h5>
          <div>
            <Paper className={classes.warningTimeframe}>
              <div>
                Afficher toute la Durée de Vie
              </div>
              <Switch
                 checked={fullTimeFrame}
                 onChange={() => { this.setState({ fullTimeFrame: !fullTimeFrame}) } }
                 color="primary"
               />
           </Paper>
         </div>
         {admin.isAdmin &&
           <div onClick={() => this.handleOpenOrClose()} >
             <Paper className={classes.reportErrors}>
               <div>
                 Signaler une anomalie
               </div>
               <ReportIcon />
             </Paper>
           </div>
         }
          <Divider className={classes.divider}variant="middle" />
          <h3>Informations</h3>
          <div className="datapoint-container">
            <span onClick={this.goToEditeur} >
              <Datapoint title="Editeur" info={article.editeur} />
            </span>
            <Datapoint title="Score Complet Article" info={article.score} />
            <Datapoint title="Score selon les filtres" info={score} />
            <Datapoint title="Dernière position" info={article.last_position} />
            <Datapoint title="Dernière catégorie" info={article.last_categorie} />
          </div>
          <div className="datapoint-container">
            <Datapoint title="Heure d'apparition Sitemap" info={article.dateArticle ? moment(article.dateArticle).format('LLL') : null} />
            <Datapoint title="Première exposition" info={article.first_exposition ? moment(article.first_exposition).format('LLL') : 'Non exposé'} />
            <Datapoint title="Dernière modification" info={article.lastModified ? moment(article.lastModified).format('LLL') : null} />
            <Datapoint title="Mise a jour Sitemap" info={article.lastDateArticle ? moment(article.lastDateArticle).format('LLL') : null} />
          </div>
          <div className="datapoint-container">
            <Datapoint title="Durée de vie totale" info={displayLifetime(article.lifetime)} />
            <Datapoint title="Durée de vie Desktop" info={displayLifetime(article.lifetime_google_desktop)} color="desktop-color" />
            <Datapoint title="Durée de vie Mobile" info={displayLifetime(article.lifetime_google_mobile_webview)} color="mobile-color" />
            { /* <Datapoint title="Durée de vie Flipboard" info={displayLifetime(article.lifetime_flipboard_mobile)} color="flipboard-color" /> */}
            <Datapoint title="Durée de vie AppleNews" info={displayLifetime(article.lifetime_applenews)} color="applenews-color" />
          </div>
          <div className="datapoint-container">
            <Datapoint title="Shares Total" info={article.social_shares_total ? article.social_shares_total : null} />
            <Datapoint title="Shares Facebook" info={article.social_shares_facebook ? article.social_shares_facebook : null } />
            <Datapoint title="Shares Twitter" info={article.social_shares_twitter ? article.social_shares_twitter : null } />
          </div>
          <Grid container justify="center" spacing={12}>
            { isScoreQuarts ?
              <Fragment>
                <Grid item xs={12}>
                  <div className="chart-container">
                    { /*
                      <h3>Evolution du score</h3>
                      <AreaChart data={data} colors={getDisplayColors(data)} library={optionsArea}/>
                    */}

                    <h3>Evolution de l’exposition de l’article</h3>
                    <AreaChart stacked={true} data={dataChart} colors={Object.values(customColor)} library={optionsColumn} />
                  </div>
                </Grid>
              </Fragment>
              : null
            }
            {
              article.cluster_id ?
              <ClusterArticle query={{...this.props.query, ...{fromDate, toDate} } } cluster_id={article.cluster_id}/>
              : null
            }
            <Grid item xs={3}>
              <h3>Entités Detectées</h3>
              <div className="datapoint-container">
              { article.lemmeArticle
                ? article.lemmeArticle.map(lemmeArticle => lemmeArticle.entities_liste.map(entity =>
                  <div className={classes.tag} onClick={() => this.goToCouverture({clusterEntities: entity, clusterLemmes: ''})} >{replaceCharacters(entity)}</div>
                ))
                : null
              }
              </div>
            </Grid>
            <Grid item xs={3}>
              <h3>Lemmes Detectés</h3>
              <div className="datapoint-container">
              { article.lemmeArticle
                ? article.lemmeArticle.map(lemmeArticle => lemmeArticle.lemmes_liste.map(lemme =>
                  <div className={classes.tag} onClick={() => this.goToCouverture({clusterEntities: '', clusterLemmes: lemme})} >{replaceCharacters(lemme)}</div>
                ))
                : null
              }
              </div>
            </Grid>
            {
              isScoreQuarts ?
              <Grid item xs={12}>
                <Divider className={classes.divider}variant="middle" />
                <h3>Scores</h3>
                <Grid container justify="center" spacing={12}>
                  {
                    Object.keys(sourceScore || {}).map(key => {
                      if (scores[key])
                        return <DataCategory classes={classes} category={key} scores={scores} />
                      return null;
                    })
                  }
                </Grid>
              </Grid>
              : null
            }
          </Grid>
          <Divider className={classes.divider}variant="middle" />
          { socialChart ?
            <Fragment>
              <h3>Evolution des partages sociaux</h3>
              <AreaChart stacked={true} data={socialChart} colors={Object.values(socialColor)} library={optionsColumn} />
            </Fragment>
            : null }

            <Dialog open={this.state.isOpen} onClose={this.handleOpenOrClose}>
              <DialogTitle>Signaler une anomalie</DialogTitle>
              <DialogContent>
                <form>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel id="demo-simple-select-label">Bug</InputLabel>
                    <Select
                      id="demo-simple-select"
                      name="valueSelect"
                      value={this.state.valueSelect ? this.state.valueSelect : ""}
                      onChange={this.handleChange()}
                    >
                      <MenuItem value="titre">Le titre</MenuItem>
                      <MenuItem value="url">Le lien</MenuItem>
                      <MenuItem value="informations">Les Informations</MenuItem>
                      <MenuItem value="entite">Les Entités/Lemmes</MenuItem>
                      <MenuItem value="graphiques">Les graphiques</MenuItem>
                      <MenuItem value="autre">Autre</MenuItem>
                    </Select>
                  </FormControl>

                  <TextField
                    margin="dense"
                    id="comment"
                    label="Commentaire"
                    type="text"
                    name="commentInput"
                    fullWidth
                    onChange={this.handleChange()}
                  />
                </form>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleOpenOrClose} color="primary">
                  Annuler
                </Button>
                <Button onClick={this.handleSubmit(this.state)} color="primary">
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
        </div>
      );

    }
    else {
      return (
        <div >
          <CircularProgress />
        </div>
      )
    }
  }
}

Article.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Article);
