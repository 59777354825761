import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import config from '../config';

import MUIDataTable from 'mui-datatables';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ReactChartkick, { AreaChart, ColumnChart, LineChart } from 'react-chartkick';
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting'
import HC_offlineExporting from 'highcharts/modules/offline-exporting'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Datapoint from './sub_components/Datapoint';
import DataEntry from './sub_components/DataEntry';

HC_exporting(Highcharts);
HC_offlineExporting(Highcharts);
ReactChartkick.addAdapter(Highcharts)

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  dense: {
    marginTop: 16,
  },
  fab: {
    margin: theme.spacing.unit,
  },
  filler: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 400
  },
  dataContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

const getScore = (articles, jour) => {
  const entries = {};

  for (let hour in articles) {
    if (articles[hour] !== 0)
        entries[jour + ' ' + hour + ':00'] = articles[hour];
  }
  return entries;
};


class ArticlesGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      render: false,
      exposed: [],
    }
  };

  getData = () => {
    const query = this.props.changeEditeur ? this.props.query : {...this.props.query, ...{editeur: 'all'} };
    axios.post(`${config.backEndServer}/api/editeur/exposition`, this.props.query
    , {withCredentials: true})
      .then((res) => {
        this.setState({ render: true, exposed: res.data[0] });
      });
  };

  componentDidMount = () => {
    this.getData();
  }

  render = () => {
    const { classes, isAdmin } = this.props;
    const { render, exposed } = this.state;

    if (!exposed)
      return null;
    if (render) {
      const expositionSeries = getScore(exposed.articles_exposes_heures[0], exposed.jours[0]);
      const sitemapSeries = [];
      sitemapSeries.push({"name":"Nouveaux Articles", "data": getScore(exposed.sitemap[0].articles_heures, exposed.jours[0])})
      sitemapSeries.push({"name":"Articles Modifies", "data": getScore(exposed.sitemap[0].modifs_articles_heures, exposed.jours[0])});

      return (
        <div className="article-container">
          <div className="datapoint-container">
            <h3>Scores</h3>
            <Datapoint title="Total" info={exposed.scores_jour[0].score} />
            <Datapoint title="Google Desktop" info={exposed.scores_jour[0].score_google_desktop} color="desktop-color" />
            <Datapoint title="Google Mobile" info={exposed.scores_jour[0].score_google_mobile_webview} color="mobile-color" />
            <Datapoint title="Applenews" info={exposed.scores_jour[0].score_applenews} color="applenews-color" />
            {/* <Datapoint title="Flipboard" info={exposed.scores_jour[0].score_flipboard_mobile} color="flipboard-color" /> */}
          </div>
          <div className="datapoint-container">
            <h3>Exposition</h3>
            <Datapoint title="Nouvelles URLs exposées" info={exposed.nouveaux_articles_exposes} />
            <Datapoint title="URLs exposées" info={exposed.articles_exposes} />
          </div>
          { exposed.sitemap[0].articles_jour ?
            <div className="datapoint-container">
              <h3>Sitemap</h3>
              <Datapoint title="Nouvelles URLs découvertes en Sitemap" info={exposed.sitemap[0].articles_jour} />
              <Datapoint title="Nombre d'URLs actuellement en Sitemap" info={exposed.sitemap[0].articles_direct} />
            </div>
          : null
          }
        </div>
      );
    } else {
      return (
        <div className={classes.filler} >
          <CircularProgress className={classes.progress} />
        </div>
      )
    }
  };
}


ArticlesGraph.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ArticlesGraph);
